import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError} from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { ApiEndPointsService } from '../common/api-end-points.service';
import { IEscalationSetting} from '../setting/interfaces/EscalationSetting';

@Injectable({
  providedIn: 'root'
})
export class EsclanationService {
  baseUrl: string;
  constructor(private http : HttpClient, private endPoints: ApiEndPointsService, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getAllEscalationSettings() : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.setting.getallescalations)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  getEscalationSettings() : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.setting.getescalationbyid)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  addEscalationSettings(smtp : IEscalationSetting) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.setting.addescalationsetting, smtp)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  editEscalationSettings(smtp : IEscalationSetting) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.setting.editescalationsetting, smtp)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  deleteEscalationSetting(id : string) : Observable<any>{
    return this.http.delete(this.baseUrl+this.endPoints.setting.deleteEscalation+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
