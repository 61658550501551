<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
      <main>
        <notifier-container></notifier-container>

        <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
          <div class="container-fluid">
            <div class="sb-page-header-content py-5">
              <h1 class="sb-page-header-title">
                <div class="sb-page-header-icon"><i data-feather="droplet"></i></div>
                <span *ngIf="user?.designation=='PR'|| user?.designation==null">Ticket Details</span>
                <span *ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">Request Details</span>
                <span *ngIf="user?.designation=='SuperAdmin'">Ticket Details</span>
              </h1>
              <div class="sb-page-header-subtitle">
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid mt-n10">
          <div class="row">
            <div class="col-lg-8">
              <div class="card">
                <div class="card-header">
                  Ticket Details
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">Title: {{ticketDetail?.ticket?.title}}</li>
                        <li class="list-group-item">
                          <h6>Description</h6>
                          <p>{{ticketDetail?.ticket?.description}}</p>
                        </li>
                        <li class="list-group-item" *ngIf="ticketDetail?.ticket.customerId !== emptyId">
                          <h6>Customer</h6>
                          <span> Name: {{ticketDetail?.customer?.firstName}} {{ticketDetail?.customer?.lastName}}</span>
                          <hr>
                          <span> Phone: {{ticketDetail?.customer?.phone}} </span>
                          <hr>
                          <span> Email: {{ticketDetail?.customer?.email}} </span>
                          <hr>
                        </li>
                        <li class="list-group-item">Created On : {{ticketDetail?.ticket?.createdAt | date : "medium"}}
                        </li>
                        <li class="list-group-item">Status : {{ticketDetail?.ticket?.closed?"Closed":"Open"}}</li>
                        <li class="list-group-item">Reason : <p>{{ticketDetail?.ticket?.reason}}</p>
                        </li>
                      </ul>
                      <div class="sb-datatable table-responsive" *ngIf="fileDetail.length > 0">
                        <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>File Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let file of fileDetail; let i = index">
                              <td>{{ i + 1}}</td>
                              <td>{{file?.url}}</td>
                              <td>
                                <a href="/Account/ViewAttachment?id={{file?.id}}">Download</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div><br>

                      <div class="mb-1 p-1">
                        <label for="file" class="p-1">Choose File</label>
                        <input type="file" id="file" (change)="onFileSelect($event)">
                        <button class="btn btn-primary" (click)="uploadFiles()">Upload</button>
                      </div>



                      <div *ngIf="user?.type == 'Employee'">
                        <button *ngIf="!ticketDetail?.ticket?.closed && user?.isHod" class="btn btn-success btn-block"
                          data-toggle="modal" data-target="#exampleComplete">
                          <span>Close</span>
                          <i class="fa fa-lock"></i></button>
                        <button *ngIf="ticketDetail?.ticket?.closed && user?.isHod" class="btn btn-danger btn-block"
                          data-toggle="modal" data-target="#exampleComplete">
                          <span>Re-Open</span>
                          <i class="fa fa-unlock"></i></button>
                      </div>

                    </div>
                    <div class="col-md-4 offset-md-1" *ngIf="user?.isAdmin == 1">
                      <div *ngIf="!ticketDetail?.ticket?.closed">
                        <span *ngIf="user?.designation=='PR'|| user?.designation==null">
                          <h6>Update Complains Activity</h6>
                        </span>
                        <span *ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">
                          <h6>Update Request Activity</h6>
                        </span>
                        <span *ngIf="user?.designation=='SuperAdmin'">
                          <h6>Update Issue Activity</h6>
                        </span>
                        <div class="form-row">
                          <div class="col-md-12">
                            <div class="form-group"><label class="small blm mb-1" for="indicator">Status</label>
                              <select class="form-control" id="indicator" [(ngModel)]="ticketStatus.indicator">
                                <option value="" disabled>--Select Status--</option>
                                <option value="Not Started">Not Started</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Postponed">Postponed</option>
                                <option value="Complete">Complete</option>
                                <option value="Cancelled">Cancelled</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="small blm mb-1" for="comment">Description</label>
                              <textarea class="form-control sb-form-control-solid" id="comment" name="comment"
                                [(ngModel)]="ticketStatus.comment" placeholder="Enter comment" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12">
                            <button class="btn btn-primary btn-block" (click)="updateTicket()">Update <i
                                class="fa fa-sync"></i></button>
                          </div>
                        </div>
                        <hr>
                      </div>
                      <div *ngIf="ticketDetail?.ticket.customerId !== emptyId">
                        <h6>Communicate with Customer</h6>
                        <div class="form-row">
                          <div class="col-md-12">
                            <div class="form-group"><label class="small blm mb-1" for="meeting">Subject Title</label>
                              <input class="form-control" id="subject" [(ngModel)]="subject" name="subject" type="text"
                                placeholder="Enter subject title" /></div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="small blm mb-1" for="comment">Message</label>
                              <textarea class="form-control sb-form-control-solid" id="message" name="message"
                                [(ngModel)]="message" placeholder="Enter message to send" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="small blm mb-1" for="indicator">Communicate Via</label>
                              <select class="form-control" id="medium" [(ngModel)]="medium">
                                <option value="" disabled>--Select Channel--</option>
                                <option value="Email">Email</option>
                                <option value="SMS">SMS</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-12">
                            <button class="btn btn-primary btn-block" (click)="sendMessage()">Send Message <i
                                class="fa fa-paper-plane"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><br /><br />
              <div id="default">
                <div class="card mb-4">
                  <div class="card-header">
                    <span *ngIf="user?.designation=='PR'|| user?.designation==null">Complain History</span>
                    <span *ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">Request
                      History</span>
                    <span *ngIf="user?.designation=='SuperAdmin'">Issue History</span></div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6 offset-md-1">
                        <span *ngIf="user?.designation=='PR'|| user?.designation==null">Complain Activity</span>
                        <span *ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">Request
                          Activity</span>
                        <span *ngIf="user?.designation=='SuperAdmin'">Issue Activity</span>
                        <ul class="timeline">
                          <li *ngFor="let activity of ticketDetail?.statuses">
                            <a target="_blank" href="">{{activity.employee.firstName}}
                              {{activity.employee.lastName}}</a>
                            <a href="#" class="float-right">{{activity.status.createdAt | date:"medium"}}</a>
                            <div class="badge badge-primary">{{activity.status.indicator}}</div>
                            <p>
                              {{activity.status.comment}}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4" *ngIf="user?.isAdmin == 1">
              <div class="sb-nav-sticky">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-md-8">
                        <span *ngIf="user?.designation=='PR'|| user?.designation==null">
                          <h6>Complain Assigned Users</h6>
                        </span>
                        <span *ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">
                          <h6>Request Assigned Users</h6>
                        </span>
                        <span *ngIf="user?.designation=='SuperAdmin'">
                          <h6>Issue Assigned Users</h6>
                        </span>

                      </div>
                      <div class="col-md-2" style="text-align: right;">
                        <button type="button" class="btn btn-primary btn-sm" data-toggle="modal"
                          data-target="#exampleModalLong">
                          <i class="fa fa-plus"></i> Add
                        </button>
                      </div>
                      <div class="col-md-2" style="text-align: right;">
                        <button type="button" class="btn btn-primary btn-sm" (click)="setEscalation()"
                          data-toggle="modal" data-target="#exampleModalLong">
                          <i class="fa fa-plus"></i> Escalate
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" *ngFor="let user of ticketDetail?.users">
                        <div class="row">
                          <div class="col-md-8">
                            <a class="nav-link" href="">{{user.firstName}} {{user.lastName}} <br> {{user.email}}<br>
                              <div class="badge badge-primary" *ngIf="user.escalated">Escalated</div>
                            </a>
                          </div>
                          <div class="col-md-4">
                            <button class="btn btn-danger btn-sm" style="text-align: right;"
                              (click)="removeUser(user.id)" *ngIf="user?.type == 'Employee'"><i
                                class="fa fa-times"></i></button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="!escalation">Assign Users </h5>
        <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="escalation">Escalate </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="form-group"><label class="small blm mb-1" for="department">Filter Department</label>
            <select class="form-control" id="department" (change)="onChange($event.target.value)">
              <option value="">Select Department</option>
              <option *ngFor="let dept of departments" value="{{dept.id}}">{{dept.name}}</option>
            </select>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let employee of employees">
              <div class="custom-control custom-checkbox"><input class="custom-control-input"
                  (change)="addRemoveId(employee.id, $event)" id="{{employee.id}}" type="checkbox">
                <label class="custom-control-label" for="{{employee.id}}">{{employee.firstName}} {{employee.lastName}} |
                  {{employee.email}}</label></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close <i
            class="fa fa-times"></i></button>
        <button type="button" class="btn btn-primary btn-sm" (click)="assignUsers()" data-dismiss="modal">Assign
          Selected <i class="fa fa-check"></i></button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleComplete" tabindex="-1" role="dialog" aria-labelledby="exampleModalCompleteTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCompleteTitle">Enter Reason/Notes</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="small blm mb-1" for="comment">Root Cause</label>
              <textarea class="form-control sb-form-control-solid" id="reason" name="reason" [(ngModel)]="reason"
                placeholder="Enter root cause" rows="3"></textarea>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group"><label class="small blm mb-1" for="indicator">Notify Customer</label>
              <select class="form-control" id="indicator" [(ngModel)]="notify">
                <option value="true">Notify the Customer</option>
                <option value="false">Don't Notify the Customer</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close <i
            class="fa fa-times"></i></button>
        <button type="button" class="btn btn-primary btn-sm" *ngIf="!ticketDetail?.ticket?.closed"
          (click)="complete(true)" data-dismiss="modal">Save <i class="fa fa-save"></i></button>
        <button type="button" class="btn btn-primary btn-sm" *ngIf="ticketDetail?.ticket?.closed"
          (click)="complete(false)" data-dismiss="modal">Save <i class="fa fa-save"></i></button>
      </div>
    </div>
  </div>
</div>
