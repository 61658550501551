<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>Customers Report</span>
        </h1>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-n10">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">
            </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="small blm mb-1" for="date">From Date</label>
                  <div class="input-group">
                    <input class="form-control" placeholder="dd-mm-yyyy"
                    name="endDate" id="startDate" [(ngModel)]="startDate" type="date">
                    </div>
              </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="small blm mb-1" for="endDate">To Date</label>
                  <div class="input-group">
                      <input class="form-control" placeholder="dd-mm-yyyy"
                             name="endDate" id="endDate" [(ngModel)]="endDate" type="date">
                    </div>
              </div>
              </div>
              <div class="col-md-3">
                <div class="form-group"><label class="small blm mb-1" for="type">Type</label>
                  <select class="form-control" id="type" [(ngModel)]="type">
                      <option value="">Select Type</option>
                      <option value="{{emptyId}}">All</option>
                      <option *ngFor="let type of types" value="{{type.id}}">{{type.name}}</option>
                  </select>
              </div>
              </div>
              <div class="col-md-3">
                <br>
                <button class="btn btn-success" (click)="getCustomers()">Generate</button>
              </div>
            </div><br>
            <div class="sb-datatable table-responsive">
              <div class="row">
                <div class="col-md-12" style="text-align: right;">
                  <button class="btn btn-info pull-right"  (click)="export()">Export</button>
                </div>
              </div><br>
              <table class="table table-bordered table-hover" datatable width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Id/Passport No</th>
                    <th>Arn No</th>
                    <th>Address</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let customer of customers;index as i">
                    <td>{{i+1}}</td>
                    <td>{{customer.firstName}} {{customer.lastName}}</td>
                    <td>{{customer.phone}}</td>
                    <td>{{customer.email}}</td>
                    <td>{{customer.identity}}</td>
                    <td>{{customer.arn}}</td>
                    <td>{{customer.address}}</td>
                    <td>
                      <div class="badge badge-primary badge-pill">{{customer?.createdAt}}</div>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</div>
</div>
</div>
