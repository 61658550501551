import { ILog } from './../log/interfaces/Log';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiEndPointsService } from './../common/api-end-points.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  baseUrl: string;
  emptyId: string;
  constructor(private http: HttpClient, private endPoints: ApiEndPointsService, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
    this.emptyId = this.endPoints.constants.emptyId;
   }

   getLogs() : Observable<any> {
     return this.http.get(this.baseUrl+this.endPoints.log.getlogs)
     .pipe(tap(), catchError(this.endPoints.handleError));
   }

   addLog(log : ILog) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.log.addLog, log)
    .pipe(tap(), catchError(this.endPoints.handleError));
   }
   getUser(id: string): Observable<any>{
     return this.http.get(this.baseUrl+this.endPoints.log.getUser+id)
     .pipe(tap(), catchError(this.endPoints.handleError));
   }

   
   saveLog(employeeId: string, message: string, type: string){
    let log: ILog = {
      id: this.emptyId,
      message: message,
      createdAt: new Date,
      updatedAt: new Date,
      type: type,
      employeeId: employeeId
    };

    this.addLog(log).subscribe(data=>{
      console.log(data);
    });
  }
}
