<notifier-container></notifier-container>
<div class="card">
    <div class="card-body">
        <form>
            <div class="form-row">
                <div class="col-md-12">
                    <div class="form-group"><label class="small blm mb-1" for="name">Enter your email address</label>
                        <input class="form-control" id="Name" name="Name" type="text" [(ngModel)]="email" placeholder="Enter email address" />
                    </div>
                </div>
            </div>
            <div class="form-group mt-4 mb-0">
                <button class="btn btn-success btn-block" (click) ="resetPassword()" data-dismiss="modal">Reset Password</button>
                <button class="btn btn-primary btn-block" data-dismiss="modal">Cancel</button>
            </div>
        </form>
    </div>
</div>
