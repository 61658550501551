import { Component, OnInit } from "@angular/core";
import { TicketService } from "src/app/services/ticket.service";
import { ITicketDetail } from "../interfaces/TicketDetail";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { NotifierService } from "angular-notifier";
import { IEmployee } from "src/app/employee/interfaces/Employee";
import { IDepartment } from "src/app/department/interfaces/Department";
import { IStatus } from "src/app/common/interfaces/Status";
import { EmployeeService } from "src/app/services/employee.service";
import { DepartmentService } from "src/app/services/department.service";
import { IUserAssigenee } from "src/app/common/interfaces/ListObject";
import { AuthService } from "src/app/services/auth.service";
import { UploadService } from "../ticket-detail/upload.service"
import { IFileDetail } from "../interfaces/fileDetail";

@Component({
  selector: "app-ticket-detail",
  templateUrl: "./ticket-detail.component.html",
  styleUrls: ["./ticket-detail.component.css"],
})
export class TicketDetailComponent implements OnInit {
  user: any;
  ticketId: string = "";
  userId: string = "";
  ticketDetail: ITicketDetail;
  reason: string;
  notify: string;
  escalation: boolean;
  subject: string;
  message: string;
  medium: string = "";
  emptyId: string = "";
  fileData: File = null;

  fileDetail: IFileDetail[]=[];
  employees: IEmployee[] = [];
  departments: IDepartment[] = [];
  userAssignees: string[] = [];
  ticketStatus: IStatus = {
    id: this.emptyId,
    owner: "Ticket",
    indicator: "",
    completionPercentage: 0,
    ownerId: this.ticketId,
    createdAt: new Date(),
    updatedAt: new Date(),
    userId: this.userId,
    comment: "",
  };
  constructor(
    private ticketService: TicketService,
    private route: ActivatedRoute,
    private router: Router,
    private endPoints: ApiEndPointsService,
    private notifier: NotifierService,
    private employeeService: EmployeeService,
    private uploadService: UploadService,
    private departmentService: DepartmentService,
    private authService: AuthService
  ) {
    this.ticketId = this.route.snapshot.paramMap.get("id");
    this.userId = localStorage.getItem("userId");
    this.emptyId = this.endPoints.constants.emptyId;
  }

  ngOnInit() {
    this.getTicketDetails();
    this.getEmployees();
    this.getDepartments();
    this.getuploadedFiles();
    this.authService.getUser().subscribe((data) => {
      this.user = data;
      console.log(this.user);
    });
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileData = file;
      console.log(this.fileData)
    }
  }
  uploadFiles() {
    const formData = new FormData();
      formData.append('file', this.fileData,this.fileData.name);

    this.uploadService.uploadFiles(this.fileData,this.ticketId).subscribe((data)=>{
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.subject = "";
        this.message = "";
        return;
      }
      this.notifier.notify("error", data.message);
    });
  }

  getTicketDetails() {
    this.ticketService.getTicket(this.ticketId).subscribe((data) => {
      if (data.success) {
        this.ticketDetail = data.data;
      }
    });
  }
  getuploadedFiles() {
    this.uploadService.uploadedFiles(this.ticketId).subscribe((data) => {
      if (data.success) {
        this.fileDetail = data.data;
        console.log(this.fileDetail)
      }
    });
  }

  getEmployees() {
    this.employeeService.getEmployees().subscribe((data) => {
      if (data.success) {
        this.employees = data.data;
      }
    });
  }

  setEscalation() {
    this.escalation = true;
  }

  onChange(deptid) {
    this.employees = this.employees.filter((x) => x.departmentId == deptid);
  }

  getDepartments() {
    this.departmentService.getDepartments().subscribe((data) => {
      if (data.success) {
        this.departments = data.data;
      }
    });
  }

  updateTicket() {
    this.ticketStatus.id = this.emptyId;
    this.ticketStatus.userId = this.userId;
    this.ticketStatus.ownerId = this.ticketId;
    this.ticketService.changeStatus(this.ticketStatus).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.getTicketDetails();

        return;
      }

      this.notifier.notify("error", data.message);
    });
  }

  addRemoveId(id: string, e) {
    if (e.target.checked) {
      if (!this.userAssignees.find((x) => x === id)) {
        this.userAssignees.push(id);
      }
    }
    if (!e.target.checked) {
      if (this.userAssignees.find((x) => x === id)) {
        const index = this.userAssignees.indexOf(id);
        if (index > -1) {
          this.userAssignees.splice(index, 1);
        }
      }
    }
  }

  assignUsers() {
    let listObject: IUserAssigenee = {
      id: this.ticketId,
      userIds: this.userAssignees,
      escalation: this.escalation,
    };
    this.ticketService.addTicketUsers(listObject).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.getTicketDetails();
        this.escalation = false;
        return;
      }
      this.escalation = false;
      this.notifier.notify("error", data.message);
    });
  }

  complete(state: boolean) {
    let notifyCustomer: boolean;
    if (this.notify == "true") {
      notifyCustomer = true;
    }
    this.ticketService
      .changeState(this.ticketId, state, this.reason, notifyCustomer)
      .subscribe((data) => {
        if (data.success) {
          this.notifier.notify("success", data.message);
          this.getTicketDetails();

          return;
        }

        this.notifier.notify("error", data.message);
      });
  }

  removeUser(id) {
    this.ticketService.removeTicketUser(this.ticketId, id).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.getTicketDetails();

        return;
      }

      this.notifier.notify("error", data.message);
    });
  }


  sendMessage() {
    this.ticketService
      .sendMessage(
        this.ticketDetail.ticket.customerId,
        this.subject,
        this.message,
        this.medium
      )
      .subscribe((data) => {
        if (data.success) {
          this.notifier.notify("success", data.message);
          this.subject = "";
          this.message = "";
          return;
        }
        this.notifier.notify("error", data.message);
      });
  }
}
