<notifier-container></notifier-container>
<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>Add FAQ</span>
        </h1>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="card shadow-lg border-0 rounded-lg mt-5">
            <div class="card-header justify-content-center">
              <h3 class="font-weight-light my-4">Create FAQ</h3>
            </div>
            <div class="card-body">
              <div>
                <div class="form-group">
                  <label class="small blm mb-1" for="name">FAQ Type</label>
                  <select class="form-control" id="type" [(ngModel)]="faq.type">
                    <option value="">--Select Faq Type</option>
                    <option value="External">Public</option>
                    <option value="Internal">Internal</option>
                  </select>
              </div>

                <div class="form-row">
                  <div class="col-md-12">
                    <div class="form-group"><label class="small blm mb-1" for="name">Title</label>
                      <input class="form-control" id="title" [(ngModel)]="faq.name" name="name" type="text"
                        placeholder="Enter Question" /></div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="small blm mb-1" for="answer">Description</label>
                      <textarea class="form-control sb-form-control-solid" id="answer" name="answer"
                        [(ngModel)]="faq.answer" placeholder="Enter answer" rows="3"></textarea>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-4 mb-0">
                  <button class="btn btn-success btn-block" (click)="saveFaq()">Save Faq</button>
                  <a class="btn btn-primary btn-block" [routerLink]="['/faqs']">Cancel</a>
                </div>
              </div>
            </div>
            <div class="card-footer text-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</div>
</div>
</div>
