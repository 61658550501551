import { NgModule } from "@angular/core";
import { LogComponent } from "./log/log.component";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SettingComponent } from "./setting/setting.component";
import { TicketComponent } from "./ticket/ticket.component";
import { EmployeeComponent } from "./employee/employee.component";
import { DepartmentComponent } from "./department/department.component";
import { CustomerComponent } from "./customer/customer.component";
import { TicketDetailComponent } from "./ticket/ticket-detail/ticket-detail.component";
import { AddTicketComponent } from "./ticket/add-ticket/add-ticket.component";
import { EditTicketComponent } from "./ticket/edit-ticket/edit-ticket.component";
import { EmployeeDetailComponent } from "./employee/employee-detail/employee-detail.component";
import { AddEmployeeComponent } from "./employee/add-employee/add-employee.component";
import { EditEmployeeComponent } from "./employee/edit-employee/edit-employee.component";
import { CustomerDetailComponent } from "./customer/customer-detail/customer-detail.component";
import { AddCustomerComponent } from "./customer/add-customer/add-customer.component";
import { EditCustomerComponent } from "./customer/edit-customer/edit-customer.component";
import { DepartmentDetailComponent } from "./department/department-detail/department-detail.component";
import { AddDepartmentComponent } from "./department/add-department/add-department.component";
import { EditDepartmentComponent } from "./department/edit-department/edit-department.component";
import { ProfileComponent } from "./profile/profile.component";
import { SurveyComponent } from "./survey/survey.component";
import { SurveyDetailsComponent } from "./survey/survey-details/survey-details.component";
import { AddSurveyComponent } from "./survey/add-survey/add-survey.component";
import { TicketReportComponent } from "./reports/ticket-report/ticket-report.component";
import { CustomerReportComponent } from "./reports/customer-report/customer-report.component";
import { FaqComponent } from "./faq/faq.component";
import { AddFaqComponent } from "./faq/add-faq/add-faq.component";
import { EditFaqComponent } from "./faq/edit-faq/edit-faq.component";
import { StaffSurveyComponent } from "./staff-survey/staff-survey.component";
import { StaffSurveyDetailComponent } from "./staff-survey/staff-survey-detail/staff-survey-detail.component";
import { ItHelpdeskComponent } from "./ticket/it-helpdesk/it-helpdesk.component";
import { ItHelpdeskReportComponent } from "./reports/it-helpdesk-report/it-helpdesk-report.component";
import { MyItRequestsComponent } from "./ticket/my-it-requests/my-it-requests.component";
import { CustomerSurveyComponent } from "./survey/customer-survey/customer-survey.component";
import { CustomerSurveyDetailComponent } from "./survey/customer-survey/customer-survey-detail/customer-survey-detail.component";
import { InternalFaqComponent } from "./faq/internal-faq/internal-faq.component";
import { AuthComponent } from "./auth/auth.component";
import { PublicFaqComponent } from "./faq/public-faq/public-faq.component";
import { AnonTicketComponent } from "./anon-ticket/anon-ticket.component";
import { CorruptionComponent } from "./corruption/corruption.component";

const routes: Routes = [
  { path: "", redirectTo: "/auth", pathMatch: "full" },
  { path: "auth", component: AuthComponent },
  { path: "auth/:username", component: AuthComponent },
  { path: "public-faq", component: PublicFaqComponent },
  { path: "anon-ticket", component: AnonTicketComponent },
  { path: "corruption", component: CorruptionComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "complains", component: TicketComponent },
  { path: "itHelpdesk", component: ItHelpdeskComponent },
  { path: "ticket-details/:id", component: TicketDetailComponent },
  { path: "add-ticket/:id", component: AddTicketComponent },
  { path: "edit-ticket/:id", component: EditTicketComponent },
  { path: "itHelpdeskReport", component: ItHelpdeskReportComponent },
  { path: "myItRequest", component: MyItRequestsComponent },
  { path: "employees", component: EmployeeComponent },
  { path: "employee-details/:id", component: EmployeeDetailComponent },
  { path: "profile", component: ProfileComponent },
  { path: "add-employee", component: AddEmployeeComponent },
  { path: "edit-employee/:id", component: EditEmployeeComponent },
  { path: "customers", component: CustomerComponent },
  { path: "customer-details/:id", component: CustomerDetailComponent },
  { path: "add-customer", component: AddCustomerComponent },
  { path: "edit-customer/:id", component: EditCustomerComponent },
  { path: "departments", component: DepartmentComponent },
  { path: "department-details/:id", component: DepartmentDetailComponent },
  { path: "surveys", component: SurveyComponent },
  { path: "survey-details/:id", component: SurveyDetailsComponent },
  { path: "add-survey", component: AddSurveyComponent },
  { path: "add-department", component: AddDepartmentComponent },
  { path: "edit-department/:id", component: EditDepartmentComponent },
  { path: "settings", component: SettingComponent },
  { path: "complains-report", component: TicketReportComponent },
  { path: "customer-report", component: CustomerReportComponent },
  { path: "faqs", component: FaqComponent },
  { path: "internal-faq", component: InternalFaqComponent },
  { path: "faq-details/:id", component: FaqComponent },
  { path: "add-faq", component: AddFaqComponent },
  { path: "edit-faq/:id", component: EditFaqComponent },
  { path: "log", component: LogComponent },
  { path: "staff-survey", component: StaffSurveyComponent },
  { path: "staff-survey-details/:id", component: StaffSurveyDetailComponent },
  { path: "customer-survey", component: CustomerSurveyComponent },
  {
    path: "customer-survey-details/:id",
    component: CustomerSurveyDetailComponent,
  },
];
@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
