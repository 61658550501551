<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>FAQs</span>
        </h1>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header"> <a class="btn btn-outline-primary btn-sm" [routerLink]="['/add-faq']"
              *ngIf="user.isAdmin == 1" routerLinkActive="router-link-active">
              <i class="fa fa-plus"></i> Add Faq</a></div>
          <div class="card-body">
            <div class="sb-datatable table-responsive">
              <table class="table table-borderless table-hover" datatable width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>Knowledgebase</th>
                    <th  *ngIf="user.isAdmin == 1" class="">Category</th>
                    <th  *ngIf="user.isAdmin == 1" class="">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let faq of faqs;index as i">
                    <td>
                      <div class="accordion" [id]="'accordion'+faq.id">
                        <div class="card">
                          <div class="card-header">
                            <a  data-toggle="collapse" [href]="'#collapseOne'+faq.id">
                              <h6>{{faq.name}}</h6>
                            </a>
                          </div>
                          <div [id]="'collapseOne'+faq.id" class="collapse" [attr.data-parent]="'#accordion'+faq.id">
                            <div class="card-body">
                              <p>{{faq.answer}}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </td>
                    <td>
                      <div  *ngIf="user.isAdmin == 1" class="card">
                        <div class="card-header">
                          {{faq.type}}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div  *ngIf="user.isAdmin == 1" class="card">
                        <div class="card-header">
                      <button  *ngIf="user.isAdmin == 1" class="btn sb-btn-datatable sb-btn-icon sb-btn-outline-danger mr-2" (click)='deleteFaq(faq.id)'>
                        <i class="fa fa-trash"></i>
                    </button>
                        ||
                          <a class="badge badge-warning ml-2" *ngIf="user.isAdmin == 1" [routerLink]="['/edit-faq',faq.id]">
                            <i class="fa fa-edit"></i>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
</div>
</div>
</div>
