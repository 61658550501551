<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main>
    <notifier-container></notifier-container>

    <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-fluid">
            <div class="sb-page-header-content py-5">
                <h1 class="sb-page-header-title">
                    <div class="sb-page-header-icon"><i data-feather="droplet"></i></div>
                    <span>Employee Profile</span>
                </h1>
                <div class="sb-page-header-subtitle">
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-n10">
        <div class="row">
            <div class="col-lg-7">
                <div class="card">
                    <div class="card-header">
                        Profile Details
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item"> <i class="fa fa-user"></i>   Names : {{employee?.firstName}} {{employee?.lastName}}</li>
                                    <li class="list-group-item"> <i class="fa fa-envelope"></i>   Email :{{employee?.email}}</li>
                                    <li class="list-group-item"> <i class="fa fa-phone"></i>   Phone : {{employee?.phone}}</li>
                                    <li class="list-group-item"> <i class="fa fa-credit-card"></i>   ID/Passport No : {{employee?.identity}}</li>
                                    <li class="list-group-item"> <i class="fa fa-clock"></i>    Created On : {{employee?.createdAt | date:'medium'}}</li>
                                    <li class="list-group-item"><button class="btn btn-success btn-block" (click)="passwordReset()">Send Password Reset Request</button></li>
                                    <li class="list-group-item" *ngIf="employee?.admin == 0"><button class="btn btn-success btn-block" (click)="makeAdmin()">Make Admin</button></li>
                                    <li class="list-group-item" *ngIf="employee?.admin == 1"><button class="btn btn-success btn-block" (click)="removeAdmin()">Remove Admin</button></li>
                                  </ul>
                            </div>
                            <div class="col-md-6">
                                <div *ngIf="loginUser?.id == userId || loginUser?.admin == 1">

                                    <h6>Change Password</h6>
                                    <div class="form-row">
                                        <div class="col-md-12">
                                            <div class="form-group"><label class="small blm mb-1" for="password">Password</label>
                                                <input class="form-control" id="password" [(ngModel)]="password" name="password"
                                                type="password" placeholder="Enter password" /></div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-12">
                                            <div class="form-group"><label class="small blm mb-1" for="confirm_password">Confirm Password</label>
                                                <input class="form-control" id="confirm_password" [(ngModel)]="confirm_password" name="confirm_password"
                                                type="password"
                                                placeholder="Re enter your password" /></div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <button class="btn btn-success btn-block" (click)="resetPassword()">Submit</button>
                                            </div>
                                        </div>
                                    </div>

                               </div>
                           </div>
                        </div>
                    </div>
                </div><br/><br/>
            </div>
        </div>
    </div>
</main>
</div>
</div>
</div>
