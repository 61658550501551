import { IUserPrivilege } from "src/app/common/interfaces/UserPrivilege";
import { AuthService } from "./../services/auth.service";
import { Component, OnInit } from "@angular/core";
import { TicketService } from "../services/ticket.service";
import { ITicket } from "./interfaces/Ticket";
import { ICustomer } from "../customer/interfaces/Customer";
import { CustomerService } from "../services/customer.service";
import { NotifierService } from "angular-notifier";
import { ApiEndPointsService } from "../common/api-end-points.service";
import { IOwnerType } from "../common/interfaces/OwnerType";
import { OtherService } from "../services/other.service";
import { Observable, of } from "rxjs";
import { ICounty } from "../common/interfaces/County";
import { ISubCounty } from "../common/interfaces/SubCounty";
import { COUNTYS } from "../common/interfaces/CountyData";
import { ExportService } from 'src/app/services/export.service';


@Component({
  selector: "app-ticket",
  templateUrl: "./ticket.component.html",
  styleUrls: ["./ticket.component.css"],
})
export class TicketComponent implements OnInit {
  canraise: boolean;
  canViewAll: boolean;
  canviewdetails: boolean;
  canedit: boolean;
  canaddcustomer: boolean;
  isBeneficiary: boolean;
  countydata: ICounty[];
  subcountySelection: ISubCounty[] = [];
  user: any;
  search_key: string;
  create_new: boolean = false;
  tickets: any[] = [];
  errors: string[] = [];
  customers: ICustomer[] = [];
  exportData: any[] =[];
  emptyId: string = null;
  showLoader: boolean;
  new_customer: ICustomer = {
    id: this.emptyId,
    createdAt: new Date(),
    updatedAt: new Date(),
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phone2: "",
    county: "",
    address: "",
    subCounty: "",
    password: "1234cust",
    identity: "",
    typeId: "",
    arn: "",
  };
  customerTypes: IOwnerType[] = [];
  privileges: IUserPrivilege[] = [];
  constructor(
    private ticketService: TicketService,
    private customerService: CustomerService,
    private notifier: NotifierService,
    private endPoints: ApiEndPointsService,
    private otherService: OtherService,
    private authService: AuthService,
    private exportService: ExportService
  ) {
    this.emptyId = this.endPoints.constants.emptyId;
    this.new_customer.id = this.emptyId;
  }

  ngOnInit() {
    this.countydata = COUNTYS;
    this.authService.getUser().subscribe((data) => {
      this.user = data;

    });
    this.getTickets();
    this.getCustomerTypes();
  }

  getTickets() {
    this.showLoader = true;
    if (this.user.isAdmin == 1 || this.canViewAll) {
      this.ticketService.getTickets('PR').subscribe(
        (data) => {
          if (data.success) {
            console.log(data)

            this.tickets = data.data;
            var counter = 1;
            this.tickets.forEach(element=>{
              var styled = {
                "S/NO" : counter,
                "Date Received": element.ticket.createdAt,
                "Requested By": element.customer.firstName + element.customer.lastName,
                "Request": element.ticket.description ,
                "Request Channel": element.ticket.source,
                "Date Acknoledged": element.ticket.reason,
                "Date Completed": element.dateCompleted,
                "Status": element.status,
              };counter++
              this.exportData.push(styled);
            });
          }
          this.showLoader = false;
        },
        (error) => console.log(<any>error)
      );
    }

    if (this.user.isAdmin == 0) {
      this.ticketService.getUserTickets(this.user.id, 'PR').subscribe(
        (data) => {
          if (data.success) {
            this.tickets = data.data;
          }
          this.showLoader = false;
        },
        (error) => console.log(<any>error)
      );
    }
  }

  newCustomer() {
    this.create_new = true;
  }

  searchCustomer() {
    this.customers = [];
    if (!this.search_key) {
      this.notifier.notify("error", "Enter search keyword first.");
      return;
    }
    this.showLoader = true;
    this.customerService.searchCustomer(this.search_key).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.customers = data.data;
        this.showLoader = false;
        return;
      }
      this.showLoader = false;
      this.notifier.notify("error", "Customer not found!!");
    });
  }

  getCustomerTypes() {
    this.otherService.getTypes("Customer").subscribe((data) => {
      if (data.success) {
        this.customerTypes = data.data;
      }
    });
  }

  saveCustomer() {
    this.validator(this.new_customer).subscribe((data) => {
      if (data.length > 0) {
        return;
      } else {
        this.showLoader = true;
        this.customerService
          .addCustomer(this.new_customer)
          .subscribe((data) => {
            if (data.success) {
              this.notifier.notify("success", data.message);
              this.customers.push(data.data);
              this.create_new = false;
              this.showLoader = false;
              return;
            }
            this.showLoader = false;
            this.notifier.notify("error", data.message);
          });
      }
    });
  }

  validator(customer: ICustomer): Observable<string[]> {
    if (!customer.typeId || customer.typeId == "" || customer.typeId == null) {
      this.errors.push("Please select a customer type first");
    }
    if (
      !customer.firstName ||
      customer.firstName == "" ||
      customer.firstName == null
    ) {
      this.errors.push("First name of customer is required");
    }
    if (
      !customer.lastName ||
      customer.lastName == "" ||
      customer.lastName == null
    ) {
      this.errors.push("Last name of customer is required");
    }
    if (!customer.email || customer.email == "" || customer.email == null) {
      this.errors.push("Email of customer is required");
    }
    if (!customer.phone || customer.phone == "" || customer.phone == null) {
      this.errors.push("Phone number of customer is required");
    }
    return of(this.errors);
  }

  populateSubCounties(county: string) {
    this.subcountySelection = this.countydata.find(
      (x) => x.name == county
    ).subcounties;
  }

  export() {
    this.exportService.exportExcel(this.exportData, 'Complains Reports');
  }

  showBeneficiaryDetails(option: string) {
    if (option === "yes") {
      this.isBeneficiary = true;
    }
    if (option === "no") {
      this.new_customer.arn = "";
      this.new_customer.identity = "";
      this.isBeneficiary = false;
    }
  }
}
