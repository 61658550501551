<div class="row">
  <div class="col-md-12">
      <notifier-container></notifier-container>
      <div class="card">
          <div class="card-header border-bottom">
              <ul class="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                  <li class="nav-item"><a class="nav-link active" id="sources-tab" href="#sources" data-toggle="tab"
                          role="tab" aria-controls="sources" aria-selected="true">Sources</a></li>
                  <li class="nav-item"><a class="nav-link" id="addsource-tab" href="#addsource" data-toggle="tab"
                          role="tab" aria-controls="addsource" aria-selected="false">Add Source</a></li>
              </ul>
          </div>
          <div class="card-body">
              <div class="tab-content" id="cardTabContent">
                  <div class="tab-pane fade show active" id="sources" role="tabpanel" aria-labelledby="sources-tab">
                      <div class="sb-datatable table-responsive">
                          <table class="table table-bordered table-hover display" datatable width="100%" cellspacing="0">
                              <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>Name</th>
                                      <th>Created On</th>
                                      <th>Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let source of sources;index as i">
                                      <td>{{i+1}}</td>
                                      <td>{{source.name}}</td>
                                      <td>
                                          <div class="badge badge-primary badge-pill">
                                              {{source.createdAt | date:'medium'}}</div>
                                      </td>
                                      <td>
                                          <button class="btn sb-btn-datatable sb-btn-icon sb-btn-outline-danger mr-2" (click)="deleteSource(source.id)">
                                              <i class="fa fa-trash"></i>
                                          </button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="addsource" role="tabpanel" aria-labelledby="addsource-tab">
                    <div class="row">
                      <div class="col-md-2"></div>
                      <div class="col-md-8">
                      <div class="form-row">
                          <div class="col-md-6">
                              <div class="form-group"><label class="small blm mb-1" for="name">Name</label>
                                  <input class="form-control " id="venue" name="name"
                                      [(ngModel)]="newSource.name" type="text" placeholder="Enter Name" />
                              </div>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="col-md-6">
                              <div class="form-group mt-4 mb-0">
                                  <button class="btn btn-primary btn-block" (click)="saveSource()">Create
                                      Type</button>
                              </div>
                          </div>
                      </div>
                    </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
