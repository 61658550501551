import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ILogin } from '../login/interfaces/Login';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  username: string;
  token:string;
  showLoader: boolean;
  user: any;
  login: ILogin = {
    email: "",
    password: "",
  };
  //redirectUrl = "http://197.254.113.139:83";
 // redirectUrl = "https://registration.ngobureau.go.ke/uat/front/#/staff-portal";
  redirectUrl = "https://registration.ngobureau.go.ke/#/index";
   //ssoUrl="https://sso.ngobureau.go.ke/login?redirect=https://localhost:44395/auth"
   ssoUrl="https://sso.ngobureau.go.ke/login?redirect=https://helpdesk.ngobureau.go.ke/auth"
  // ssoUrl="https://registration.ngobureau.go.ke/#/index"
  

  constructor(private authService: AuthService,private route: ActivatedRoute,
    private router: Router,private notifier: NotifierService) {
    this.username = this.route.snapshot.queryParamMap.get("username");
    debugger
    this.token=this.route.snapshot.queryParamMap.get("_token");
   }

  ngOnInit() {
    debugger
    var s= this.token;
    if(this.username){
      this.login.email = this.username;
      this.authenticate();
    }else if(this.token){
      this.authenticate();
    }else{
      window.location.href = this.ssoUrl;
    }
  }

  authenticate() {
    debugger
    if(this.token){
      this.authService.validateToken(this.token).subscribe((result)=>{
        debugger
        if(result.success){
          debugger
          var res=JSON.parse(result.data);
          if(res.status){
            this.login.email=res.data.email;
            this.authenticateTokens();
            return;
          }else{
            this.showLoader = false;
            debugger
            this.notifier.notify("error", result.message);
            window.location.href = this.ssoUrl;
          }
        }else{
          this.showLoader = false;
          debugger
          this.notifier.notify("error", result.message);
          window.location.href = this.ssoUrl;
          return;
        }

      });

    }else{
      this.authService.authenticate(this.login).subscribe((data) => {
        console.log(data);
        if (data.success) {
          this.notifier.notify("success", data.message);
          this.user = data.data;
          this.authService.setUserId(this.user.id).subscribe(logsuccess=>{
            if(!logsuccess){
              window.location.href = this.redirectUrl;
              return;
            }
          });
          this.authService.setUser(this.user);
          //this.getUserPrivileges();
          this.showLoader = false;
          this.router.navigate(["dashboard"]);
          //window.location.reload();
          return;
        }else{
          this.showLoader = false;
          this.notifier.notify("error", data.message);
          window.location.href = this.redirectUrl;
          return;
        }
      });
    }

  }
  authenticateTokens(){
    debugger
    this.authService.authenticate(this.login).subscribe((data) => {
      console.log(data);
      debugger
      if (data.success) {
        debugger
        this.notifier.notify("success", data.message);
        this.user = data.data;
        this.authService.setUserId(this.user.id).subscribe(logsuccess=>{
          if(!logsuccess){
            window.location.href = this.redirectUrl;
            return;
          }
        });
        this.authService.setUser(this.user);
        //this.getUserPrivileges();
        this.showLoader = false;
        this.router.navigate(["dashboard"]);
        //window.location.reload();
        return;
      }else{
        this.showLoader = false;
        this.notifier.notify("error", data.message);
        window.location.href = this.redirectUrl;
        return;
      }
    });

  }

}
