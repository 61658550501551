<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main>
  <notifier-container></notifier-container>

  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
      <div class="container-fluid">
          <div class="sb-page-header-content py-5">
              <h1 class="sb-page-header-title">
                  <div class="sb-page-header-icon"><i data-feather="droplet"></i></div>
                  <span>Department Details</span>
              </h1>
              <div class="sb-page-header-subtitle">
              </div>
          </div>
      </div>
  </div>
  <div class="container-fluid mt-n10">
      <div class="row">
          <div class="col-lg-11">
              <div class="card">
                  <div class="card-header">
                      Department Details
                  </div>
                  <div class="card-body">
                      <app-loading *ngIf="showLoader"></app-loading>
                      <div class="row" *ngIf="!showLoader">
                          <div class="col-md-4">
                              <ul class="list-group list-group-flush">
                                  <li class="list-group-item">Title: {{department?.name}}</li>
                                  <li class="list-group-item">Email : {{department?.email}}</li>
                                  <li class="list-group-item">Created On : {{department?.createdAt | date:'medium'}}</li>
                                  <li class="list-group-item">HOD : {{departmentHead?.firstName}} {{departmentHead?.lastName}} | {{departmentHead?.email}}</li>
                                </ul>
                          </div>
                          <div class="col-md-8">
                              <h6>Deparment Employees</h6>
                              <div class="sb-nav-sticky">
                                  <div class="card">
                                      <div class="card-body">
                                        <div class="sb-datatable table-responsive">
                                          <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                                              <thead>
                                                  <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                    <th>Is Head</th>
                                                    <th>Actions</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr *ngFor="let employee of employees;index as i">
                                                      <td>{{i+1}}</td>
                                                      <td>{{employee?.firstName}} {{employee.lastName}}</td>
                                                      <td>{{employee?.phone}}</td>
                                                      <td>{{employee?.email}}</td>
                                                      <td>
                                                        <span class="badge badge-pill badge-success" *ngIf="employee?.id == departmentHead?.id">H.O.D</span>
                                                      </td>
                                                      <td>
                                                              <button class="badge badge-primary" (click)="assignAsHead(employee?.id)">
                                                                  <i class="fa fa-user"></i> Assign
                                                              </button>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div><br/><br/>
          </div>
          <div class="col-lg-1">

          </div>
      </div><br>
      <div class="row" *ngIf="!showLoader">
          <div class="col-lg-11">
            <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModalLong">
                Add New Team  <i class="fa fa-plus"></i>
             </button>
              <div class="card">
                <div class="card-header">
                    <h4>Department Teams</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6" *ngFor="let team of teams">
                            <div class="card">
                                <div class="card-body">
                                    <div class="sb-datatable table-responsive">
                                        <h4 class="text-primary">{{team.team.name}}</h4>
                                        <div style="text-align: right;">
                                          <div class="badge badge-warning" (click)="triggerEditTeam(team?.team?.id, team?.team?.name)" style="text-align: right;" data-toggle="modal" data-target="#exampleEditTeam">Edit <i class="fa fa-edit"></i></div>
                                        </div>
                                        <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
                                            <thead>
                                                <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let member of team.teamMembers;index as i">
                                                    <td>{{i+1}}</td>
                                                    <td>{{member?.firstName}} {{member.lastName}}</td>
                                                    <td>{{member?.phone}}</td>
                                                    <td>{{member?.email}}</td>
                                                    <td>
                                                        <button class="badge badge-primary" (click)="removeMember(team.team.id,member.id)">
                                                            <i class="fa fa-times"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                            <div class="row">
                                                <div class="col-md-8">
                                                    <div class="form-group">
                                                        <select class="form-control" id="{{team.team.id}}" (change)="selectOption($event.target.value)">
                                                            <option value="" selected="selected">Select Employee</option>
                                                            <option *ngFor="let dept_emp of employees" value="{{dept_emp.id}}">
                                                            {{dept_emp.firstName}} {{dept_emp.lastName}} | {{dept_emp.email}}
                                                        </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <button class="btn btn-success btn-sm" (click)="addMember(team.team.id)">
                                                        Add Member<i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <ul class="list-group">
                                                <li class="list-group-item active"><h6>Team Associated Ticket/Task Types</h6></li>
                                                <li class="list-group-item" *ngFor="let teamType of team.teamTypes">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            {{teamType?.name }} | {{teamType?.owner}}
                                                        </div>
                                                        <div class="col-md-4">
                                                            <button class="badge badge-primary" (click)="removeType(team?.team.id,teamType?.id)">
                                                                <i class="fa fa-times"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="list-group-item">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div class="form-group">
                                                                <select class="form-control" id="{{team.team.id}}" (change)="selectTypeOption($event.target.value)">
                                                                    <option value="" selected="selected">Select Ticket/Task Type</option>
                                                                    <option *ngFor="let owner_type of ownerTypes" value="{{owner_type.id}}">
                                                                    {{owner_type.name}}
                                                                </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <button class="btn btn-success btn-sm" (click)="addType(team.team.id)">
                                                                Add Type<i class="fa fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</main>
</div>
</div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Create Team</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-md-12">
            <div class="form-row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="small blm mb-1" for="name">Team Name</label>
                        <input class="form-control py-4" id="name" name="name" type="text" [(ngModel)]="newTeam.name" placeholder="Enter team name" />
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="saveTeam()">Save <i class="fa fa-save"></i></button>
        </div>
      </div>
    </div>
  </div>


<!-- Modal -->
<div class="modal fade" id="exampleEditTeam" tabindex="-1" role="dialog" aria-labelledby="exampleEditTeamTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Edit Team</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="form-row">
              <div class="col-md-12">
                  <div class="form-group">
                      <label class="small blm mb-1" for="name">Team Name</label>
                      <input class="form-control py-4" id="name" name="name" type="text" [(ngModel)]="editTeam.name" placeholder="Enter new team name" />
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveEditTeam()">Update <i class="fa fa-save"></i></button>
      </div>
    </div>
  </div>
</div>
