import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError} from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { ApiEndPointsService } from '../common/api-end-points.service';
import { ICustomer } from '../customer/interfaces/Customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  baseUrl: string;
  constructor(private http : HttpClient, private endPoints: ApiEndPointsService, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getCustomers() : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.customer.getcustomers)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getCustomer(id : string) : Observable<any>{
    return this.http.get(this.baseUrl+ this.endPoints.customer.getcustomerdetails+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addCustomer(customer : ICustomer) : Observable<any>{
      return this.http.post(this.baseUrl+ this.endPoints.customer.addcustomer, customer)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  editCustomer(customer : ICustomer) : Observable<any>{
    return this.http.post(this.baseUrl+ this.endPoints.customer.editCustomer, customer)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  searchCustomer(phone: string) : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.customer.searchcustomer+phone)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getAnonymousUser(): Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.customer.getanonymoususer)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
