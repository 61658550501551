import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  userId: string;
  user: any;
  //redirectUrl = "https://registration.ngobureau.go.ke/uat/front/#/staff-portal";
  redirectUrl = "https://registration.ngobureau.go.ke/#/index";
  constructor(private authService: AuthService, private router: Router) {
    this.userId = localStorage.getItem("userId");
  }

  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;
    });
  }

  logout() {
    // this.authService.removeUserId();
    // this.router.navigate([""]);
    window.location.href = this.redirectUrl;
  }
}
