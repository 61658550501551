<main>
    <div class="row">
        <div class="col-md-12">
            <h1 class="sb-page-header-title">
                <div class="sb-page-header-icon"><i data-feather="file"></i></div>
                <span>Welcome To Support Management</span>
            </h1>
        </div>
    </div>
<div class="row" style="width: 100%;">
    <div class="col-md-4">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="card shadow-lg border-0 rounded-lg mt-5">
                        <div class="card-header justify-content-center"><h3 class="font-weight-light my-4">Login</h3></div>
                        <div class="card-body">
                            <form>
                                <div class="form-group"><label class="small blm mb-1" for="inputEmailAddress">Email</label><input class="form-control py-4" id="inputEmailAddress" type="email" placeholder="Enter email address" /></div>
                                <div class="form-group"><label class="small blm mb-1" for="inputPassword">Password</label><input class="form-control py-4" id="inputPassword" type="password" placeholder="Enter password" /></div>
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox"><input class="custom-control-input" id="rememberPasswordCheck" type="checkbox" /><label class="custom-control-label" for="rememberPasswordCheck">Remember password</label></div>
                                </div>
                                <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0"><a class="small" href="password-basic.html">Forgot Password?</a><a class="btn btn-primary" href="index-2.html">Login</a></div>
                            </form>
                        </div>
                        <div class="card-footer text-center">
                            <div class="small"><a href="register-basic.html">Need an account? Sign up!</a></div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
    <div class="col-md-4">

    </div>
    <div class="col-md-4>">

    </div>
</div>
</main>
