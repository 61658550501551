import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError} from 'rxjs/operators';
import { HttpClient} from "@angular/common/http";
import { ApiEndPointsService } from '../common/api-end-points.service';
import { IDepartment } from '../department/interfaces/Department';
import { IDepartmentHead } from '../department/interfaces/DepartmentHead';
import { ITeam } from '../department/interfaces/Team';
import { ITeamMember } from '../department/interfaces/TeamMember';
import { ITeamType } from '../department/interfaces/TeamType';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
baseUrl: string;
  constructor(private http : HttpClient, private endPoints: ApiEndPointsService, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
   }

  getDepartments() : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.department.getdepartments)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getDepartmentEmployees(id: string) : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.department.getdepartmentemployees+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getDepartment(id : string) : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.department.getdepartment+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getDepartmentHead(id : string) : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.department.getdepartmenthead+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addDepartment(department : IDepartment) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.department.adddepartment, department)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  editDepartment(department : IDepartment) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.department.editdepartment, department)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  editTeam(team : ITeam) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.department.editteam, team)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addDepartmentHead(head : IDepartmentHead) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.department.adddepartmenthead, head)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getTeams() : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.department.getteams)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  getDepartmentTeams(id: string) : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.department.getdepartmentteams+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addTeam(team: ITeam) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.department.addteam, team)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addTeamMember(member : ITeamMember): Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.department.addteammember, member)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  removeTeamMember(member : ITeamMember) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.department.removeteammember, member)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addTeamType(type : ITeamType): Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.department.addteamtype, type)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  removeTeamType(type : ITeamType) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.department.removeteamtype, type)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
