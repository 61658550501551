<notifier-container></notifier-container>
<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
    <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-fluid">
            <div class="sb-page-header-content py-5">
                <h1 class="sb-page-header-title">
                    <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
                    <span>Add Department</span>
                </h1>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-n10">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="card shadow-lg border-0 rounded-lg mt-5">
                        <div class="card-header justify-content-center"><h3 class="font-weight-light my-4">Create Department</h3></div>
                        <div class="card-body">

                            <form>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="name">Department Name</label>
                                            <input class="form-control" id="Name" name="Name" type="text" [(ngModel)]="department.name" placeholder="Enter department name" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="email">Email</label>
                                            <input class="form-control" type="email" id="Email" name="Email" [(ngModel)]= "department.email"  placeholder="Enter email address" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mt-4 mb-0">
                                    <button class="btn btn-success btn-block" (click) = "saveDepartment()">Create Department</button>
                                    <a class="btn btn-primary btn-block" [routerLink]="['/departments']">Cancel</a>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer text-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</div>
</div>
</div>
