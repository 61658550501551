import { Component, OnInit } from '@angular/core';
import { FaqService } from 'src/app/services/faq.service';

@Component({
  selector: 'app-internal-faq',
  templateUrl: './internal-faq.component.html',
  styleUrls: ['./internal-faq.component.css']
})
export class InternalFaqComponent implements OnInit {
  faqs: any[] = [];
  type: "";
  showLoader: boolean;
  constructor(private faqService: FaqService) {}

  ngOnInit() {
    this.getFaqs();
  }

  getFaqs() {
    this.showLoader = true;
    
    this.faqService.getFaqs("Internal").subscribe((data) => {
      console.log(data);
      if (data.success) {
        this.faqs = data.data;
      } else {
        this.showLoader = false;
      }
    });
  }
}
