<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main>
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>Dashboard</span>
        </h1>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <div class="card bg-primary text-white mb-4">
          <div class="card-body" *ngIf="user?.designation=='PR'|| user?.designation==null">Tickets Closed</div>
          <div class="card-body" *ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">Requests Closed</div>
          <div class="card-body" *ngIf="user?.designation=='SuperAdmin'">Tickets Closed</div>

          <div class="card-footer d-flex align-items-center justify-content-between">
            <a class="small text-white stretched-link" href="javascript:void(0)">{{dashboard?.ticketClosed}}</a>
            <div class="small text-white"></div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card bg-warning text-white mb-4">
          <div class="card-body"*ngIf="user?.designation=='PR'|| user?.designation==null">Tickets Opened</div>
          <div class="card-body"*ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">Requests Opened</div>
          <div class="card-body"*ngIf="user?.designation=='SuperAdmin'">Tickets Opened</div>
          <div class="card-footer d-flex align-items-center justify-content-between">
            <a class="small text-white stretched-link" href="javascript:void(0)">{{dashboard?.ticketOpened}}</a>
            <div class="small text-white"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-xl-4 col-md-6">
        <div class="card bg-warning text-white mb-4">
          <div class="card-body"*ngIf="user?.designation=='PR'|| user?.designation==null">Due Tickets</div>
          <div class="card-body"*ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">Due Requests</div>
          <div class="card-body"*ngIf="user?.designation=='SuperAdmin'">Due Tickets</div>
          <div class="card-footer d-flex align-items-center justify-content-between">
            <a class="small text-white stretched-link" href="javascript:void(0)">{{dashboard?.dueTickets}}</a>
            <div class="small text-white"></div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6">
        <div class="card bg-success text-white mb-4">
          <div class="card-body"*ngIf="user?.designation=='PR'|| user?.designation==null">Overdue Tickets</div>
          <div class="card-body"*ngIf="user?.designation=='IT' || user?.designation=='Other Departments'">Overdue Requests</div>
          <div class="card-body"*ngIf="user?.designation=='SuperAdmin'">Overdue Tickets</div>
          <div class="card-footer d-flex align-items-center justify-content-between">
            <a class="small text-white stretched-link" href="javascript:void(0)">{{dashboard?.overDueTickets}}</a>
            <div class="small text-white"></div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6">
        <div class="card bg-danger text-white mb-4">
          <div class="card-body" *ngIf="user?.designation=='PR'|| user?.designation==null">Critical Tickets</div>
          <div class="card-body" *ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">Critical Requests</div>
          <div class="card-body"*ngIf="user?.designation=='SuperAdmin'">Critical Tickets</div>
          <div class="card-footer d-flex align-items-center justify-content-between">
            <a class="small text-white stretched-link" href="javascript:void(0)">{{dashboard?.criticalTickets}}</a>
            <div class="small text-white"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="card mb-4 col-md-6">
        <div class="card-header"*ngIf="user?.designation=='PR'|| user?.designation==null">Open/Closed Complains Tickets</div>
        <div class="card-header"*ngIf="user?.designation=='IT'|| user?.designation=='Other Departments'">Open/Closed Requests Comparison</div>
        <div class="card-header"*ngIf="user?.designation=='SuperAdmin'">Open/Closed Tickets Comparison</div>
        <div class="card-body">
          <canvas #myBarTicketComp></canvas>
        </div>
      </div>
    </div>
    <br>
  </div>
</main>
</div>
</div>
</div>
