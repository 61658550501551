<notifier-container></notifier-container>
<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
    <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-fluid">
            <div class="sb-page-header-content py-5">
                <h1 class="sb-page-header-title">
                    <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
                    <span>Edit</span>
                </h1>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-n10">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="card shadow-lg border-0 rounded-lg mt-5">
                        <div class="card-header justify-content-center"><h3 class="font-weight-light my-4">Edit </h3></div>
                        <div class="card-body">
                            <div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="form-group"><label class="small blm mb-1" for="meeting">Ticket Title</label>
                                            <input class="form-control" id="title" [(ngModel)]="ticket.title" name="title" type="text" placeholder="Enter title" /></div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="small blm mb-1" for="description">Description</label>
                                            <textarea class="form-control sb-form-control-solid" id="description" name="description"
                                             [(ngModel)]="ticket.description" placeholder="Enter meeting description" rows="3"></textarea>
                                    </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="typeId">Type</label>
                                            <select class="form-control" id="typeId" [(ngModel)]="ticket.typeId">
                                                <option value="">Select Type</option>
                                            <option *ngFor="let type of types" value="{{type.id}}">{{type.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="form-group"><label class="small blm mb-1" for="source">Source</label>
                                        <select class="form-control" id="source" [(ngModel)]="ticket.source">
                                            <option value="">Select Source</option>
                                            <option *ngFor="let item of sources" value="{{item.name}}">{{item.name}}</option>
                                        </select>
                                    </div>
                                    </div>
                                </div>
                                <div class="form-group mt-4 mb-0">
                                    <button class="btn btn-success btn-block" (click)="editTicket()">Save Ticket</button>
                                    <a *ngIf="loginUser?.designation=='IT' && loginUser?.isAdmin == 1" class="btn btn-primary btn-block" [routerLink]="['/itHelpdesk']">Cancel</a>
                                    <a *ngIf="(loginUser?.designation=='PR' || loginUser?.designation=='SuperAdmin') && loginUser?.isAdmin == 1" class="btn btn-primary btn-block" [routerLink]="['/complains']">Cancel</a>
                                    <a *ngIf="loginUser?.isAdmin == 0" class="btn btn-primary btn-block" [routerLink]="['/myItRequest']">Cancel</a>
                                    <!-- <a *ngIf="loginUser?.designation=='IT'" class="btn btn-primary btn-block" [routerLink]="['/complains']">Cancel</a> -->

                                 </div>
                            </div>
                        </div>
                        <div class="card-footer text-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</div>
</div>
</div>
