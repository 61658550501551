import { IUserPrivilege } from './../common/interfaces/UserPrivilege';
import { tap, catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndPointsService } from '../common/api-end-points.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {
baseUrl: string;
  constructor(private http : HttpClient, private endPoints : ApiEndPointsService, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
   }

  getPrivileges() : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.privileges.getprivileges).pipe(tap(), catchError(this.endPoints.handleError))
  }

  getUserPrivileges(id: string) : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.privileges.getuserprivileges+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addPrivilege(userPrivilege : IUserPrivilege) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.privileges.adduserprivilege, userPrivilege)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  removePrivilege(userPrivilege : IUserPrivilege) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.privileges.removeuserprivilege, userPrivilege)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
