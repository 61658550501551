import { IUserPrivilege } from "./../common/interfaces/UserPrivilege";
import { IEmployee } from "src/app/employee/interfaces/Employee";
import { Injectable, Inject } from "@angular/core";
import { ILogin } from "../login/interfaces/Login";
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ApiEndPointsService } from "../common/api-end-points.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl: string;
  constructor(
    private router: Router,
    private http: HttpClient,
    private endPoints: ApiEndPointsService,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.baseUrl = baseUrl;
  }

  getUserId(): Observable<any> {
    return of(localStorage.getItem("userId"));
  }


  setUserId(id: string): Observable<any> {
    localStorage.setItem("userId", id);
    return of(1);
  }
  setUser(user: Object): Observable<any> {
    localStorage.setItem("user", JSON.stringify(user));
    return of(1);
  }

  setUserPrivileges(privileges: IUserPrivilege[]): Observable<any> {
    localStorage.removeItem("privileges");
    localStorage.setItem("privileges", JSON.stringify(privileges));
    return of(true);
  }

  getUserPrivileges(): Observable<any> {
    return of(JSON.parse(localStorage.getItem("privileges")));
  }

  getUser(): Observable<any> {
    return of(JSON.parse(localStorage.getItem("user")));
  }

  removeUserId() {
    localStorage.clear();
    window.location.reload();
  }

  authenticate(login: ILogin): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.login.signin, login)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  validateToken(token: string): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.login.validatetoken+token)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  passwordReset(email: string): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.login.passwordreset + email)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  resetPassword(password: string, userId: string): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.login.resetpassword, {
        password: password,
        userId: userId,
      })
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  makeAdmin(id: string): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.login.makeadmin + id)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  removeAdmin(id: string): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.login.removeadmin + id)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
