<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main>
  <notifier-container></notifier-container>

  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
      <div class="container-fluid">
          <div class="sb-page-header-content py-5">
              <h1 class="sb-page-header-title">
                  <div class="sb-page-header-icon"><i data-feather="droplet"></i></div>
                  <span>Survey Details</span>
              </h1>
              <div class="sb-page-header-subtitle">
              </div>
          </div>
      </div>
  </div>
  <div class="container-fluid mt-n10">
      <div class="row">
          <div class="col-lg-12">
              <div class="card">
                  <div class="card-header">
                      Survey Details
                  </div>
                  <div class="card-body">
                      <div class="row">
                          <div class="col-md-6">
                              <ul class="list-group list-group-flush">
                                  <li class="list-group-item"> Title : {{surveyDetail?.survey?.title}}</li>
                                  <li class="list-group-item"> Description : {{surveyDetail?.survey?.description}}</li>
                                  <li class="list-group-item"> Department : {{surveyDetail?.survey?.department}}</li>
                                  <li class="list-group-item"> Total Questions : <div class="badge badge-info">{{surveyDetail?.questions.length}}</div></li>
                                  <li class="list-group-item"> Created On : {{surveyDetail?.survey?.createdAt | date:'medium'}}</li>
                                </ul>
                          </div>
                          <div class="col-md-6">

                         </div>
                      </div>
                  </div>
              </div><br/>
              <div class="card">
                  <div class="card-header" style="width: 100% !important;">
                      <h6>Survey Questions</h6> <br>
                      <button class="btn btn-outline-primary" class="btn btn-primary btn-sm" data-toggle="modal" style="text-align: right;"
                       data-target="#questionDialog" *ngIf="surveyDetail?.survey?.id != emptyId && surveyDetail?.survey?.status == 'Pending'">
                        Add Question <i class="fa fa-plus"></i>
                    </button>
                  </div>
               <div class="card-content" *ngIf="surveyDetail?.questions?.length > 0">
                <ul *ngFor="let questItem of surveyDetail?.questions;let i = index" style="list-style-type:none;">
                      <h6>{{i+1}} . {{questItem?.surveyQuestion.question}}</h6>
                      <li *ngIf="questItem.surveyQuestion.type ==='OpenQuiz'">
                        <div class="custom-control md-textarea">
                          <textarea class="form-control sb-form-control-solid"
                            placeholder="Enter the question" rows="2"></textarea><br>
                        </div>
                      </li>
                      <ul *ngFor="let optionItem of questItem.questionOptions" style="list-style-type:none;">
                          <li *ngIf="questItem.surveyQuestion.type ==='multiple'">
                            <div class="custom-control custom-checkbox"><input class="custom-control-input" id="{{optionItem.option.id}}"
                               type="checkbox">
                               <label class="custom-control-label" for="{{optionItem.option.id}}">{{optionItem.option.option}} |  <div class="badge badge-success">{{optionItem.responseCount}}</div> Selections</label>
                            </div>
                          </li>
                          <li *ngIf="questItem.surveyQuestion.type ==='single'|| questItem.surveyQuestion.type=== 'Review'">
                            <div class="custom-control custom-radio"><input class="custom-control-input" id="{{optionItem.option.id}}" type="radio" name="{{questItem.surveyQuestion.id}}">
                              <label class="custom-control-label" for="{{optionItem.option.id}}">{{optionItem.option.option}} |  <div class="badge badge-success">{{optionItem.responseCount}}</div> Selections</label>
                            </div>
                          </li>

                      </ul>

                </ul>
               </div>
          </div>
          </div>
      </div>
  </div>
</main>
</div>
</div>
</div>
<!-- Modal -->
<div class="modal fade" id="questionDialog" tabindex="-1" role="dialog" aria-labelledby="questionDialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Add Question</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="form-group">
              <label class="small mb-1" for="newQuiz">Survey Question</label>
              <textarea class="form-control sb-form-control-solid" id="newQuiz" name="newQuiz"
              [(ngModel)]="newQuiz" placeholder="Enter question" rows="3"></textarea>
          </div>
          <div class="form-group">
            <div class="form-group">
              <label class="small mb-1" for="typeId">Question Type</label>
              <select class="form-control" id="typeId" [(ngModel)]="activeType">
                <option value="">Select Question Type</option>
                <option value="single">Single Choice</option>
                <option value="multiple">Multiple Choices</option>
                <option value="OpenQuiz">Open Quistion</option>
                <option value="Review">Service Review</option>
              </select>
          </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="saveQuestion()" data-dismiss="modal">Save Question<i class="fa fa-save"></i></button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="optionDialog" tabindex="-1" role="dialog" aria-labelledby="optionDialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Add Question Option</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="form-group">
            <label class="small mb-1" for="newOption">Question Option</label>
            <input type="text" class="form-control" [(ngModel)]="newOption" id="newOption" name="newOption" placeholder="Question option"/>
        </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="saveOption()" data-dismiss="modal">Save <i class="fa fa-save"></i></button>
      </div>
    </div>
  </div>
</div>

