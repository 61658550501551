import { Component, OnInit } from "@angular/core";
import { CustomerService } from "../services/customer.service";
import { ICustomer } from "./interfaces/Customer";
import { IUserPrivilege } from "../common/interfaces/UserPrivilege";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.css"],
})
export class CustomerComponent implements OnInit {
  canraise: boolean;
  canviewdetails: boolean;
  canedit: boolean;
  canraiseticket: boolean;
  showLoader: boolean;
  user: any;
  constructor(
    private customerService: CustomerService,
    private authService: AuthService
  ) {}
  customers: ICustomer[] = [];
  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;

    });

    this.getCustomers();
  }

  getCustomers() {
    this.showLoader = true;
    this.customerService.getCustomers().subscribe((data) => {
      if (data.success) {
        this.customers = data.data;
        this.showLoader = false;
      } else {
        this.showLoader = false;
      }
    });
  }

}
