import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { SurveyService } from 'src/app/services/survey.service';
import { IResponse } from 'src/app/survey/interfaces/QuestionResponse';
import { IResponseView } from 'src/app/survey/interfaces/ResponseView';
import { ISurveyDetail } from 'src/app/survey/interfaces/SurveyDetail';
import {IReviewOptions} from "src/app/common/interfaces/ReviewOptions";
import { OtherService } from 'src/app/services/other.service';
import { DepartmentService } from "src/app/services/department.service";
import{IDepartment} from 'src/app/department/interfaces/Department';
import { EmployeeService } from "src/app/services/employee.service";
import { IEmployee } from "src/app/employee/interfaces/Employee";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: 'app-staff-survey-detail',
  templateUrl: './staff-survey-detail.component.html',
  styleUrls: ['./staff-survey-detail.component.css']
})
export class StaffSurveyDetailComponent implements OnInit {

  surveyDetail: ISurveyDetail;
  surveyId : string;
  responses: IResponse[] = [];
  departments: IDepartment[] = [];
  reviews: IReviewOptions[]=[];
  openResponse: "";
  departmentId: any[];
  emptyId: string;
  userId: string;
  employee: IEmployee;
  loginUser: any;
  hasConducted: boolean;
  constructor(
    private surveyService: SurveyService,
    private otherService: OtherService,
    private departmentService: DepartmentService,
    private endPoints: ApiEndPointsService,
    private notifier: NotifierService,
    private route : ActivatedRoute,
    private authService: AuthService,
    private router: Router) {
    this.surveyId = this.route.snapshot.paramMap.get('id');
    this.emptyId = endPoints.constants.emptyId;

    
    this.emptyId = this.endPoints.constants.emptyId;
    this.authService.getUser().subscribe((data) => {
      this.loginUser = data;
      console.log(this.loginUser);
    });
  
   
  }

  ngOnInit() {
    this.getActiveSurvey();
    this.getReviewOptions();
    this.getSurveyDetails();
    this.hasConductedSurvey();
  }
  getReviewOptions(){
 
    this.otherService.getReviewOptions().subscribe(data => {
      if (data.success) {
        this.reviews = data.data;

      }else{
        this.notifier.notify('error', data.message);
      }

    });
  }
  getDepartments() {
    this.departmentService.getDepartments().subscribe((data) => {
      if (data.success) {
        this.departments = data.data;
      } else {
      }
    });
  }

 
  getSurveyDetails() {
    this.surveyService.getSurveyDetail(this.surveyId).subscribe((data) => {
      if (data.success) {
        this.surveyDetail = data.data;
      }
      return;
    });
  }


  getActiveSurvey(){
    this.surveyService.getSurveyDetail(this.surveyId).subscribe(data=>{
      if(data.success){
        this.surveyDetail = data.data;
      }
    });
  }

  addRemoveResponseChoice(optionId : string, questionId: string, e){
    if(e.target.checked){
      let optionCheck: IResponse = {
        id: this.emptyId,
        questionId: questionId,
        optionId: optionId,
        createdAt: new Date,
        updatedAt: new Date,
        customerId: this.loginUser.type == "Customer" ? this.loginUser.id : this.emptyId,
        employeeId: this.loginUser.type == "Employee" ? this.loginUser.id : this.emptyId,
      
        openResponse :""
      };
      this.responses.push(optionCheck);
    }
    if(!e.target.checked){
      this.responses = this.responses.filter(x=>x.questionId != questionId && x.optionId !=optionId);
     }

  }
  addResponse(questionId:string, openResponse:string){
    if(openResponse.length >=1){
      let openRes: IResponse = {
        id: this.emptyId,
        questionId: questionId,
        optionId: this.emptyId,
        createdAt: new Date,
        updatedAt: new Date,
        employeeId:this.loginUser.type == "Employee" ? this.loginUser.id : this.emptyId,
        customerId: this.loginUser.type == "Customer" ? this.loginUser.id : this.emptyId,
        openResponse: openResponse,
       
      };
      console.log(openRes);
      this.responses.push(openRes);
    }
    else if(openResponse.length <1){
      this.responses= this.responses.filter(x=>x.questionId !=questionId && x.openResponse !=openResponse)

    }

  }
  

  submitSurvey(){
    if(this.responses.length == 0){
      this.notifier.notify('error', 'Please fill the survey first before submit.');
      return;
    }
    let resp: IResponseView = {
      responses: this.responses
    };

    this.surveyService.saveResponses(resp).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
        this.surveyDetail = null;
        this.router.navigate(['/staff-survey']);
        return;
      }
      this.notifier.notify('error', data.message); 
    });
  }

  hasConductedSurvey(){
    let has = {
      "id": this.loginUser.id,
      "type": this.loginUser.type,
      "surveyId": this.surveyId
    };
    this.surveyService.conductedSurvey(has).subscribe(data=>{
      console.log(data);
      if(data.success){
        this.hasConducted = data.data;
      }
    });
  }

}
