<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <notifier-container></notifier-container>
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>Surveys</span>
        </h1>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header"><a class="btn btn-outline-primary btn-sm" [routerLink]="['/add-survey']"
              routerLinkActive="router-link-active" *ngIf="user.isAdmin == 1">
              <i class="fa fa-plus"></i> Add Survey</a></div>
          <div class="card-body">
            <div class="sb-datatable table-responsive">
              <table class="table table-bordered table-hover" datatable width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Created On</th>
                    <th>Questions</th>
                    <th>Responses</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let survey of surveys;index as i">
                    <td>MSS-{{i+1}}</td>
                    <td>{{survey.survey.title }}</td>
                    <td>{{ survey.survey.status }}</td>
                    <td>{{ survey.survey.type }}</td>
                    <td>{{survey.survey.createdAt | date:'medium'}}</td>
                    <td>
                      <div class="badge badge-primary badge-pill">{{survey.questionCount }}</div>
                    </td>
                    <td>
                      <div class="badge badge-info badge-pill">{{survey.responseCount}}</div>
                    </td>
                    <td>
                      <a class="badge badge-primary" [routerLink]="['/survey-details', survey.survey.id]"
                        *ngIf="user.isAdmin == 1">
                        <i class="fa fa-eye"></i> View
                      </a> |
                      <button class="badge badge-primary" (click)="setActiveId(survey.survey.id)" data-toggle="modal"
                        data-target="#optionDialog" *ngIf="user.isAdmin == 1">
                        <i class="fa fa-edit"></i> Change Status
                      </button> |
                      <button type="button" class="badge badge-primary" (click)="setSurveyNotifyId(survey.survey.id)" data-toggle="modal"
                          data-target="#exampleModalLong">
                          <i class="fa fa-bell" aria-hidden="true"></i>  Notify Staff
                        </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</div>
</div>
</div>
<!-- Modal -->
<div class="modal fade" id="optionDialog" tabindex="-1" role="dialog" aria-labelledby="optionDialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Change Survey Status</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="form-group">
            <label class="small mb-1" for="typeId">Change Status</label>
            <select class="form-control" id="typeId" [(ngModel)]="status">
              <option value="">Select Status</option>
              <option value="Active">Activate Survey</option>
              <option value="Closed">Close Survey</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="changeStatus()" data-dismiss="modal">Update <i
            class="fa fa-save"></i></button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Notify Staff Via Email</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let employee of employees">
              <div class="custom-control custom-checkbox"><input class="custom-control-input"
                  (change)="addRemoveId(employee.id, $event)" id="{{employee.id}}" type="checkbox">
                <label class="custom-control-label" for="{{employee.id}}">
                  {{employee.firstName}} {{employee.lastName}} |{{employee.email}}
                </label></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close <i
            class="fa fa-times"></i></button>
        <button type="button" class="btn btn-primary btn-sm" (click)="notify()" data-dismiss="modal">Send 
          Notification <i class="fa fa-check"></i></button>
      </div>
    </div>
  </div>
</div>