import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { ApiEndPointsService } from "../common/api-end-points.service";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  baseUrl: string;
  constructor(
    private http: HttpClient,
    private endPoints: ApiEndPointsService,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.baseUrl = baseUrl;
  }

  getTickets(reportField: Object): Observable<any> {
    console.log(reportField);
    return this.http
      .post(this.baseUrl + this.endPoints.report.gettickets, reportField)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getCustomers(reportField: Object): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.report.getcustomers, reportField)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
