<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main>
    <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-fluid">
            <div class="sb-page-header-content py-5">
                <h1 class="sb-page-header-title">
                    <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
                    <span>Settings</span>
                </h1>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-n10">
        <app-smtp-setting></app-smtp-setting>
        <app-sources></app-sources>
        <app-escalation-setting></app-escalation-setting>
        <app-type-setting></app-type-setting>
        <app-review-setting></app-review-setting>
    </div>
</main>
</div>
</div>
</div>
<script>
  $('table.display').DataTable();
</script>
