<nav class="sb-sidenav sb-shadow-right sb-sidenav-light" *ngIf="isLogged">
  <div class="sb-sidenav-menu">
    <div class="nav accordion" id="accordionSidenav">
      <div class="sb-sidenav-menu-heading">Dashboards</div>
      <a class="nav-link" [routerLink]="['/']" routerLinkActive="router-link-active">
        <div class="sb-nav-link-icon"><i class="fa fa-chart-line"></i></div>
        Dashboard
      </a>
      <div class="sb-sidenav-menu-heading">System</div>
      <a class="nav-link collapsed" [routerLink]="['/complains']" routerLinkActive="router-link-active"
        aria-expanded="false" aria-controls="collapseLayouts" *ngIf="
					(user.isAdmin == 1 && user?.designation == 'PR') ||
					user?.designation == 'SuperAdmin' ||
					user?.type == 'Customer'
				">
        <div class="sb-nav-link-icon"><i class="fa fa-ticket-alt"></i></div>
        Complains
      </a>

      <a class="nav-link collapsed" [routerLink]="['/itHelpdesk']" routerLinkActive="router-link-active"
        aria-expanded="false" aria-controls="collapseLayouts" *ngIf="
					(user?.isAdmin == 1 && user?.designation == 'IT') ||
					user?.designation == 'SuperAdmin'
				">
        <div class="sb-nav-link-icon"><i class="fa fa-ticket-alt"></i></div>
        IT Helpdesk
      </a>

      <a class="nav-link collapsed" [routerLink]="['/myItRequest']" routerLinkActive="router-link-active"
        aria-expanded="false" aria-controls="collapseLayouts" *ngIf="user.isAdmin == 0 && user.type == 'Employee'">
        <div class="sb-nav-link-icon"><i class="fa fa-ticket-alt"></i></div>
        My IT Requests
      </a>

      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="false"
        aria-controls="collapsePages" *ngIf="
					(user?.isAdmin == 1 && user?.designation == 'IT') ||
					user?.designation == 'SuperAdmin'
				">
        <div class="sb-nav-link-icon"><i class="fa fa-briefcase"></i></div>
        Employees
        <div class="sb-sidenav-collapse-arrow">
          <i class="fas fa-angle-down"></i>
        </div>
      </a>
      <div class="collapse" id="collapsePages" data-parent="#accordionSidenav">
        <nav class="sb-sidenav-menu-nested nav accordion" id="accordionSidenavPages">
          <a class="nav-link" [routerLink]="['/employees']">View Employees</a>
          <a class="nav-link" [routerLink]="['/add-employee']" *ngIf="user.isAdmin == 1">Add Employee</a>
        </nav>
      </div>
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseFlows" aria-expanded="false"
        aria-controls="collapseFlows" *ngIf="
					(user.isAdmin == 1 && user?.designation == 'PR') ||
					(user.isAdmin == 1 && user?.designation == 'SuperAdmin')
				">
        <div class="sb-nav-link-icon"><i class="fa fa-users"></i></div>
        Customers
        <div class="sb-sidenav-collapse-arrow">
          <i class="fas fa-angle-down"></i>
        </div>
      </a>
      <div class="collapse" id="collapseFlows" data-parent="#accordionSidenav">
        <nav class="sb-sidenav-menu-nested nav accordion" id="accordionSidenavPages">
          <a class="nav-link" [routerLink]="['/customers']">View Customers</a>
          <a class="nav-link" [routerLink]="['/add-customer']" routerLinkActive="router-link-active" *ngIf="
							(user.isAdmin == 1 && user?.designation == 'PR') ||
							(user.isAdmin == 1 && user?.designation == 'SuperAdmin')
						">Add Customer</a>
        </nav>
      </div>

      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseDepts" aria-expanded="false"
        aria-controls="collapseDepts" *ngIf="user.isAdmin == 1">
        <div class="sb-nav-link-icon"><i class="fa fa-code-branch"></i></div>
        Departments
        <div class="sb-sidenav-collapse-arrow">
          <i class="fas fa-angle-down"></i>
        </div>
      </a>

      <div class="collapse" id="collapseDepts" data-parent="#accordionSidenav">
        <nav class="sb-sidenav-menu-nested nav accordion" id="accordionSidenavPages">
          <a class="nav-link" [routerLink]="['/departments']">View Departments</a>
        </nav>
      </div>

      <a class="nav-link" [routerLink]="['/staff-survey']" routerLinkActive="router-link-active"
        *ngIf="user?.type == 'Employee' || user?.designation == 'SuperAdmin'">
        <div class="sb-nav-link-icon"><i class="fa fa-tasks"></i></div>
        Staff Surveys
      </a>
      <a class="nav-link" [routerLink]="['/customer-survey']" routerLinkActive="router-link-active"
        *ngIf="user?.type == 'Customer'">
        <div class="sb-nav-link-icon"><i class="fa fa-tasks"></i></div>
        Surveys
      </a>

      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSurvey" aria-expanded="false"
        aria-controls="collapseSurvey" *ngIf="user.isAdmin == 1">
        <div class="sb-nav-link-icon"><i class="fa fa-tasks"></i></div>
        Surveys
        <div class="sb-sidenav-collapse-arrow">
          <i class="fas fa-angle-down"></i>
        </div>
      </a>
      <div class="collapse" id="collapseSurvey" data-parent="#accordionSidenav">
        <nav class="sb-sidenav-menu-nested nav accordion" id="accordionSidenavPages">
          <a class="nav-link" [routerLink]="['/surveys']">View Surveys</a>
          <a class="nav-link" [routerLink]="['/add-survey']" routerLinkActive="router-link-active"
            *ngIf="user.isAdmin == 1">Add Survey</a>
        </nav>
      </div>
      <a class="nav-link" [routerLink]="['/internal-faq']" routerLinkActive="router-link-active"
        *ngIf="user?.type == 'Employee' && user.isAdmin == 0">
        <div class="sb-nav-link-icon"><i class="fa fa-tasks"></i></div>
        FAQs
      </a>

      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseFaq" aria-expanded="false"
        aria-controls="collapseSurvey" *ngIf="user.isAdmin == 1">
        <div class="sb-nav-link-icon"><i class="fa fa-question"></i></div>
        FAQs
        <div class="sb-sidenav-collapse-arrow">
          <i class="fas fa-angle-down"></i>
        </div>
      </a>
      <div class="collapse" id="collapseFaq" data-parent="#accordionSidenav">
        <nav class="sb-sidenav-menu-nested nav accordion" id="accordionSidenavPages">
          <a class="nav-link" [routerLink]="['/faqs']">View FAQ</a>
          <a class="nav-link" [routerLink]="['/add-faq']" routerLinkActive="router-link-active"
            *ngIf="user.isAdmin == 1">Add Faq</a>
        </nav>
      </div>

      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReports" aria-expanded="false"
        aria-controls="collapseReports" *ngIf="user.isAdmin == 1">
        <div class="sb-nav-link-icon"><i class="fa fa-bug"></i></div>
        Reports
        <div class="sb-sidenav-collapse-arrow">
          <i class="fas fa-angle-down"></i>
        </div>
      </a>
      <div class="collapse" id="collapseReports" data-parent="#accordionSidenav">
        <nav class="sb-sidenav-menu-nested nav accordion" id="accordionSidenavPages">
          <a *ngIf="
							(user.isAdmin == 1 && user?.designation == 'PR') ||
							(user.isAdmin == 1 && user?.designation == 'SuperAdmin')
						" class="nav-link" [routerLink]="['/complains-report']">Tickets Report</a>
          <a *ngIf="
							(user?.isAdmin == 1 && user?.designation == 'IT') ||
							user?.designation == 'SuperAdmin'
						" class="nav-link" [routerLink]="['/itHelpdeskReport']">Internal Report</a>
          <a *ngIf="
							(user?.isAdmin == 1 && user?.designation == 'PR') ||
							user?.designation == 'SuperAdmin'
						" class="nav-link" [routerLink]="['/customer-report']">Customers Report</a>
        </nav>
      </div>

      <div class="sb-sidenav-menu-heading" *ngIf="user.isAdmin == 1">
        Settings
      </div>
      <a class="nav-link" [routerLink]="['/settings']" *ngIf="user.isAdmin == 1">
        <div class="sb-nav-link-icon"><i class="fa fa-cogs"></i></div>
        Settings
      </a>

      <div *ngIf="!user ||!user?.designation">
        <div class="sb-sidenav-menu-heading">Anonymous ticket</div>
      <a class="nav-link" [routerLink]="['/anon-ticket']">
        <div class="sb-nav-link-icon"><i class="fa fa-ticket"></i></div>
        Anonymous ticket
      </a>
      </div>

      <div class="sb-sidenav-menu-heading">Corruption</div>
      <a class="nav-link" [routerLink]="['/corruption']">
        <div class="sb-nav-link-icon"><i class="fa fa-ticket"></i></div>
        Report Corruption
      </a>

      <div class="sb-sidenav-menu-heading">Public FAQ</div>
      <a class="nav-link" [routerLink]="['/public-faq']">
        <div class="sb-nav-link-icon"><i class="fa fa-question"></i></div>
        Public FAQ
      </a>

      <div class="sb-sidenav-menu-heading" *ngIf="user.isAdmin == 1">
        System Logs
      </div>
      <a class="nav-link" [routerLink]="['/log']" *ngIf="user.isAdmin == 1">
        <div class="sb-nav-link-icon"><i class="fa fa-history"></i></div>
        Logs
      </a>
    </div>
  </div>
  <div class="sb-sidenav-footer">
    <div>
      <div class="small">Powered by:</div>
      ABNO Softwares Intl
    </div>
  </div>
</nav>
