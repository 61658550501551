import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { IUserPrivilege } from "../common/interfaces/UserPrivilege";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.css"],
})
export class SidenavComponent implements OnInit {
  privileges: IUserPrivilege[] = [];
  user: any;
  isLogged: boolean;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;
      //console.log(this.user);
      if(data){
        this.isLogged = true;
      }
    });
  }
}
