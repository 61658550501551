import { Component, OnInit } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { AuthService } from "../services/auth.service";
import { SurveyService } from "../services/survey.service";
import { ISurveyPreview } from "../survey/interfaces/SurveyPreview";
import{IDepartment} from 'src/app/department/interfaces/Department';

@Component({
  selector: "app-staff-survey",
  templateUrl: "./staff-survey.component.html",
  styleUrls: ["./staff-survey.component.css"],
})
export class StaffSurveyComponent implements OnInit {
  surveys: ISurveyPreview[] = [];

  showLoader: boolean;
  activeId: string;
  status: string;
  departments: IDepartment[] = [];
  

  constructor(
    private surveyService: SurveyService,
    private authService: AuthService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getSurveys();
  }

  
  getSurveys() {
    this.showLoader = true;
    this.surveyService.getSurveys().subscribe(
      (data) => {
        if (data.success) {
          this.surveys = data.data;
          this.surveys = this.surveys.filter(
            (x) => x.survey.type == "Internal" 
          );
        }
        this.showLoader = false;
      },
      (error) => console.log(<any>error)
    );
  }

  setActiveId(id: string) {
    this.activeId = id;
  }
}
