import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from '../common/api-end-points.service';
import { ICounty } from '../common/interfaces/County';
import { COUNTYS } from '../common/interfaces/CountyData';
import { IOwnerType } from '../common/interfaces/OwnerType';
import { ISubCounty } from '../common/interfaces/SubCounty';
import { ICustomer } from '../customer/interfaces/Customer';
import { IEmployee } from '../employee/interfaces/Employee';
import { CustomerService } from '../services/customer.service';
import { EmployeeService } from '../services/employee.service';
import { OtherService } from '../services/other.service';
import { TicketService } from '../services/ticket.service';
import { ITicket } from '../ticket/interfaces/Ticket';

@Component({
  selector: 'app-corruption',
  templateUrl: './corruption.component.html',
  styleUrls: ['./corruption.component.css']
})
export class CorruptionComponent implements OnInit {

  emptyId: string;
  customer: ICustomer;
  employee:IEmployee;
  countydata: ICounty[];
  subcountySelection: ISubCounty[] = [];
  customerTypes: IOwnerType[] = [];
  showLoader: boolean;

  types: IOwnerType[] = [];

  ticket: ITicket;
  who: string;
  when: string;
  where: string;
  constructor(
    private endPoints: ApiEndPointsService,
    private customerService: CustomerService,
    public employeeService: EmployeeService,
    private ticketService: TicketService,
    private notifier: NotifierService,
    private otherService: OtherService,
    private router: Router
  ) {
    this.emptyId = this.endPoints.constants.emptyId;
    this.customer = {
      id: this.emptyId,
      createdAt: new Date(),
      updatedAt: new Date(),
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phone2: "",
      county: "",
      address: "",
      subCounty: "",
      password: "1234cust",
      identity: "",
      typeId: this.emptyId,
      arn: "",
    };
    this.employee={
      id: this.emptyId,
      createdAt: new Date(),
      updatedAt: new Date(),
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      departmentId:"",
      creator:"",
      address: "",
      admin:  0,
      escalated: false,
      password: "1234cust",
      identity: "",



    }
    this.ticket = {
      id: this.emptyId,
      title: "",
      description: "",
      closed: false,
      completionPercentage: 0,
      creator: this.emptyId,
      customerId: this.emptyId,
      employeeId:this.emptyId,
      typeId: this.emptyId,
      createdAt: new Date(),
      updatedAt: new Date(),
      status: "",
      reason: "",
      source: "Customer",
    };
  }

  ngOnInit(): void {
    this.countydata = COUNTYS;
    this.getCustomerTypes();
    this.getTicketTypes();
    this.getAnonymousUser();
  }

  getCustomerTypes() {
    this.otherService.getTypes("Customer").subscribe((data) => {
      if (data.success) {
        this.customerTypes = data.data;
      }
    });
  }

  getTicketTypes() {
    let type = "PR"
    this.otherService.getTypes(type).subscribe((data) => {
      if (data.success) {
        this.types = data.data;

      }
    });
  }

  getAnonymousUser() {
    this.showLoader = true;
    this.customerService.getAnonymousUser().subscribe((data) => {
      if (data.success) {
        //this.notifier.notify("success", data.message);
        this.customer = data.data;
        this.showLoader = false;
        return;
      }
      this.showLoader = false;
      //this.notifier.notify("error", data.message);
    });
  }

  saveTicket() {
    if (!this.ticket.title) {
      this.notifier.notify("error", "Provide a title for your ticket");
      return;
    }
    if (!this.ticket.description) {
      this.notifier.notify("error", "Provide a description for your ticket");
      return;
    }
    if (!this.ticket.typeId || this.ticket.typeId == this.emptyId) {
      this.notifier.notify("error", "Select a category for your ticket");
      return;
    }
    var tempDesc = 'Person(s) involved :'+ this.who +' Where :'+this.where+" When :"+this.when+" Details of how: "+this.ticket.description;
    this.ticket.description = tempDesc;
    this.showLoader = true;
    this.ticket.customerId = this.customer.id;
    this.ticket.employeeId=this.employee.id;
    this.ticketService.addTicket(this.ticket).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.router.navigate([""]);
        this.showLoader = false;
        //window.location.reload();
        return;
      }
      this.showLoader = false;
      this.notifier.notify("error", data.message);
    });
  }
  populateSubCounties(county: string) {
    this.subcountySelection = this.countydata.find(
      (x) => x.name == county
    ).subcounties;
  }

}
