import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  email: string;
  constructor(private authService: AuthService, private notifier: NotifierService) { }

  ngOnInit() {
  }

  resetPassword(){
    this.authService.passwordReset(this.email).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
        return;
      }
      this.notifier.notify('error', data.message);
    });
  }
}
