<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
    <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-fluid">
            <div class="sb-page-header-content py-5">
                <h1 class="sb-page-header-title">
                    <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
                    <span>My IT Request</span>
                </h1>

            </div>
        </div>
    </div>
    <div class="container-fluid mt-n10">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-header">
                        <div *ngIf="(user?.type == 'Employee') || (user?.isAdmin == 1)">

                            <a class="btn btn-outline-primary btn-sm" *ngIf="user?.type == 'Employee' || user.isAdmin == 1"
                                [routerLink]="['/add-ticket', emptyId]">
                                <i class="fa fa-plus"></i> Internal IT helpdesk
                            </a>
                        </div>

                    </div>
                    <div class="card-body">
                        <div class="sb-datatable table-responsive">
                            <table class="table table-bordered table-hover" datatable width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Date Received</th>
                                        <th>Complaint Issue</th>
                                        <th>Date Acknowledged</th>
                                        <th>Complaint Status</th>
                                        <th>Action Taken</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let model of tickets;index as i">
                                        <td>{{i + 1}}</td>
                                        <td>{{model.ticket.createdAt | date:'medium'}}</td>
                                        <td>{{model.ticket.description}}</td>
                                        <td>
                                            <div *ngIf="model.acknowledgedAt">
                                            {{model.acknowledgedAt | date:'medium'}}
                                        </div>
                                        <div *ngIf="!model.acknowledgedAt">
                                            <p>N/A</p>

                                        </div></td>

                                        <!-- <td>
                                            <span class="badge badge-success"
                                                *ngIf="ticket?.status == 'Due' || !ticket?.status"> <i
                                                    class="fa fa-history"></i> Due</span>
                                            <span class="badge badge-warning" *ngIf="ticket?.status == 'Overdue'"> <i
                                                    class="fa fa-clock"></i>
                                                Overdue</span>
                                            <span class="badge badge-danger" *ngIf="ticket?.status == 'Critical'"> <i
                                                    class="fa fa-stopwatch"></i> Critical</span>
                                        </td> -->
                                        <td>
                                            <div *ngIf="model.status">
                                            {{model.status}}
                                          </div>
                                          <div *ngIf="!model.status">
                                            <p>N/A</p>

                                          </div>
                                          </td>
                                          <td>
                                            <a class="badge badge-primary "
                                                [routerLink]="['/ticket-details',model.ticket.id]"
                                                *ngIf="user.isAdmin == 1 || user?.type =='Employee'">
                                                <i class="fa fa-eye"></i> View
                                            </a> |
                                            <a class="badge badge-warning"
                                                [routerLink]="['/edit-ticket', model.ticket.id]"
                                                *ngIf="!model.status">
                                                <i class="fa fa-edit"></i>
                                            </a>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</div>
</div>
</div>

