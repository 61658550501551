import { Component, OnInit } from "@angular/core";
import { IEmployee } from "./interfaces/Employee";
import { EmployeeService } from "../services/employee.service";
import { IUserPrivilege } from "../common/interfaces/UserPrivilege";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-employee",
  templateUrl: "./employee.component.html",
  styleUrls: ["./employee.component.css"],
})
export class EmployeeComponent implements OnInit {
  employees: IEmployee[] = [];
  canraise: boolean;
  canviewdetails: boolean;
  canedit: boolean;
  user: any;
  showLoader: boolean;
  constructor(
    private employeeService: EmployeeService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;

    });
    this.getEmployees();
  }

  getEmployees() {
    this.showLoader = true;
    this.employeeService.getEmployees().subscribe((data) => {
      if (data.success) {
        this.employees = data.data;
        console.log(this.employees)
      }
      this.showLoader = false;
    });
  }

}
