<notifier-container></notifier-container>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<div class="row" *ngIf="surveyDetail && surveyDetail.survey.type == 'Internal'">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header" style="width: 100% !important;">
        <h6>Take Survey</h6> <br>
      </div>
      <div class="card-content">
        <div class="alert alert-info" *ngIf="hasConducted">NB: You have already conducted this survey</div>


        <ul class="list-group list-group-flush" style="list-style: none;">
          <li class="list-group-item"> Title : {{surveyDetail?.survey?.title}}</li>
          <li class="list-group-item"> Description : {{surveyDetail?.survey?.description}}</li>
          <li class="list-group-item"> Department : {{surveyDetail?.survey?.department }}</li>
          <li class="list-group-item"> Total Questions : <div class="badge badge-info">
              {{surveyDetail?.questions.length}}</div>
          </li>
          <li class="list-group-item"> Created On : {{surveyDetail?.survey?.createdAt | date:'medium'}}</li>
        </ul>

        <ul *ngFor="let questItem of surveyDetail?.questions;let i = index" style="list-style-type:none;">
          <h6>{{i+1}} . {{questItem?.surveyQuestion.question}}</h6>
          <li *ngIf="questItem.surveyQuestion.type ==='OpenQuiz'">
            <div class="custom-control md-textarea">
              <textarea class="form-control sb-form-control-solid" [(ngModel)]="openResponse"
               (keyup.enter)="addResponse(questItem.surveyQuestion.id, openResponse, $event)"
                (blur)="addResponse(questItem.surveyQuestion.id,openResponse, $event)"
               id="openResponse" placeholder="Enter the question" rows="2"></textarea>
            </div>
          </li>

          <li>
            <ul *ngFor="let optionItem of questItem.questionOptions" style="list-style-type:none;">
              <li *ngIf="questItem.surveyQuestion.type ==='multiple'">
                <div class="custom-control custom-checkbox"><input class="custom-control-input"
                    id="{{optionItem.option.id}}"
                    (change)="addRemoveResponseChoice(optionItem.option.id,questItem.surveyQuestion.id, $event)"
                    type="checkbox">
                  <label class="custom-control-label" for="{{optionItem.option.id}}">{{optionItem.option.option}}
                  </label>
                </div>
              </li>
              <li *ngIf="questItem.surveyQuestion.type ==='single' || questItem.surveyQuestion.type === 'Review'">
                <div class="custom-control custom-radio"><input class="custom-control-input"
                    id="{{optionItem.option.id}}"
                    (change)="addRemoveResponseChoice(optionItem.option.id,questItem.surveyQuestion.id, $event)"
                    type="radio" name="{{questItem.surveyQuestion.id}}">
                  <label class="custom-control-label" for="{{optionItem.option.id}}">{{optionItem.option.option}}
                  </label>
                </div>
              </li>
            </ul>
          </li>
        </ul>
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4" >
            <button class="btn btn-success btn-block" *ngIf="!hasConducted" (click)='submitSurvey()'>Submit</button>
          </div>
          <div class="col-md-4"></div>
        </div><br>

      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
