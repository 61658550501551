<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <notifier-container></notifier-container>
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>Staff Surveys</span>
        </h1>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header"></div>
          <div class="card-body">
            <div class="sb-datatable table-responsive">
              <table class="table table-bordered table-hover" datatable width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Created On</th>
                    <th>Department</th>
                    <th>Questions</th>
                    <th>Responses</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let survey of surveys;index as i">
                    <td>MSS-{{i+1}}</td>
                    <td>{{survey.survey.title }}</td>
                    <td>{{ survey.survey.status }}</td>
                    <td>{{ survey.survey.type }}</td>
                    <td>{{survey.survey.createdAt | date:'medium'}}</td>
                    <td>{{survey.department}}</td>
                    <td>
                      <div class="badge badge-primary badge-pill">{{survey.questionCount }}</div>
                    </td>
                    <td>
                      <div class="badge badge-info badge-pill">{{survey.responseCount}}</div>
                    </td>
                    <td>
                      <a class="badge badge-primary" [routerLink]="['/staff-survey-details', survey.survey.id]">
                        <i class="fa fa-eye"></i> View
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</div>
</div>
</div>
