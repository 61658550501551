import { ICounty } from "./County";

export const COUNTYS: ICounty[] = [
  {
    code: "001",
    name: "Mombasa",
    subcounties: [
      {
        name: "Changamwe"
      },
      {
        name: "Jomvu"
      },
      {
        name: "Kisauni"
      },
      {
        name: "Nyali"
      },
      {
        name: "Likoni"
      },
      {
        name: "Mvita"
      }
    ]
  },
  {
    code: "002",
    name: "Kwale",
    subcounties: [
      {
        name: "Msambweni"
      },
      {
        name: "Matuga"
      },
      {
        name: "Kinango"
      },
      {
        name: "Lunga"
      }
    ]
  },
  {
    code: "003",
    name: "Kilifi",
    subcounties: [
      {
        name: "Kilifi"
      },
      {
        name: "Ganze"
      },
      {
        name: "Malindi"
      },
      {
        name: "Magarini"
      },
      {
        name: "Rabai"
      },
      {
        name: "Kaloleni"
      }
    ]
  },
  {
    code: "004",
    name: "Tana River",
    subcounties: [
      {
        name: "Bura"
      },
      {
        name: "Galore"
      },
      {
        name: "Garsen"
      }
    ]
  },
  {
    code: "005",
    name: "Lamu",
    subcounties: [
      {
        name: "Lamu West"
      },
      {
        name: "Lamu East"
      }
    ]
  },
  {
    code: "006",
    name: "Taita–Taveta",
    subcounties: [
      {
        name: "Taveta"
      },
      {
        name: "Wundanyi"
      },
      {
        name: "Mwatate"
      },
      {
        name: "Voi"
      }
    ]
  },
  {
    code: "007",
    name: "Garissa",
    subcounties: [
      {
        name: "Garisa Township"
      },
      {
        name: "Fafi"
      },
      {
        name: "Dadaab"
      },
      {
        name: "Lagdera"
      },
      {
        name: "Balambala"
      },
      {
        name: "Ijara"
      }
    ]
  },
  {
    code: "008",
    name: "Wajir",
    subcounties: [
      {
        name: "Wajir East"
      },
      {
        name: "Wajir West"
      },
      {
        name: "Wajir North"
      },
      {
        name: "Wajir South"
      },
      {
        name: "Tarbaj"
      },
      {
        name: "Eldas"
      }
    ]
  },
  {
    code: "009",
    name: "Mandera",
    subcounties: [
      {
        name: "Mandera South"
      },
      {
        name: "Mandera West"
      },
      {
        name: "Mandera East"
      },
      {
        name: "Mandera North"
      },
      {
        name: "Banisa"
      },
      {
        name: "Lafey"
      }
    ]
  },
  {
    code: "010",
    name: "Marsabit",
    subcounties: [
      {
        name: "Moyale"
      },
      {
        name: "North Horr"
      },
      {
        name: "Saku"
      },
      {
        name: "Laisamis"
      }
    ]
  },
  {
    code: "011",
    name: "Isiolo",
    subcounties: [
      {
        name: "Isiolo North"
      },
      {
        name: "Isiolo South"
      }
    ]
  },
  {
    code: "012",
    name: "Meru",
    subcounties: [
      {
        name: "Igembe South"
      },
      {
        name: "Tigania West"
      },
      {
        name: "Tigania East"
      },
      {
        name: "Igembe North"
      },
      {
        name: "Imenti North"
      },
      {
        name: "Imenti Central"
      },
      {
        name: "Igembe Central"
      },
      {
        name: "Imenti South"
      },
      {
        name: "Buuri"
      }
    ]
  },
  {
    code: "013",
    name: "Tharaka-Nithi",
    subcounties: [
      {
        name: "Maara"
      },
      {
        name: "Chuka/Igambang’ombe"
      },
      {
        name: "Tharaka"
      }
    ]
  },
  {
    code: "014",
    name: "Embu",
    subcounties: [
      {
        name: "Embu West"
      },
      {
        name: "Embu North"
      },
      {
        name: "Embu East"
      },
      {
        name: "Mbeere South"
      },
      {
        name: "Mbeere North"
      }
    ]
  },
  {
    code: "015",
    name: "Kitui",
    subcounties: [
      {
        name: "Kitui Central"
      },
      {
        name: "Lower Yatta"
      },
      {
        name: "Kitui West"
      },
      {
        name: "Kasasi"
      },
      {
        name: "Nzambani"
      },
      {
        name: "Mutitu"
      },
      {
        name: "Mutomo"
      },
      {
        name: "Ikutha"
      },
      {
        name: "Katulani"
      },
      {
        name: "Matinyani"
      },
      {
        name: "Mwingi Central"
      },
      {
        name: "Mwingi East"
      },
      {
        name: "Tseikuru"
      },
      {
        name: "Muumoni"
      },
      {
        name: "Kyuso"
      },
      {
        name: "Migwani"
      }
    ]
  },
  {
    code: "016",
    name: "Machakos",
    subcounties: [
      {
        name: "Masinga"
      },
      {
        name: "Yatta"
      },
      {
        name: "Kangundo"
      },
      {
        name: "Matungulu"
      },
      {
        name: "Kathiani"
      },
      {
        name: "Mavoko"
      },
      {
        name: "Machakos Town"
      },
      {
        name: "Mwala"
      }
    ]
  },
  {
    code: "017",
    name: "Makueni",
    subcounties: [
      {
        name: "Makueni"
      },
      {
        name: "Kaiti"
      },
      {
        name: "Mbooni"
      },
      {
        name: "Kilome"
      },
      {
        name: "Kibwezi East"
      },
      {
        name: "Kibwezi West"
      }
    ]
  },
  {
    code: "018",
    name: "Nyandarua",
    subcounties: [
      {
        name: "Kinangop"
      },
      {
        name: "Kipipiri"
      },
      {
        name: "Ol Joro Orok"
      },
      {
        name: "Ndaragwa"
      },
      {
        name: "Ol Kalou"
      }
    ]
  },
  {
    code: "019",
    name: "Nyeri",
    subcounties: [
      {
        name: "Mathira East"
      },
      {
        name: "Mathira West"
      },
      {
        name: "Kieni East"
      },
      {
        name: "Kieni West"
      },
      {
        name: "Nyeri Town"
      },
      {
        name: "Mukurweini"
      },
      {
        name: "Othaya"
      },
      {
        name: "Tetu"
      }
    ]
  },
  {
    code: "020",
    name: "Kirinyaga",
    subcounties: [
      {
        name: "Mwea"
      },
      {
        name: "Gichugu"
      },
      {
        name: "Ndia"
      },
      {
        name: "Kirinyaga Central"
      }
    ]
  },
  {
    code: "021",
    name: "Murang'a",
    subcounties: [
      {
        name: "Gatanga"
      },
      {
        name: "Kandara"
      },
      {
        name: "Kangema"
      },
      {
        name: "Kigumo"
      },
      {
        name: "Kiharu"
      },
      {
        name: "Maragua "
      },
      {
        name: "Mathioya "
      }
    ]
  },
  {
    code: "022",
    name: "Kiambu",
    subcounties: [
      {
        name: "Limuru"
      },
      {
        name: "Kikuyu"
      },
      {
        name: "Kabete Lari"
      },
      {
        name: "Gatundu South"
      },
      {
        name: "Gatundu North"
      },
      {
        name: "Githunguri "
      },
      {
        name: "Kiambu"
      },
      {
        name: "Kiambaa"
      },
      {
        name: "Ruiru "
      },
      {
        name: "Juja"
      },
      {
        name: "Thika Town "
      }
    ]
  },
  {
    code: "023",
    name: "Turkana",
    subcounties: [
      {
        name: "Turkana North"
      },
      {
        name: "Turkana Central"
      },
      {
        name: "Turkana South"
      }
    ]
  },
  {
    code: "024",
    name: "West Pokot",
    subcounties: [
      {
        name: "Kacheliba"
      },
      {
        name: "Kapenguria"
      },
      {
        name: "Sigor"
      },
      {
        name: "Pokot South"
      }
    ]
  },
  {
    code: "025",
    name: "Samburu",
    subcounties: [
      {
        name: "Samburu East"
      },
      {
        name: "Samburu Central"
      },
      {
        name: "Samburu North"
      }
    ]
  },
  {
    code: "027",
    name: "Trans-Nzoia",
    subcounties: [
      {
        name: "Cherangani"
      },
      {
        name: "Kwanza"
      },
      {
        name: "Saboti"
      },
      {
        name: "Endebess"
      },
      {
        name: "Kaminini"
      }
    ]
  },
  {
    code: "026",
    name: "Uasin Gishu",
    subcounties: [
      {
        name: "Soy"
      },
      {
        name: "Turbo"
      },
      {
        name: "Moiben"
      },
      {
        name: "Ainabkoi"
      },
      {
        name: "Kapseret"
      },
      {
        name: "Kesses"
      }
    ]
  },
  {
    code: "028",
    name: "Elgeyo-Marakwet",
    subcounties: [
      {
        name: "Marakwet East"
      },
      {
        name: "Marakwet West"
      },
      {
        name: "Keiyo North"
      },
      {
        name: "Keiyo South"
      }
    ]
  },
  {
    code: "029",
    name: "Nandi",
    subcounties: [
      {
        name: "Mosop"
      },
      {
        name: "Emgwen"
      },
      {
        name: "Aldai"
      },
      {
        name: "Tinderet"
      },
      {
        name: "Nandi"
      },
      {
        name: "Chesumei"
      }
    ]
  },
  {
    code: "030",
    name: "Baringo",
    subcounties: [
      {
        name: "Baringo Central"
      },
      {
        name: "Baringo South"
      },
      {
        name: "Tiaty"
      },
      {
        name: "Baringo North"
      },
      {
        name: "Eldama Ravine"
      },
      {
        name: "Mogotio"
      }
    ]
  },
  {
    code: "031",
    name: "Laikipia",
    subcounties: [
      {
        name: "Laikipia Central"
      },
      {
        name: "Laikipia North"
      },
      {
        name: "Laikipia East"
      },
      {
        name: "Laikipia West"
      },
      {
        name: "Nyahururu"
      }
    ]
  },
  {
    code: "032",
    name: "Nakuru",
    subcounties: [
      {
        name: "Naivasha"
      },
      {
        name: "Nakuru Town West"
      },
      {
        name: "Nakuru Town East"
      },
      {
        name: "Kuresoi South"
      },
      {
        name: "Kuresoi North"
      },
      {
        name: "Molo"
      },
      {
        name: "Rongai"
      },
      {
        name: "Subukia"
      },
      {
        name: "Njoro"
      },
      {
        name: "Gilgil"
      },
      {
        name: "Bahati"
      }
    ]
  },
  {
    code: "033",
    name: "Narok",
    subcounties: [
      {
        name: "Narok North"
      },
      {
        name: "Narok South"
      },
      {
        name: "Narok East"
      },
      {
        name: "Narok West"
      },
      {
        name: "Emurua Dikirr"
      },
      {
        name: "Kirgoris"
      }
    ]
  },
  {
    code: "034",
    name: "Kajiado",
    subcounties: [
      {
        name: "Kajiado North"
      },
      {
        name: "Loitoktok"
      },
      {
        name: "Kajiado Central"
      },
      {
        name: "Isinya"
      },
      {
        name: "Mashuuru"
      }
    ]
  },
  {
    code: "035",
    name: "Kericho",
    subcounties: [
      {
        name: "Bureti"
      },
      {
        name: "Belgut"
      },
      {
        name: "Ainamoi"
      },
      {
        name: "Soin/Sigowet"
      },
      {
        name: "Kipkelion East"
      },
      {
        name: "Kipkelion West"
      }
    ]
  },
  {
    code: "036",
    name: "Bomet",
    subcounties: [
      {
        name: "Bomet Central"
      },
      {
        name: "Bomet East"
      },
      {
        name: "Chepalungu"
      },
      {
        name: "Sotik"
      },
      {
        name: "Konoin "
      }
    ]
  },
  {
    code: "037",
    name: "Kakamega",
    subcounties: [
      {
        name: "Butere"
      },
      {
        name: "Khwisero"
      },
      {
        name: "Lurambi"
      },
      {
        name: "Navakholo"
      },
      {
        name: "Shinyalu "
      },
      {
        name: "Kakamega North "
      },
      {
        name: "Ikolomani "
      },
      {
        name: "Likuyani "
      },
      {
        name: "Lugari "
      },
      {
        name: "Matungu "
      },
      {
        name: "Mumias west "
      },
      {
        name: "Mumias East "
      }
    ]
  },
  {
    code: "038",
    name: "Vihiga",
    subcounties: [
      {
        name: "Emuhaya"
      },
      {
        name: "Sabatia"
      },
      {
        name: "Luanda"
      },
      {
        name: "Hamisi"
      },
      {
        name: "Vihiga "
      }
    ]
  },
  {
    code: "039",
    name: "Bungoma",
    subcounties: [
      {
        name: "Kimilili"
      },
      {
        name: "Tongaren"
      },
      {
        name: "Webuye West"
      },
      {
        name: "Webuye East"
      },
      {
        name: "Sirisia"
      },
      {
        name: "Kabuchai"
      },
      {
        name: "Kandunyi"
      },
      {
        name: "Bumula"
      },
      {
        name: "Mt. Elgon"
      }
    ]
  },
  {
    code: "040",
    name: "Busia",
    subcounties: [
      {
        name: "Nambale"
      },
      {
        name: "Butula"
      },
      {
        name: "Funyula"
      },
      {
        name: "Budalangi"
      },
      {
        name: "Matayos "
      },
      {
        name: "Teso North "
      },
      {
        name: "Teso South "
      }
    ]
  },
  {
    code: "041",
    name: "Siaya",
    subcounties: [
      {
        name: "Alego"
      },
      {
        name: "Bondo"
      },
      {
        name: "Gem"
      },
      {
        name: "Rarieda"
      },
      {
        name: "Ugenya"
      },
      {
        name: "Ugunja"
      }
    ]
  },
  {
    code: "042",
    name: "Kisumu",
    subcounties: [
      {
        name: "Kisumu East"
      },
      {
        name: "Kisumu West"
      },
      {
        name: "Kisumu Central"
      },
      {
        name: "Seme"
      },
      {
        name: "Nyando"
      },
      {
        name: "Muhoroni"
      },
      {
        name: "Nyakach"
      }
    ]
  },
  {
    code: "043",
    name: "Homa Bay",
    subcounties: [
      {
        name: "Kasipul"
      },
      {
        name: "Kabondo Kasipul"
      },
      {
        name: "Karachuonyo"
      },
      {
        name: "Rangwe"
      },
      {
        name: "Homa Bay Town"
      },
      {
        name: "Ndhiwa"
      },
      {
        name: "Nyakach"
      },
      {
        name: "Suba South"
      },
      {
        name: "Suba North"
      }
    ]
  },
  {
    code: "044",
    name: "Migori",
    subcounties: [
      {
        name: "Migori"
      },
      {
        name: "Nyatike"
      },
      {
        name: "Rongo"
      },
      {
        name: "Uriri"
      }
    ]
  },
  {
    code: "045",
    name: "Kisii",
    subcounties: [
      {
        name: "Bobasi"
      },
      {
        name: "Bonchari"
      },
      {
        name: "Bomachoge Borabu"
      },
      {
        name: "Bomachoge Chache"
      },
      {
        name: "Kitutu Chache North"
      },
      {
        name: "Kitutu Chache South"
      },
      {
        name: "Nyaribari Chache"
      },
      {
        name: "Nyaribali Masaba"
      },
      {
        name: "South Mugirango"
      }
    ]
  },
  {
    code: "046",
    name: "Nyamira",
    subcounties: [
      {
        name: "Manga"
      },
      {
        name: "Nyamira South"
      },
      {
        name: "Nyamira North"
      },
      {
        name: "Borabu"
      },
      {
        name: "Masaba North"
      }
    ]
  },
  {
    code: "047",
    name: "Nairobi",
    subcounties: [
      {
        name: "Westlands"
      },
      {
        name: "Dagoretti North"
      },
      {
        name: "Dagoretti South"
      },
      {
        name: "Langata"
      },
      {
        name: "Kibra"
      },
      {
        name: "Roysambu"
      },
      {
        name: "Kasarani"
      },
      {
        name: "Ruaraka"
      },
      {
        name: "Embakasi South"
      },
      {
        name: "Embakasi North"
      },
      {
        name: "Embakasi Central"
      },
      {
        name: "Embakasi East"
      },
      {
        name: "Embakasi West"
      },
      {
        name: "Makadara"
      },
      {
        name: "Kamukunji"
      },
      {
        name: "Starehe"
      },
      {
        name: "Mathare"
      }
    ]
  }
];
