<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <notifier-container></notifier-container>
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="droplet"></i></div>
          <span>Profile Information</span>
        </h1>
        <div class="sb-page-header-subtitle">
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="row">
      <div class="col-lg-7">
        <div class="card">
          <div class="card-header">
            Profile Details
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item"> <i class="fa fa-user"></i> Names : {{loginUser?.firstName}}
                    {{loginUser?.lastName}}</li>
                  <li class="list-group-item"> <i class="fa fa-envelope"></i> Email :{{loginUser?.email}}</li>
                  <li class="list-group-item"> <i class="fa fa-phone"></i> Phone : {{loginUser?.phone}}</li>
                  <li class="list-group-item"> <i class="fa fa-clock"></i> Created On :
                    {{loginUser?.createdAt | date:'medium'}}</li>
                </ul>
              </div>
              <div class="col-md-6">
                <div *ngIf="loginUser?.id == userId || loginUser?.admin == 1">
                  <h6>Change Password</h6>
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="form-group"><label class="small blm mb-1" for="password">Password</label>
                        <input class="form-control" id="password" [(ngModel)]="password" name="password" type="password"
                          placeholder="Enter password" /></div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="form-group"><label class="small blm mb-1" for="confirm_password">Confirm Password</label>
                        <input class="form-control" id="confirm_password" [(ngModel)]="confirm_password"
                          name="confirm_password" type="password" placeholder="Re enter your password" /></div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <button class="btn btn-success btn-block" (click)="resetPassword()">Submit</button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div><br /><br />
      </div>
      <!-- <div class="col-lg-5" *ngIf="loginUser?.type == 'Employee'">
        <div class="card">
          <div class="card-header">
            Privileges
          </div>
          <div class="card-body">
            <div id="accordion">
              <div class="card" *ngFor="let item of privileges;index as i">
                <div class="card-header" id="{{item.id}}">
                  <h5 class="mb-0">
                    <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse'+i"
                      aria-expanded="true" [attr.aria-controls]="'#collapse'+i">
                      {{item.name}}
                    </button>
                  </h5>
                </div>

                <div id="{{'collapse'+i}}" class="collapse" [attr.aria-labelledby]="item.id" data-parent="#accordion">
                  <div class="card-body">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" *ngFor="let privItem of item.items">
                        <div class="custom-control custom-checkbox" *ngIf="checkIfAssigned(privItem.name)">
                          <input class="custom-control-input" id="{{privItem.id}}" type="checkbox" checked disabled>
                          <label class="custom-control-label" for="{{privItem.id}}">
                            {{privItem.name}}
                          </label>
                        </div>

                        <div class="custom-control custom-checkbox" *ngIf="!checkIfAssigned(privItem.name)">
                          <input class="custom-control-input" id="{{privItem.id}}" type="checkbox" disabled>
                          <label class="custom-control-label" for="{{privItem.id}}">
                            {{privItem.name}}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</main>
</div>
</div>
</div>
