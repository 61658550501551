import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiEndPointsService {
  constructor() {}
  constants = {
    emptyId: "00000000-0000-0000-0000-000000000000",
  };
  login = {
    controller: "api/authentication/",
    signin: "api/authentication/login",
    resetpassword: "api/authentication/resetPassword/",
    passwordreset: "api/authentication/passwordReset/",
    makeadmin: "api/authentication/makeAdmin/",
    removeadmin: "api/authentication/removeAdmin/",
    validatetoken: "api/authentication/validateToken/",
  };

  privileges = {
    controller: "api/privileges/",
    getprivileges: "api/privileges/getPrivileges/",
    getuserprivileges: "api/privileges/getUserPrivileges/",
    adduserprivilege: "api/privileges/addPrivilege/",
    removeuserprivilege: "api/privileges/removePrivilege/",
  };

  dashboard = {
    controller: "api/dashboard/",
    getdashboarddata: "api/dashboard/getDashboardData/",
    getdashstats: "api/dashboard/getDashStats/",
    gettottalticketclosed: "api/dashboard/getClosedTicketsPerDay/",
    gettottaltaskclosed: "api/dashboard/getClosedTasksPerDay/",
    gettaskcomp: "api/dashboard/getTaskComparison/",
    getticketcomp: "api/dashboard/getTicketComparison/",
  };

  tickets = {
    controller: "api/tickets/",
    getalltickets: "api/tickets/getAllTickets/",
    getusertickets: "api/tickets/getUserTickets/",
    getticketusers: "api/tickets/getTicketUsers/",
    getticketdetails: "api/tickets/getTicketDetails/",
    changestatus: "api/tickets/changeStatus/",
    addticket: "api/tickets/addTicket/",
    editticket: "api/tickets/editTicket/",
    addticketusers: "api/tickets/addTicketUsers/",
    removeticketuser: "api/tickets/removeTicketUser/",
    openclose: "api/tickets/changeState/",
    sendmessage: "api/tickets/sendMessage/",
    uploadFiles: "api/tickets/UploadFiles/",
    uploadedFiles: "api/tickets/uploadedFiles/",
  };

  meeting = {
    controller: "api/meetings/",
    getallmeetings: "api/meetings/getAllMeetings/",
    getusermeetings: "api/meetings/getUserMeetings/",
    getmeetingusers: "api/meetings/getMeetingUsers/",
    getmeetingdetails: "api/meetings/getMeetingDetails/",
    addmeeting: "api/meetings/addMeeting/",
    editMeeting: "api/meetings/editMeeting/",
    addmeetingusers: "api/meetings/addMeetingUsers/",
    removemeetinguser: "api/meetings/removeMeetingUser/",
    cancelmeeting: "api/meetings/cancelMeeting/",
  };

  department = {
    controller: "api/department/",
    getdepartments: "api/department/getDepartments/",
    getdepartmentemployees: "api/department/getDepartmentEmployees/",
    getdepartment: "api/department/getDepartmentDetails/",
    getdepartmenthead: "api/department/getDepartmentHead/",
    adddepartment: "api/department/addDepartment/",
    editdepartment: "api/department/editDepartment/",
    editteam: "api/department/editTeam/",
    adddepartmenthead: "api/department/addDepartmentHead/",
    getdepartmentteams: "api/department/getDepartmentTeams/",
    getteams: "api/department/getTeams/",
    addteammember: "api/department/addTeamMember/",
    removeteammember: "api/department/removeTeamMember/",
    addteamtype: "api/department/addTeamType/",
    removeteamtype: "api/department/removeTeamType/",
    addteam: "api/department/addTeam/",
  };

  employee = {
    controller: "api/employees/",
    getemployees: "api/employees/getEmployees/",
    getemployeedetails: "api/employees/getEmployeeDetails/",
    addemployee: "api/employees/addEmployee/",
    addemployeebyno: "api/employees/addEmployeeByNo/",
    editemployee: "api/employees/editEmployee/",
  };

  customer = {
    controller: "api/customers/",
    getcustomers: "api/customers/getCustomers/",
    getcustomerdetails: "api/customers/getCustomerDetails/",
    addcustomer: "api/customers/addCustomer/",
    editCustomer: "api/customers/editCustomer/",
    searchcustomer: "api/customers/searchCustomer/",
    getanonymoususer: "api/customers/getAnonymousCustomer",
  };

  survey = {
    controller: "api/survey/",
    getsurveys: "api/survey/getSurveys/",
    getsurveydetail: "api/survey/getSurveyDetail/",
    getactivesurvey: "api/survey/getActiveSurvey/",
    savesurvey: "api/survey/saveSurvey/",
    notifystaff: "api/survey/notifyStaff/",
    savequestion: "api/survey/saveQuestion/",
    saveoption: "api/survey/saveOption/",
    saveresponses: "api/survey/saveResponses/",
    conductedSurvey:"api/survey/conductedSurvey/",
    changestatus: "api/survey/changeStatus/",
  }; 

  setting = {
    controller: "api/settings/",
    getallsmtpsettings: "api/settings/getAllSmtpSettings/",
    smtpsettings: "api/settings/getSmtpSettings/",
    addsmtpsetting: "api/settings/addSmtpSetting/",
    editSmtpSetting: "api/settings/editSmtpSetting/",
    getalltypes: "api/settings/getAllTypes/",
    gettypes: "api/settings/getTypes/",
    addtype: "api/settings/addType/",
    edittype: "api/settings/editType/",
    getallescalations: "api/settings/getAllEscalations/",
    getescalationbyid: "api/settings/getEscalationById/",
    addescalationsetting: "api/settings/addEscalationSetting/",
    editescalationsetting: "api/settings/editEscalationSetting/",
    deleteEscalation: "api/settings/deleteEscalationSetting/",
    deleteSmtpSetting: "api/settings/deleteSmtpSetting/",
    deleteTypeSetting: "api/settings/deleteTypeSetting/",
    addSource: "api/settings/addSource/",
    getsources: "api/settings/getSources/",
    deleteSource: "api/settings/deleteSource/",
    addReview: "api/settings/addReview/",
    getReviewOptions: "api/settings/getReviewOptions/",
  };

  log = {
    controller: "api/logs/",
    getlogs: "api/logs/getLogs/",
    addLog: "api/logs/addLog/",
    getUser: "api/logs/getUser/",
  };

  faq = {
    controller: "api/faqs/",
    getfaqs: "api/faqs/getFaqs/",
    addFaq: "api/faqs/addFaq/",
    getfaqdetails: "api/faqs/getFaqDetails/",
    editfaq: "api/faqs/editFaq/",
    deleteFaq: "api/faqs/deleteFaq/",
  };

  report = {
    controller: "api/reports/",
    gettickets: "api/reports/getTickets/",
    gettasks: "api/reports/getTasks/",
    getmeetings: "api/reports/getMeetings/",
    getcustomers: "api/reports/getCustomers/",
    getvenues: "api/reports/getVenues/",
  };

  public handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
