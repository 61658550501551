<notifier-container></notifier-container>
<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>Add Employee</span>
        </h1>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="card shadow-lg border-0 rounded-lg mt-5">
            <div class="card-header justify-content-center">
              <h3 class="font-weight-light my-4">New Employee</h3>
            </div>
            <div class="card-body">
              <div>
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="form-group"><label class="small blm mb-1" for="empNo">Staff No</label>
                      <input class="form-control" id="empNo" [(ngModel)]="empNo" name="empNo" type="text"
                        autocomplete="false" placeholder="Enter Staff No" /></div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group"><label class="small blm mb-1" for="admin">Make Admin</label>
                      <select class="form-control" id="admin" [(ngModel)]="admin">
                        <option value="">Select Admin Option</option>
                        <option value="true">Make Admin</option>
                        <option value="false">Dont Make Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="small blm mb-1" for="password">Password</label>
                      <input class="form-control" id="password" name="password" [(ngModel)]="password" type="password"
                        placeholder="Enter password" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="small blm mb-1" for="confirm_password">Confirm Password</label>
                      <input class="form-control" id="confirm_password" [(ngModel)]="confirm_password" type="password"
                        placeholder="Confirm password" />
                    </div>
                  </div>
                </div>

                <div class="form-group mt-4 mb-0">
                  <button class="btn btn-success btn-block" (click)="saveEmployee()">Create Employee</button>
                  <a class="btn btn-primary btn-block" [routerLink]="['/employees']">Cancel</a>
                </div>
              </div>
            </div>
            <div class="card-footer text-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</div>
</div>
</div>
