import { ISubCounty } from "./../../common/interfaces/SubCounty";
import { COUNTYS } from "./../../common/interfaces/CountyData";
import { ICounty } from "./../../common/interfaces/County";
import { Component, OnInit } from "@angular/core";
import { CustomerService } from "src/app/services/customer.service";
import { ICustomer } from "../interfaces/Customer";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { IOwnerType } from "src/app/common/interfaces/OwnerType";
import { OtherService } from "src/app/services/other.service";
import { Observable, of } from "rxjs";

@Component({
  selector: "app-add-customer",
  templateUrl: "./add-customer.component.html",
  styleUrls: ["./add-customer.component.css"],
})
export class AddCustomerComponent implements OnInit {
  errors: string[] = [];
  countydata: ICounty[];
  showLoader: boolean;
  subcountySelection: ISubCounty[] = [];
  constructor(
    private customerService: CustomerService,
    private notifier: NotifierService,
    private router: Router,
    private endPoints: ApiEndPointsService,
    private otherService: OtherService
  ) {}
  customer: ICustomer = {
    id: this.endPoints.constants.emptyId,
    createdAt: new Date(),
    updatedAt: new Date(),
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phone2: "",
    county: "",
    address: "",
    subCounty: "",
    password: "1234cust",
    identity: "",
    typeId: "",
    arn: "",
  };
  customerTypes: IOwnerType[] = [];
  ngOnInit() {
    this.countydata = COUNTYS;
    this.getCustomerTypes();
  }

  saveCustomer() {
    this.showLoader = true;
    this.validator(this.customer).subscribe((data) => {
      if (data.length > 0) {
        this.showLoader = false;
        return;
      } else {
        this.customerService.addCustomer(this.customer).subscribe((data) => {
          if (data.success) {
            this.notifier.notify("success", data.message);
            this.router.navigate(["customers"]);
            this.showLoader = false;
            return;
          }
          this.showLoader = false;
          this.notifier.notify("error", data.message);
        });
      }
    });
  }

  getCustomerTypes() {
    this.otherService.getTypes("Customer").subscribe((data) => {
      if (data.success) {
        this.customerTypes = data.data;
      }
    });
  }

  validator(customer: ICustomer): Observable<string[]> {
    if (!customer.typeId || customer.typeId == "" || customer.typeId == null) {
      this.errors.push("Please select a customer type first");
    }
    if (
      !customer.firstName ||
      customer.firstName == "" ||
      customer.firstName == null
    ) {
      this.errors.push("First name of customer is required");
    }
    if (
      !customer.lastName ||
      customer.lastName == "" ||
      customer.lastName == null
    ) {
      this.errors.push("Last name of customer is required");
    }
    if (!customer.email || customer.email == "" || customer.email == null) {
      this.errors.push(
        "Email of customer is required, for us to provide feedback"
      );
    }
    if (!customer.phone || customer.phone == "" || customer.phone == null) {
      this.errors.push("Phone number of customer is required");
    }
    return of(this.errors);
  }

  populateSubCounties(county: string) {
    this.subcountySelection = this.countydata.find(
      (x) => x.name == county
    ).subcounties;
  }
}
