<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
    <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-fluid">
            <div class="sb-page-header-content py-5">
                <h1 class="sb-page-header-title">
                    <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
                    <span>Employees</span>
                </h1>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-n10">
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-header"><a class="btn btn-outline-primary btn-sm" [routerLink]="['/add-employee']"
                   routerLinkActive="router-link-active" *ngIf="canraise || user.isAdmin == 1">
                    <i class="fa fa-plus"></i> Add Employee</a></div>
                            <div class="card-body">
                                <div class="sb-datatable table-responsive">
                                    <table class="table table-bordered table-hover" datatable width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Created On</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of employees;index as i">
                                                <td>{{i+1}}</td>
                                                <td>{{item.firstName}} {{item.lastName}}</td>
                                                <td>{{item.phone}}</td>
                                                <td>{{item.email}}</td>
                                                <td>
                                                    <div class="badge badge-primary badge-pill">{{item.createdAt | date:'medium'}}</div>
                                                </td>
                                                <td>
                                                        <a class="badge badge-primary" *ngIf="canviewdetails || user.isAdmin == 1"
                                                        [routerLink]="['/employee-details', item.id]">
                                                            <i class="fa fa-eye"></i>
                                                        </a> |
                                                        <a class="badge badge-warning" *ngIf="canedit || user.isAdmin == 1"
                                                        [routerLink]="['/edit-employee', item.id]">
                                                            <i class="fa fa-edit"></i>
                                                        </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
        </div>
    </div>
    </div>
</main>
</div>
</div>
</div>
