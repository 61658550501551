<footer class="sb-footer py-4 mt-auto sb-footer-light">
  <div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between small">
      <div>Copyright &#xA9; abnosoftwares.com</div>
      <div>
        <a href="#">Privacy Policy</a>
        &#xB7;
        <a href="#">Terms &amp; Conditions</a>
      </div>
    </div>
  </div>
</footer>
