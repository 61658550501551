<notifier-container></notifier-container>
<app-loading *ngIf="showLoader"></app-loading>

<div
	class="row d-flex align-items-Center justify-content-center"
	*ngIf="customer.id != emptyId && !showLoader"
>
	<div class="col-md-6 row">
		<div class="col-md-12">
			<div class="card shadow-lg border-0 rounded-lg mt-5">
				<div class="card-header">
					<h4>Enter Ticket details</h4>
				</div>
				<div class="card-body">
					<div>
						<div class="form-row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="small blm mb-1" for="title"
										>Describe your Issue</label
									>
									<input
										class="form-control py-4"
										id="title"
										[(ngModel)]="ticket.title"
										name="title"
										type="text"
										placeholder="Enter title"
									/>
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="small blm mb-1" for="description"
										>Describe your issue in details</label
									>
									<textarea
										class="form-control sb-form-control-solid"
										id="description"
										name="description"
										[(ngModel)]="ticket.description"
										placeholder="Describe your issue"
										rows="3"
									></textarea>
								</div>
							</div>
						</div>
						<div class="form-row">
							<div class="col-md-12">
								<div class="form-group">
									<label class="small blm mb-1" for="typeId"
										>Issue Category</label
									>
									<select
										class="form-control"
										id="typeId"
										[(ngModel)]="ticket.typeId"
									>
										<option value="">Select Category</option>
										<option *ngFor="let type of types" value="{{ type.id }}">
											{{ type.name }}
										</option>
									</select>
								</div>
							</div>
						</div>
						<div class="form-group mt-4 mb-0">
							<button class="btn btn-primary btn-block" (click)="saveTicket()">
								Save Ticket
							</button>
						</div>
					</div>
				</div>
				<div class="card-footer text-center"></div>
			</div>
		</div>
	</div>
</div>
