import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { IOwnerType } from 'src/app/common/interfaces/OwnerType';
import { OtherService } from 'src/app/services/other.service';
import { Router } from '@angular/router';
import { SettingService } from 'src/app/services/setting.service';


@Component({
  selector: 'app-type-setting',
  templateUrl: './type-setting.component.html',
  styleUrls: ['./type-setting.component.css']

})
export class TypeSettingComponent implements OnInit {
  constructor(private otherService: OtherService, private notifier: NotifierService,
    private endPoints: ApiEndPointsService, private router: Router, private settingService : SettingService) { }
  initTypeSetting: IOwnerType = {
    id: this.endPoints.constants.emptyId,
    name: "",
    owner: "",
    createdAt: new Date,
    updatedAt: new Date,
    type: "",
    defaultMessage: ""
  }
  typesData = [ {"value": "Customer", "name":"Customer"},{"value": "IT", "name":"Internal IT Helpdesk"},{"value": "PR", "name":"Public Relations"} ]

  typeSetting: IOwnerType = this.initTypeSetting;

  types: IOwnerType[] = [];
  ngOnInit() {
    this.getTypeSettings();
  }
  getTypeSettings() {

    this.otherService.getAllTypes().subscribe(data => {
      if (data.success) {
        this.types = data.data;
      }else{
        this.notifier.notify('error', data.message);
      }

    });
  }

  saveTypeSetting() {
    if(this.typeSetting.owner === 'IT'){
      this.typeSetting.type = 'Internal';
    }
    if(this.typeSetting.owner === 'PR'){
      this.typeSetting.type = 'External';
    }
    this.otherService.addType(this.typeSetting).subscribe(data => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.router.navigate(['settings']);
        this.getTypeSettings();
        this.typeSetting = this.initTypeSetting;

        window.location.reload();
        return;
      }else{
        this.notifier.notify('error', data.message);
      }

    });
  }

  deleteTypeSetting(id : string){

    this.settingService.deleteTypeSetting(id).subscribe(data=>{
      if(data.success){
        this.notifier.notify("success", data.message);
        this.getTypeSettings();

        return;
      }

      this.notifier.notify("error", data.message);
    });
  }

}
