<div class="row">
    <div class="col-md-12">
        <notifier-container></notifier-container>

        <div class="card">
            <div class="card-header border-bottom">
                <ul class="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="overview-tab" href="#overview" data-toggle="tab"
                            role="tab" aria-controls="overview" aria-selected="true">Category Settings</a></li>
                    <li class="nav-item"><a class="nav-link" id="example-tab" href="#example" data-toggle="tab"
                            role="tab" aria-controls="example" aria-selected="false">Add Category</a></li>

                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="cardTabContent">
                    <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                        <div class="sb-datatable table-responsive">
                            <table class="table table-bordered table-hover display" datatable width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Category Owner</th>
                                        <th>Created On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let type of types;index as i">
                                        <td>{{i+1}}</td>
                                        <td>{{type.name}}</td>
                                        <td>{{type.owner}} <div class="badge badge-info" *ngIf="type.owner == 'Ticket'">{{type.type}}</div></td>
                                        <td>
                                            <div class="badge badge-primary badge-pill">
                                                {{type.createdAt | date:'medium'}}</div>
                                        </td>
                                        <td>
                                            <button class="btn sb-btn-datatable sb-btn-icon sb-btn-outline-danger mr-2" (click)="deleteTypeSetting(type.id)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="example" role="tabpanel" aria-labelledby="example-tab">
                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group"><label class="small blm mb-1" for="name">Name</label>
                                    <input class="form-control py-4" id="venue" name="name"
                                        [(ngModel)]="typeSetting.name" type="text" placeholder="Enter Name" />
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group"><label class="small blm mb-1" for="typeId">Category Owner</label>
                                    <span class="custom-dropdown">
                                        <select class="form-control dropdown-class" id="typeId"
                                            [(ngModel)]="typeSetting.owner">
                                            <option [ngValue]="''" disabled>Select Owner</option>
                                            <option *ngFor="let type of typesData" [ngValue]="type.value">{{type.name}}
                                            </option>
                                        </select>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-row" *ngIf="typeSetting?.owner == 'IT' || typeSetting?.owner == 'PR'">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="small blm mb-1" for="defaultMessage">Enter default text for this item</label>
                              <textarea class="form-control sb-form-control-solid" id="defaultMessage" name="defaultMessage"
                                [(ngModel)]="typeSetting.defaultMessage" placeholder="Enter default text" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group mt-4 mb-0">
                                    <button class="btn btn-primary btn-block" (click)="saveTypeSetting()">Create
                                        Category</button>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
