<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main>
  <notifier-container></notifier-container>

  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="droplet"></i></div>
          <span>Create a survey</span>
        </h1>
        <div class="sb-page-header-subtitle">
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <ul class="list-group list-group-flush" *ngIf="survey?.id != emptyId">
                  <li class="list-group-item">Title: {{survey?.title}}</li>
                  <li class="list-group-item">
                    <h6>Description</h6>
                    <p>{{survey?.description}}</p>
                  </li>
                  <li class="list-group-item">Created On : {{survey?.createdAt | date:'medium'}}</li>
                </ul>

                <div class="row" *ngIf="survey.id == emptyId">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <label class="small mb-1" for="title">Survey Title</label>
                        <input type="text" class="form-control" [(ngModel)]="survey.title" id="title" name="title"
                          placeholder="Survey title"><br>
                        <div class="form-group">
                          <label class="small mb-1" for="typeId">Type of Survey</label>
                          <select class="form-control" id="typeId" [(ngModel)]="survey.type">
                            <option value="">Select Survey Type</option>
                            <option value="Internal">For Staff</option>
                            <option value="External">For Customers</option>
                          </select>
                        </div>
                        <div class="card-body">
                          <select class="form-control" id="departmentId" [(ngModel)]="survey.departmentId">
                            <option value="">--Select Department</option>
                            <option *ngFor="let department of departments" value="{{department?.id}}">
                              {{department?.name}}</option>
                          </select>

                        </div>
                        <label class="small mb-1" for="description">Survey Description</label>
                        <textarea class="form-control sb-form-control-solid" id="description" name="description"
                          [(ngModel)]="survey.description" placeholder="Enter task description" rows="3"></textarea><br>

                        <button class="btn btn-success btn-block" (click)="saveSurvey()">Save Survey</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="card">
                  <div class="card-header" style="width: 100% !important;">
                    <h6>Survey Questions</h6> <br>

                    <button class="btn btn-outline-primary" class="btn btn-primary btn-sm" data-toggle="modal"
                      style="text-align: right;" data-target="#questionDialog" *ngIf="survey?.id != emptyId">
                      Add Question <i class="fa fa-plus"></i>
                    </button>
                  </div>
                  <div class="card-content" *ngIf="questions?.length > 0">
                    <ol *ngFor="let questItem of questions;let i = index">
                      <h6>{{i+1}} . {{questItem.question.question}}</h6>
                      <ul style="list-style: none;">
                        <li *ngIf="questItem.question.type ==='Review'">
                          <div class="custom-control">
                            <div class="custom-control" *ngFor="let review of reviews;index as i">
                              <input type="radio" /><label for="">{{i+1}} {{review.name}}</label><br>
                            </div>
                          </div>
                        </li>
                        <li *ngIf="questItem.question.type ==='OpenQuiz'">
                          <div class="custom-control md-textarea">
                            <textarea class="form-control sb-form-control-solid" id="" name="{{questItem.question.id}}"
                              placeholder="Enter the question" rows="2"></textarea><br>
                          </div>
                        </li>
                      </ul>
                      <ul *ngFor="let optionItem of questItem.options" style="list-style: none;">

                        <li *ngIf="questItem.question.type ==='multiple'">
                          <div class="custom-control custom-checkbox"><input class="custom-control-input"
                              id="{{optionItem.id}}" type="checkbox"><label class="custom-control-label"
                              for="{{optionItem.id}}">{{optionItem.option}}</label>
                          </div>
                        </li>
                        <li *ngIf="questItem.question.type ==='single'">
                          <div class="custom-control custom-radio"><input class="custom-control-input"
                              id="{{optionItem.id}}" type="radio" name="{{questItem.question.id}}">
                            <label class="custom-control-label" for="{{optionItem.id}}">{{optionItem.option}}</label>
                          </div>
                        </li>
                      </ul>
                      <button class="btn btn-outline-primary" (click)="setActiveQuestion(questItem.question.id)"
                        *ngIf="questItem.question.type=='single' || questItem.question.type== 'multiple'"
                        class="btn btn-primary btn-sm" data-toggle="modal" data-target="#optionDialog">Add Option <i
                          class="fa fa-plus"></i>
                      </button>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><br /><br />
      </div>
    </div>
  </div>
</main>
</div>
</div>
</div>

<!-- Modal -->
<div class="modal fade" id="questionDialog" tabindex="-1" role="dialog" aria-labelledby="questionDialog"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Add Question</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="form-group">
            <label class="small mb-1" for="newQuiz">Survey Question</label>
            <textarea class="form-control sb-form-control-solid" id="newQuiz" name="newQuiz" [(ngModel)]="newQuiz"
              placeholder="Enter question" rows="3"></textarea>
          </div>
          <div class="form-group">
            <label class="small mb-1" for="typeId">Question Type</label>
            <select class="form-control" id="typeId" [(ngModel)]="activeType">
              <option value="">Select Question Type</option>
              <option value="single">Single Choice</option>
              <option value="multiple">Multiple Choices</option>
              <option value="OpenQuiz">Open Question</option>
              <option value="Review">Service Review</option>
            </select>
          </div>
          <!-- <div class="form-group">
            <label class="small mb-1" for="newQuiz">Service Review</label><br>
            <ngb-rating [max]="5" [(rate)]="currentRate" [readonly]="false"></ngb-rating>
            <label  class="small mb-1" for="rateId">{{currentRate}}</label>
          </div> -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="saveQuestion()" data-dismiss="modal">Save Question<i
            class="fa fa-save"></i></button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="optionDialog" tabindex="-1" role="dialog" aria-labelledby="optionDialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Add Question Option</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="form-group">
            <label class="small mb-1" for="newOption">Question Option</label>
            <input type="text" class="form-control" [(ngModel)]="newOption" id="newOption" name="newOption"
              placeholder="Question option" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="saveOption()" data-dismiss="modal">Save <i
            class="fa fa-save"></i></button>
      </div>
    </div>
  </div>
</div>
