<nav class="topnav navbar navbar-expand shadow navbar-light bg-white" id="sidenavAccordion">
  <a class="navbar-brand active" href="index.html">Support Management</a>
  <button class="btn btn-icon btn-transparent-dark order-1 order-lg-0 mr-lg-2" id="sidebarToggle" href="#">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
      stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu">
      <line x1="3" y1="12" x2="21" y2="12"></line>
      <line x1="3" y1="6" x2="21" y2="6"></line>
      <line x1="3" y1="18" x2="21" y2="18"></line>
    </svg>
  </button>

  <ul class="navbar-nav align-items-center ml-auto">

    <li class="nav-item dropdown no-caret mr-2 dropdown-user">
      <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage"
        href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img
          class="img-fluid" src="{{'assets/images/avator.png'}}"></a>
      <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
        aria-labelledby="navbarDropdownUserImage">
        <h6 class="dropdown-header d-flex align-items-center">
          <img class="dropdown-user-img" src="{{'assets/images/avator.png'}}">
          <div class="dropdown-user-details">
            <div class="dropdown-user-details-name">{{user?.firstName}} {{user?.lastName}}</div>
            <div class="dropdown-user-details-email">{{user?.email}}</div>
          </div>
        </h6>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" [routerLink]="['/profile']">
          <div class="dropdown-item-icon">
          </div>
          Profile
        </a>
        <!-- <a class="dropdown-item" (click)="logout()"> -->
        <!-- <a class="dropdown-item" href="https://registration.ngobureau.go.ke/uat/front/#/staff-portal"> -->
        <a class="dropdown-item" href="https://registration.ngobureau.go.ke/#/index">
          <div class="dropdown-item-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" class="feather feather-log-out">
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7">

              </polyline>
              <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg></div>
          Logout
        </a>
      </div>
    </li>
  </ul>
</nav>
