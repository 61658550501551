import { ISource } from './../interfaces/Source';
import { Component, OnInit } from '@angular/core';
import { OtherService } from 'src/app/services/other.service';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { Router } from '@angular/router';
import { SettingService } from 'src/app/services/setting.service';


@Component({
  selector: 'app-sources',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.css']
})
export class SourcesComponent implements OnInit {
 newSource: ISource;
 sources: ISource[] = [];
  constructor(private otherService: OtherService, private notifier: NotifierService,
    private endPoints: ApiEndPointsService, private router: Router, private settingService : SettingService) {
      this.newSource = {
        id: this.endPoints.constants.emptyId,
        name: "",
        createdAt: new Date,
        updatedAt: new Date
      }
    }

  ngOnInit() {
    this.getSources();
  }

  getSources(){

    this.otherService.getSources().subscribe(data => {
      if (data.success) {
        this.sources = data.data;
      }else{
        this.notifier.notify('error', data.message);
      }

    });
  }

  saveSource(){

    this.otherService.addSource(this.newSource).subscribe(data => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.router.navigate(['settings']);
        this.getSources();

        window.location.reload();
        return;
      }else{
        this.notifier.notify('error', data.message);
      }

    });
  }
  deleteSource(id: string){

    this.otherService.deleteSource(id).subscribe(data=>{
      if(data.success){
        this.getSources();

        return;
      }

      this.notifier.notify("error", data.message);
    });
  }
}
