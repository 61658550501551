import { Component, OnInit } from "@angular/core";
import { FaqService } from "src/app/services/faq.service";

@Component({
  selector: "app-public-faq",
  templateUrl: "./public-faq.component.html",
  styleUrls: ["./public-faq.component.css"],
})
export class PublicFaqComponent implements OnInit {
  faqs: any[] = [];
  type: "";
  showLoader: boolean;
  constructor(private faqService: FaqService) {}

  ngOnInit() {
    this.getFaqs();
  }

  getFaqs() {
    this.showLoader = true;
    
    this.faqService.getFaqs("External").subscribe((data) => {
      console.log(data);
      if (data.success) {
        this.faqs = data.data;
      } else {
        this.showLoader = false;
      }
    });
  }
}
