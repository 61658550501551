import { Component, OnInit } from '@angular/core';
import { IDepartment } from '../interfaces/Department';
import { DepartmentService } from 'src/app/services/department.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';


@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.css']
})
export class AddDepartmentComponent implements OnInit {
  constructor(private departmentService : DepartmentService, private router: Router,private notifier: NotifierService,
     private endPointService : ApiEndPointsService ) { }
  department : IDepartment = {
    name :"",
    email : "",
    updatedAt : new Date,
    createdAt : new Date,
    id : this.endPointService.constants.emptyId,
    departmentHeadId : this.endPointService.constants.emptyId
  };
  submitted : boolean;
  showLoader: boolean;

  ngOnInit() {
  }

  saveDepartment(){
    this.showLoader = true;
    this.departmentService.addDepartment(this.department).subscribe(data=>{
      if(data.success){
        this.notifier.notify("success", data.message);
          this.router.navigate(['/departments']);
        this.showLoader = false;
          return;
      }
      this.showLoader = false;
      this.notifier.notify("error", data.message);
    }
    );
  }
}
