import { IEmployee } from './../employee/interfaces/Employee';
import { EmployeeService } from './../services/employee.service';
import { LogService } from './../services/log.service';
import { NotifierService } from 'angular-notifier';
import { ILog } from './interfaces/Log';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from "../services/customer.service";
import { ICustomer } from "../customer/interfaces/Customer";
import { AuthService } from "./../services/auth.service";
import { identity } from 'rxjs';


@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css'],
  providers: [LogService]
})
export class LogComponent implements OnInit {
  logs: ILog[] = []
  employee: IEmployee;
  error : string;
  user:any;
  customer: ICustomer[]=[];
  customerId : string;
  constructor(private notifier: NotifierService, private logService: LogService, private authService: AuthService, private customerService: CustomerService, private employeeService: EmployeeService) { }

  ngOnInit() { 
    this.getLogs()
  }

  getLogs(){
    this.logService.getLogs().subscribe(data=>{
      if(data.success){
        this.logs = data.data;
        return;
      }
      console.log(this.logs);
      this.notifier.notify('error', data.message);
    });
  }
  getUser(id: string){
    console.log(id);
    this.logService.getUser(id).subscribe((data)=>{
      console.log(data);
      if(data.success){
        this.user=data.data;
        return;

      }
    });

  }

  getEmployee(id: string){
    this.employeeService.getEmployee(id).subscribe(data=>{
      if(data.success){
        this.employee = data.data;
        return;
      }
      this.error = data.message;
      this.notifier.notify('error', data.message);
    });
  }
  getCustomer(id: string){
    this.customerService.getCustomer(id).subscribe(data=>{
      if(data.success){
        this.customer = data.data;
        return;
      }
      this.error = data.message;
      this.notifier.notify('error', data.message);
    });
  }


}
