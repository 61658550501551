<div class="row">
    <div class="col-md-12">
        <notifier-container></notifier-container>
        <div class="card">
            <div class="card-header border-bottom">
                <ul class="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="reviews-tab" href="#reviews" data-toggle="tab"
                            role="tab" aria-controls="reviews" aria-selected="true">Reviews Settings</a></li>
                    <li class="nav-item"><a class="nav-link" id="addReview-tab" href="#addReview" data-toggle="tab"
                            role="tab" aria-controls="addReview" aria-selected="false">Add Review OPtion</a></li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="cardTabContent">
                    <div class="tab-pane fade show active" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                        <div class="sb-datatable table-responsive">
                            <table class="table table-bordered table-hover display" datatable width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Created On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let review of reviews;index as i">
                                        <td>{{i+1}}</td>
                                        <td>{{review.name}}</td>
                                        <td>
                                            <div class="badge badge-primary badge-pill">
                                                {{review.createdAt | date:'medium'}}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="addReview" role="tabpanel" aria-labelledby="addReview-tab">
                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group"><label class="small blm mb-1" for="name">Name</label>
                                    <input class="form-control " id="venue" name="name"
                                        [(ngModel)]="newReview.name" type="text" placeholder="Enter Name" />
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group mt-4 mb-0">
                                    <button class="btn btn-primary btn-block" (click)="saveReview()">Create
                                        Type</button>
                                </div>
                            </div>
                        </div>
                      </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  