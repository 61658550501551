import { Component, OnInit } from "@angular/core";
import { DepartmentService } from "../services/department.service";
import { IDepartment } from "./interfaces/Department";
import { IEmployee } from "../employee/interfaces/Employee";
import { IUserPrivilege } from "../common/interfaces/UserPrivilege";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-department",
  templateUrl: "./department.component.html",
  styleUrls: ["./department.component.css"],
})
export class DepartmentComponent implements OnInit {
  departments: IDepartment[] = [];
  canraise: boolean;
  canviewdetails: boolean;
  canedit: boolean;
  canraiseticket: boolean;
  showLoader: boolean;
  user: any;
  constructor(
    private departmentService: DepartmentService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;

    });
    this.getDepartments();
  }

  getDepartments() {
    this.showLoader = true;
    this.departmentService.getDepartments().subscribe((data) => {
      if (data.success) {
        this.departments = data.data;
        this.showLoader = false;
      } else {
        this.showLoader = false;
      }
    });
  }

}
