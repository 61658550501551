import { Injectable,Inject } from '@angular/core';
import { tap,catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {ApiEndPointsService} from 'src/app/common/api-end-points.service'

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  baseUrl: string;

  constructor(private http: HttpClient, private apiEndPoints: ApiEndPointsService, @Inject('BASE_URL') baseUrl: string) { 
    this.baseUrl = baseUrl;
  }

  uploadFiles(fileData,ticketId): any {
    const formData = new FormData();
    formData.append('File', fileData);
    formData.append('TicketId',ticketId);
    return this.http.post(this.apiEndPoints.tickets.uploadFiles, formData)
    .pipe(tap(), catchError(this.apiEndPoints.handleError));
  }
  uploadedFiles(ticketId): any {
    return this.http.get(this.baseUrl +this.apiEndPoints.tickets.uploadedFiles+ticketId)
    .pipe(tap(), catchError(this.apiEndPoints.handleError));
  }
}
