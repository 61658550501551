<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>Complains</span>
        </h1>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">
            <div *ngIf="user?.type == 'Employee' || user?.isAdmin == 1">
              <button class="btn btn-outline-primary btn-sm" type="button" data-toggle="modal"
                data-target="#exampleModalLong" *ngIf="canraise || user.isAdmin == 1"
                routerLinkActive="router-link-active">
                <i class="fa fa-plus"></i> Complains
              </button> ||
              <a class="btn btn-outline-primary btn-sm" *ngIf="canraise || user.isAdmin == 1"
                [routerLink]="['/add-ticket', emptyId]">
                <i class="fa fa-plus"></i> Internal IT helpdesk
              </a>
            </div>
            <div *ngIf="user?.type == 'Customer'">
              <a class="btn btn-outline-primary btn-sm" [routerLink]="['/add-ticket', user?.id]">
                <i class="fa fa-plus"></i> New Complain
              </a>
            </div>||
            <div style="text-align: right;" *ngIf="user.isAdmin == 1">
              <button class="btn btn-info pull-right"  (click)="export()">Export</button>
            </div>
          </div>
          <div class="card-body">
            <div class="sb-datatable table-responsive">
              <table class="table table-bordered table-hover" datatable width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Date Received</th>
                    <th>Requested by</th>
                    <th>Request</th>
                    <th>Request Channel</th>
                    <th>Date Acknowledged</th>
                    <th>Date Completed</th>
                    <!-- <th>Title</th>
                    <th>Description</th>
                    <th>Created On</th>
                    <th>Closed</th> -->
                    <th>Request Status</th>
                    <th>Action Taken</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let model of tickets;index as i">
                    <td>{{model.ticket.id.substring(0,7) | uppercase}}</td>
                    <!-- <td>{{ticket.title}}</td> -->
                    <td>{{model.ticket.createdAt | date:'medium'}}</td>
                    <td>{{model.customer.firstName +'&nbsp;' + model.customer.lastName}}</td>
                    <td>{{model.ticket.description}}</td>
                    <td>{{model.ticket.source}}</td>
                    <td>
                      <div *ngIf="model.acknowledgedAt">
                        {{model.acknowledgedAt | date:'medium'}}
                      </div>
                      <div *ngIf="!model.acknowledgedAt">
                        <p>N/A</p>

                      </div>
                    </td>
                    <td>
                      <div *ngIf="model.dateCompleted">
                        {{model.dateCompleted | date:'medium'}}
                      </div>
                      <div *ngIf="!model.dateCompleted">
                        <p>N/A</p>

                      </div>
                    </td>

                    <!-- <td>{{ticket.description}}</td> -->
                    <!-- <td>{{ticket.createdAt | date:'medium'}}</td> -->
                    <!-- <td>
                      <div class="badge badge-primary badge-pill">{{ticket.closed?"Closed":"Open"}}</div>
                    </td> -->
                    <td>
                      <div *ngIf="model.status">
                        {{model.status}}
                      </div>
                      <div *ngIf="!model.status">
                        <p>N/A</p>
                      </div>

                    </td>
                    <td>
                      <a class="badge badge-primary " [routerLink]="['/ticket-details',model.ticket.id]"
                        *ngIf="canviewdetails || user.isAdmin == 1 || user?.type =='Customer'">
                        <i class="fa fa-eye"></i> View
                      </a> |
                      <a class="badge badge-warning" [routerLink]="['/edit-ticket', model.ticket.id]"
                      *ngIf="!model.status">
                        <i class="fa fa-edit"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</div>
</div>
</div>

<!-- Modal -->
<div class="modal fade bd-example-modal-lg" id="exampleModalLong" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Raise Complain</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="row" *ngIf="!create_new">
            <div class="col-md-8">
              <div class="form-group">
                <input class="form-control py-4" id="title" [(ngModel)]="search_key" name="search_key" type="text"
                  placeholder="Search customer by phone" /></div>
            </div>
            <div class="col-md-4">
              <button class="btn btn-outline-primary btn-lg" (click)="searchCustomer()">Search <i
                  class="fa fa-search"></i></button>
            </div>
            <hr>
          </div>

          <div class="row" *ngIf="!create_new">
            <div class="col-md-2"></div>
            <div class="col-md-8" style="text-align: center;">
              <button class="btn btn-success" (click)="newCustomer()" *ngIf="canaddcustomer || user.isAdmin == 1">New
                Customer <i class="fa fa-user"></i></button>
            </div>
            <div class="col-md-2"></div> <br>
          </div>

          <div class="sb-datatable table-responsive" *ngIf="customers.length > 0">
            <table class="table table-bordered table-hover" id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>ID/Passport</th>
                  <th>ARN</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let customer of customers">
                  <td>{{customer?.firstName}} {{customer?.lastName}}</td>
                  <td>{{customer?.phone}}</td>
                  <td>{{customer?.email}}</td>
                  <td>{{customer?.identity}}</td>
                  <td>{{customer?.arn}}</td>
                  <td>
                    <a class="badge badge-success" data-dismiss="modal" [routerLink]="['/add-ticket', customer?.id]">
                      <i class="fa fa-ticket-alt"></i> Raise
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div><br>

          <div *ngIf="create_new">
            <h5>Create Customer</h5>
            <hr>
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group"><label class="small blm mb-1" for="firstName">First Name</label>
                  <input class="form-control" id="firstName" [(ngModel)]="new_customer.firstName" name="firstName"
                    type="text" placeholder="Enter first name" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small blm mb-1" for="lastName">Last Name</label>
                  <input class="form-control" id="lastName" [(ngModel)]="new_customer.lastName" name="lastName"
                    type="text" placeholder="Enter last name" /></div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="small blm mb-1" for="email">Email</label>
                  <input class="form-control" id="email" name="email" [(ngModel)]="new_customer.email" type="email"
                    placeholder="Enter email address" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="small blm mb-1" for="phone">Phone/ Mobile</label>
                  <input class="form-control" id="phone" [(ngModel)]="new_customer.phone" type="text"
                    placeholder="Enter phone number" />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-12">
                <div class="form-group"><label class="small blm mb-1" for="county">Are they under KYEOP</label>
                  <select class="form-control" id="county" (change)="showBeneficiaryDetails($event.target.value)">
                    <option value="">Are they under KYEOP</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="isBeneficiary">
              <div class="col-md-6">
                <div class="form-group"><label class="small blm mb-1" for="arn">ARN Number</label>
                  <input class="form-control py-4" id="arn" name="arn" [(ngModel)]="new_customer.arn" type="text"
                    placeholder="Enter ARN Number" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small blm mb-1" for="identity">Id/Passport No</label>
                  <input class="form-control py-4" id="identity" name="identity" [(ngModel)]="new_customer.identity"
                    type="text" placeholder="Enter Id/Passport No." />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group"><label class="small blm mb-1" for="county">County</label>
                  <select class="form-control" id="county" [(ngModel)]="new_customer.county"
                    (change)="populateSubCounties($event.target.value)">
                    <option value="">Select County</option>
                    <option *ngFor="let county of countydata" value="{{county.name}}">{{county.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small blm mb-1" for="subCounty">Sub County</label>
                  <select class="form-control" id="subCounty" [(ngModel)]="new_customer.subCounty">
                    <option value="">Select Sub County</option>
                    <option *ngFor="let subCounty of subcountySelection" value="{{subCounty.name}}">{{subCounty.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group"><label class="small blm mb-1" for="address">Address</label>
                  <input class="form-control" id="address" name="address" [(ngModel)]="new_customer.address" type="text"
                    placeholder="Address" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small blm mb-1" for="typeId">Customer Type</label>
                  <select class="form-control" id="typeId" [(ngModel)]="new_customer.typeId">
                    <option value="">Select Customer Type</option>
                    <option *ngFor="let type of customerTypes" value="{{type.id}}">{{type.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <ul>
              <li *ngFor="let err of errors" style="color: red;">{{err}}</li>
            </ul>
            <div class="form-group mt-4 mb-0" style="text-align: right;">
              <button class="btn btn-primary" (click)="saveCustomer()">Save Customer <i class="fa fa-save"></i></button>
            </div>
          </div>

        </div>
      </div>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close <i class="fa fa-times"></i></button>
    </div>
  </div>
</div>
