import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NotifierModule } from "angular-notifier";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ChartsModule } from "ng2-charts";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { TicketComponent } from "./ticket/ticket.component";
import { AuthComponent } from "./auth/auth.component";
import { DepartmentComponent } from "./department/department.component";
import { EmployeeComponent } from "./employee/employee.component";
import { CustomerComponent } from "./customer/customer.component";
import { SettingComponent } from "./setting/setting.component";
import { DataTablesModule } from "angular-datatables";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { CustomerDetailComponent } from "./customer/customer-detail/customer-detail.component";
import { AddCustomerComponent } from "./customer/add-customer/add-customer.component";
import { EditCustomerComponent } from "./customer/edit-customer/edit-customer.component";
import { DepartmentDetailComponent } from "./department/department-detail/department-detail.component";
import { AddDepartmentComponent } from "./department/add-department/add-department.component";
import { EditDepartmentComponent } from "./department/edit-department/edit-department.component";
import { EmployeeDetailComponent } from "./employee/employee-detail/employee-detail.component";
import { AddEmployeeComponent } from "./employee/add-employee/add-employee.component";
import { EditEmployeeComponent } from "./employee/edit-employee/edit-employee.component";
import { AddTicketComponent } from "./ticket/add-ticket/add-ticket.component";
import { EditTicketComponent } from "./ticket/edit-ticket/edit-ticket.component";
import { TicketDetailComponent } from "./ticket/ticket-detail/ticket-detail.component";
import { FaqComponent } from "./faq/faq.component";
import { SmtpSettingComponent } from "./setting/smtp-setting/smtp-setting.component";
import { TypeSettingComponent } from "./setting/type-setting/type-setting.component";
import { EscalationSettingComponent } from "./setting/escalation-setting/escalation-setting.component";
import { ProfileComponent } from "./profile/profile.component";
import { AnonTicketComponent } from "./anon-ticket/anon-ticket.component";
import { LogComponent } from "./log/log.component";
import { CommonModule } from "@angular/common";
import { LoadingComponent } from "./loading/loading.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SurveyComponent } from "./survey/survey.component";
import { SurveyDetailsComponent } from "./survey/survey-details/survey-details.component";
import { AddSurveyComponent } from "./survey/add-survey/add-survey.component";
import { UserSurveyComponent } from "./user-survey/user-survey.component";
import { ReportsComponent } from "./reports/reports.component";
import { TicketReportComponent } from "./reports/ticket-report/ticket-report.component";
import { CustomerReportComponent } from "./reports/customer-report/customer-report.component";
import { AddFaqComponent } from "./faq/add-faq/add-faq.component";
import { FaqDetailsComponent } from "./faq/faq-details/faq-details.component";
import { EditFaqComponent } from "./faq/edit-faq/edit-faq.component";
import { SourcesComponent } from "./setting/sources/sources.component";
import { PublicFaqComponent } from "./faq/public-faq/public-faq.component";
import { StaffSurveyComponent } from "./staff-survey/staff-survey.component";
import { StaffSurveyDetailComponent } from "./staff-survey/staff-survey-detail/staff-survey-detail.component";
import {ReviewSettingComponent} from "./setting/review-setting/review-setting.component";
import { ItHelpdeskComponent } from "./ticket/it-helpdesk/it-helpdesk.component";
import { ItHelpdeskReportComponent } from "./reports/it-helpdesk-report/it-helpdesk-report.component";
import { MyItRequestsComponent } from "./ticket/my-it-requests/my-it-requests.component";
import { CustomerSurveyComponent } from "./survey/customer-survey/customer-survey.component";
import { CustomerSurveyDetailComponent } from "./survey/customer-survey/customer-survey-detail/customer-survey-detail.component";
import { InternalFaqComponent } from "./faq/internal-faq/internal-faq.component";
import { CorruptionComponent } from './corruption/corruption.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TicketComponent,
    AuthComponent,
    DepartmentComponent,
    EmployeeComponent,
    CustomerComponent,
    SettingComponent,
    SidenavComponent,
    DashboardComponent,
    LoginComponent,
    CustomerDetailComponent,
    AddCustomerComponent,
    EditCustomerComponent,
    DepartmentDetailComponent,
    AddDepartmentComponent,
    EditDepartmentComponent,
    EmployeeDetailComponent,
    AddEmployeeComponent,
    EditEmployeeComponent,
    AddTicketComponent,
    EditTicketComponent,
    TicketDetailComponent,
    MyItRequestsComponent,
    FaqComponent,
    SmtpSettingComponent,
    TypeSettingComponent,
    EscalationSettingComponent,
    ProfileComponent,
    AnonTicketComponent,
    SourcesComponent,
    LogComponent,
    LoadingComponent,
    ResetPasswordComponent,
    SurveyComponent,
    AddSurveyComponent,
    SurveyDetailsComponent,
    UserSurveyComponent,
    ReportsComponent,
    TicketReportComponent,
    CustomerReportComponent,
    CustomerSurveyComponent,
    CustomerSurveyDetailComponent,
    AddFaqComponent,
    FaqDetailsComponent,
    EditFaqComponent,
    InternalFaqComponent,
    PublicFaqComponent,
    StaffSurveyComponent,
    StaffSurveyDetailComponent,
    ReviewSettingComponent,
    ItHelpdeskComponent,
    ItHelpdeskReportComponent,
    CorruptionComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ChartsModule,
    BrowserAnimationsModule,
    NgbModule,
    DataTablesModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: "right",
        },
        vertical: {
          position: "top",
        },
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
