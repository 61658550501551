<div class="row">
    <div class="col-md-12">
        <notifier-container></notifier-container>

        <div class="card">
            <div class="card-header border-bottom">
                <ul class="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="escalation-tab" href="#escalation" data-toggle="tab"
                            role="tab" aria-controls="overview" aria-selected="true">Escalation Settings</a></li>
                    <li class="nav-item"><a class="nav-link" id="add-escalation-tab" href="#add-escalation" data-toggle="tab"
                            role="tab" aria-controls="example" aria-selected="false">Add Escalation</a></li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="cardTabContent">
                    <div class="tab-pane fade show active" id="escalation" role="tabpanel" aria-labelledby="escalation-tab">
                        <div class="sb-datatable table-responsive">
                            <table class="table table-bordered table-hover display" datatable width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Type</th>
                                        <th>Due Time</th>
                                        <th>Critical Time</th>
                                        <th>Created On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let escalationSetting of escalations;index as i">
                                        <td>{{i+1}}</td>
                                        <td>{{escalationSetting.type}}</td>
                                        <td>{{escalationSetting.dueDuration}} {{escalationSetting.dueUnit}}</td>
                                        <td>{{escalationSetting.criticalDuration}} {{escalationSetting.criticalUnit}}</td>
                                        <td>
                                            <div class="badge badge-primary badge-pill">
                                                {{escalationSetting.createdAt | date:'medium'}}</div>
                                        </td>
                                        <td>
                                            <button class="btn sb-btn-datatable sb-btn-icon sb-btn-outline-danger mr-2" (click)='deleteEscalation(escalationSetting.id)'>
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="add-escalation" role="tabpanel" aria-labelledby="add-escalation-tab">

                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group"><label class="small blm mb-1" for="typeId">Type</label>
                                    <select class="form-control" id="typeId" [(ngModel)]="escalation.typeId">
                                        <option value="">Select Type</option>
                                        <option *ngFor="let type of types" value="{{type.id}}">{{type.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <h6>Due Time</h6>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="dueDuration">Duration Time</label>
                                            <input class="form-control" id="dueDuration" name="dueDuration"
                                                [(ngModel)]="escalation.dueDuration" type="number" placeholder="Enter Duration Time" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="unit">Time Unit</label>
                                            <select class="form-control" id="unit" [(ngModel)]="escalation.dueUnit">
                                                <option value="">Select Unit</option>
                                                <option value="Minutes">Minutes</option>
                                                <option value="Hours">Hours</option>
                                                <option value="Days">Days</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <h6>Critical Time</h6>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="criticalDuration">Duration Time</label>
                                            <input class="form-control" id="criticalDuration" name="criticalDuration"
                                                [(ngModel)]="escalation.criticalDuration" type="number" placeholder="Enter Duration Time" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="criticalUnit">Time Unit</label>
                                            <select class="form-control" id="criticalUnit" [(ngModel)]="escalation.criticalUnit">
                                                <option value="">Select Unit</option>
                                                <option value="Minutes">Minutes</option>
                                                <option value="Hours">Hours</option>
                                                <option value="Days">Days</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group mt-4 mb-0">
                                    <button class="btn btn-primary btn-block" (click)="saveEscalationSetting()">
                                        Save Escalation
                                    </button>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
