import { ISource } from './../setting/interfaces/Source';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndPointsService } from '../common/api-end-points.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { IOwnerType } from '../common/interfaces/OwnerType';
import {IReviewOptions} from "../common/interfaces/ReviewOptions";
@Injectable({
  providedIn: 'root'
})
export class OtherService {
baseUrl: string;
  constructor(private http : HttpClient, private endPoints : ApiEndPointsService , @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getTypes(owner : string) : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.setting.gettypes+owner)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  getAllTypes() : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.setting.getalltypes)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  addType(type : IOwnerType) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.setting.addtype, type)
    .pipe(tap(), catchError(this.endPoints.handleError))
  }
  editType(type : IOwnerType) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.setting.edittype, type)
    .pipe(tap(), catchError(this.endPoints.handleError))
  }

  getSources() : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.setting.getsources)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addSource(source : ISource) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.setting.addSource, source)
    .pipe(tap(), catchError(this.endPoints.handleError))
  }

  deleteSource(id : string) : Observable<any>{
    return this.http.delete(this.baseUrl+this.endPoints.setting.deleteSource+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  getReviewOptions(): Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.setting.getReviewOptions)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  addReview(review :IReviewOptions) :Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.setting.addReview, review)
    .pipe(tap(), catchError(this.endPoints.handleError))
  }

}
