import { Component, OnInit } from '@angular/core';
import { ISurvey } from '../interfaces/Survey';
import { SurveyService } from 'src/app/services/survey.service';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { IQuestion } from '../interfaces/Question';
import { IOption } from '../interfaces/Option';
import { IQuestionView } from '../interfaces/QuestionView';
import{IDepartment} from 'src/app/department/interfaces/Department';
import {AuthService} from "src/app/services/auth.service";
import { DepartmentService } from "src/app/services/department.service";
import{ReviewSettingComponent} from "src/app/setting/review-setting/review-setting.component";
import {IReviewOptions} from "src/app/common/interfaces/ReviewOptions";
import { OtherService } from 'src/app/services/other.service';
import { count } from 'rxjs/operators';
import { from } from 'rxjs';

@Component({
  selector: 'app-add-survey',
  templateUrl: './add-survey.component.html',
  styleUrls: ['./add-survey.component.css']
})
export class AddSurveyComponent implements OnInit {
  userId : string;
  emptyId: string;
  showLoader: boolean;
  survey : ISurvey;
  surveyQuestion : IQuestion;
  option : IOption;
  departmentId: any[];
  questions : IQuestionView[] =[];
  departments: IDepartment[] = [];
  reviews: IReviewOptions[]=[];

  newQuiz: string = '';
  newOption: string = '';
  activeType: string = '';
  activeQuestionId: string;
  currentRate=0;

  constructor(private surveyService: SurveyService, private otherService: OtherService, private notifier : NotifierService,
    private endPoints : ApiEndPointsService,private authService: AuthService,private departmentService: DepartmentService,) {
      this.userId = localStorage.getItem('userId');
      this.emptyId = this.endPoints.constants.emptyId;
      this.activeQuestionId = this.emptyId;
      this.survey = {
        id : this.emptyId,
        title : '',
        description : '',
        type : '',
        status : 'Pending',
        createdAt : new Date,
        updatedAt : new Date,
        departmentId: this.emptyId
      };

      this.surveyQuestion = {
        id : this.emptyId,
        surveyId : this.survey.id,
        type : '',
        question : '',
        createdAt : new Date,
        updatedAt : new Date
      };

      this.option = {
        id : this.emptyId,
        option : '',
        questionId : this.emptyId,
        createdAt : new Date,
        updatedAt : new Date
      };
    }

  ngOnInit() {
    this.getDepartments();
    this.getReviewOptions();
    

  }
 

  saveSurvey(){
    this.showLoader = true;
    this.surveyService.saveSurvey(this.survey).subscribe(data=>{
      if(data.success){
        this.notifier.notify("success", data.message);
        this.survey = data.data;
        this.showLoader = false;
        return;
    }
    this.showLoader = false;
    this.notifier.notify('error', data.message);
    });
  }

  saveQuestion(){
    this.showLoader = true;
    let newSQuestion = this.surveyQuestion;
    newSQuestion.surveyId = this.survey.id;
    newSQuestion.question = this.newQuiz;
    newSQuestion.type = this.activeType;
    this.surveyService.saveQuestion(this.surveyQuestion).subscribe(
      data=>{
        if(data.success){
          this.notifier.notify("success", data.message);
          this.newQuiz='';
          this.activeType = '';
          let quizView: IQuestionView={
            question: data.data,
            options: []
          };
          this.questions.push(quizView);
          this.showLoader = false;
          return;
        }
        this.showLoader = false;
      }
    );
  }

  saveOption(){
    this.showLoader = true;
    let optionSave = this.option;
    optionSave.option = this.newOption;
    optionSave.questionId = this.activeQuestionId;
    this.surveyService.saveOption(optionSave).subscribe(
      data=>{
        if(data.success){
          this.notifier.notify("success", data.message);
          this.questions.find(x=>x.question.id == this.activeQuestionId).options.push(data.data);
          this.newOption = '';
          this.showLoader = false;
        }
        this.showLoader = false;
      }
    );
  }

  setActiveQuestion(id: string){
    this.activeQuestionId = id;
  }
 
  
  getDepartments() {
    this.departmentService.getDepartments().subscribe((data) => {
      if (data.success) {
        this.departments = data.data;
      } else {
      }
    });
  }
  getReviewOptions(){
 
    this.otherService.getReviewOptions().subscribe(data => {
      if (data.success) {
        this.reviews = data.data;

      }else{
        this.notifier.notify('error', data.message);
      }

    });
  }


   


}
