import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TicketService } from "src/app/services/ticket.service";
import { OtherService } from "src/app/services/other.service";
import { IOwnerType } from "src/app/common/interfaces/OwnerType";
import { ITicket } from "../interfaces/Ticket";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { NotifierService } from "angular-notifier";
import { ISource } from "src/app/setting/interfaces/Source";
import { LogService } from "src/app/services/log.service";
import { AuthService } from "src/app/services/auth.service";
import { IEmployee } from "src/app/employee/interfaces/Employee";

@Component({
  selector: "app-edit-ticket",
  templateUrl: "./edit-ticket.component.html",
  styleUrls: ["./edit-ticket.component.css"],
})
export class EditTicketComponent implements OnInit {
  customerId: string = "";
  employeeId:string="";
  ticketId: string = "";
  userId: string = "";
  loginUser: IEmployee;
  showLoader: boolean;
  sources: ISource[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ticketService: TicketService,
    private logger: LogService,
    private otherService: OtherService,
    private endPoints: ApiEndPointsService,
    private authService: AuthService,
    private notifier: NotifierService
  ) {
    this.userId = this.userId = localStorage.getItem("userId");
    this.ticketId = this.route.snapshot.paramMap.get("id");
  }
  types: IOwnerType[] = [];
  ticket: ITicket = {
    id: this.endPoints.constants.emptyId,
    title: "",
    description: "",
    closed: false,
    completionPercentage: 0,
    creator: this.userId,
    customerId: this.customerId,
    employeeId:this.employeeId,
    typeId: this.endPoints.constants.emptyId,
    createdAt: new Date(),
    updatedAt: new Date(),
    status: "",
    reason: "",
    source: "",
  };
  ngOnInit() {
    this.customerId = this.route.snapshot.paramMap.get("id");
    this.employeeId=this.route.snapshot.paramMap.get("id");
    this.authService.getUser().subscribe((data) => {
      this.loginUser = data;
      console.log(this.loginUser)
    });
    this.getTicketTypes();
    this.getTicketById();
    this.getSources();
  }

  getTicketById() {
    this.ticketService.getTicket(this.ticketId).subscribe((data) => {
      if (data.success) {
        this.ticket = data.data.ticket;
        console.log(this.ticket)


        return;
      }

      this.logger.saveLog(
        this.loginUser.id,
        "Failed to fetch ticket by id",
        "Error"
      );
    });
  }

  getTicketTypes() {
    this.otherService.getAllTypes().subscribe((data) => {
      if (data.success) {
        this.types = data.data;

        return;
      }

      this.logger.saveLog(
        this.loginUser.id,
        "Failed to fetch ticket types",
        "Error"
      );
    });
  }

  getSources() {
    this.otherService.getSources().subscribe((data) => {
      if (data.success) {
        this.sources = data.data;

        return;
      }

      this.logger.saveLog(
        this.loginUser.id,
        "Failed to fetch sources",
        "Error"
      );
    });
  }
  editTicket() {
    this.showLoader = true;
    this.ticket.id = this.ticketId;
    this.ticketService.editTicket(this.ticket).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        let message = "Edited " + data.data.title + " ticket";
        this.logger.saveLog(this.loginUser.id, message, "Info");
        this.showLoader = false;
        this.router.navigate(["/tickets"]);
        return;
      } else {
        this.logger.saveLog(
          this.loginUser.id,
          "Editing of ticket details failed",
          "Error"
        );
        this.notifier.notify("error", data.message);
        this.showLoader = false;
      }
    });
  }
}
