import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ApiEndPointsService } from "../common/api-end-points.service";
import { IEmployee } from "../employee/interfaces/Employee";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  baseUrl: string;
  constructor(
    private http: HttpClient,
    private endPoints: ApiEndPointsService,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.baseUrl = baseUrl;
  }

  getEmployees(): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.employee.getemployees)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  getEmployee(id: string): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.employee.getemployeedetails + id)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  addEmployee(employee: IEmployee): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.employee.addemployee, employee)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  addEmployeeByNo(employee: any): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.employee.addemployeebyno, employee)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  editEmployee(employee: IEmployee): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.employee.editemployee, employee)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
