<main>
  <div class="container">

    <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
      <circle cx="170" cy="170" r="160" stroke="#0e863d" />
      <circle cx="170" cy="170" r="135" stroke="#1c2166" />
      <circle cx="170" cy="170" r="110" stroke="#0e863d" />
      <circle cx="170" cy="170" r="85" stroke="#1c2166" />
    </svg>
    <h6 class="text-primary">Loading...</h6>
  </div>
</main>
