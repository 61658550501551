import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IDashboard } from "src/app/dashboard/interfaces/Dashboard";
import { DashboardService } from "src/app/services/dashbord.service";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import { Chart } from "chart.js";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild("myLineChartClosedTickets")
  myBarChart!: ElementRef<HTMLCanvasElement>;
  @ViewChild("myBarTicketComp")
  myBarTicketComp!: ElementRef<HTMLCanvasElement>;
  chart!: Chart;
  user: any;

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private notifier: NotifierService,
    private router: Router
  ) {}
  dashboard: IDashboard;

  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;
      console.log(this.user)
      this.getDashboard(this.user.id, this.user.designation);
    });
    // this.getTotalTicketClosedPerDay();
  }

  getDashboard(id: string, origin:string) {
    this.dashboardService.getDashboard(id, origin).subscribe((data) => {
      if (data.success) {
        console.log(data)
        this.dashboard = data.data;
      }
    });
  }

  closedLabes = [];
  closedData = [];
  closedTicketCompLabes = [];
  closedTicketCompData = [];

  ngAfterViewInit() {
    setTimeout(()=>{
    //this is for closed tickets per day
    this.dashboardService.getTotalTicketClosedPerDay(this.user.id, this.user.designation).subscribe((data) => {
      if (data.success) {
        //line chart for closed tasks
        data.data.forEach((x) => {
          this.closedData.push(x.count);
          this.closedLabes.push(x.day);
        });
        //start here


        this.chart = new Chart(this.myBarChart.nativeElement, {
          type: "line",
          data: {
            labels: this.closedLabes,
            datasets: [
              {
                label: "Closed Tickets Per Day",
                backgroundColor: "rgba(2,117,216,1)",
                borderColor: "rgba(2,117,216,1)",
                data: this.closedData,
              },
            ],
          },
          options: {
            scales: {
              xAxes: [
                {
                  time: {
                    unit: "day",
                  },
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    maxTicksLimit: 6,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    min: 0,
                    max: 25,
                    maxTicksLimit: 5,
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
          },
        });

        //ends here
      }
    });
    //ends here

    //ticket comp starts here
    this.dashboardService.getTicketComp(this.user.id, this.user.designation).subscribe((data) => {
      if (data.success) {
        //line chart for closed tasks
        data.data.forEach((x) => {
          this.closedTicketCompData.push(x.count);
          this.closedTicketCompLabes.push(x.name);
        });
        //start here
        this.chart = new Chart(this.myBarTicketComp.nativeElement, {
          type: "bar",
          data: {
            labels: this.closedTicketCompLabes,
            datasets: [
              {
                label: "Closed/Open Comparison",
                backgroundColor: "rgba(2,117,216,1)",
                borderColor: "rgba(2,117,216,1)",
                data: this.closedTicketCompData,
              },
            ],
          },
          options: {
            scales: {
              xAxes: [
                {
                  time: {
                    unit: "day",
                  },
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    maxTicksLimit: 6,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    min: 0,
                    max: 50,
                    maxTicksLimit: 5,
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
          },
        });

        //ends here
      }
    });
  },1000);
    //ends here
  }
}
