import { Component, OnInit } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { AuthService } from "src/app/services/auth.service";
import { SurveyService } from "src/app/services/survey.service";
import { ISurveyPreview } from "../interfaces/SurveyPreview";

@Component({
  selector: 'app-customer-survey',
  templateUrl: './customer-survey.component.html',
  styleUrls: ['./customer-survey.component.css']
})
export class CustomerSurveyComponent implements OnInit {
  surveys: ISurveyPreview[] = [];

  showLoader: boolean;
  activeId: string;
  status: string;
  

  constructor(
    private surveyService: SurveyService,
    private authService: AuthService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.getSurveys();
  }

  
  getSurveys() {
    this.showLoader = true;
    this.surveyService.getSurveys().subscribe(
      (data) => {
        if (data.success) {
          this.surveys = data.data;
          this.surveys = this.surveys.filter(
            (x) => x.survey.type == "External" 
          );
        }
        this.showLoader = false;
      },
      (error) => console.log(<any>error)
    );
  }

  setActiveId(id: string) {
    this.activeId = id;
  }
}
