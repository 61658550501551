import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError} from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { ApiEndPointsService } from '../common/api-end-points.service';
import { ISmtpSetting } from '../setting/interfaces/SmtpSetting';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  baseUrl: string;
  constructor(private http : HttpClient, private endPoints: ApiEndPointsService, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getAllSmtpSettings() : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.setting.getallsmtpsettings)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  getSmtpSettings() : Observable<any>{
    return this.http.get(this.baseUrl+this.endPoints.setting.smtpsettings)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  addSmtpSettings(smtp : ISmtpSetting) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.setting.addsmtpsetting, smtp)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  editSmtpSettings(smtp : ISmtpSetting) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.setting.editSmtpSetting, smtp)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  deleteTypeSetting(id : string) : Observable<any>{
    return this.http.delete(this.baseUrl+this.endPoints.setting.deleteTypeSetting+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  deleteSmtpSetting(id : string) : Observable<any>{
    return this.http.delete(this.baseUrl+this.endPoints.setting.deleteSmtpSetting+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
