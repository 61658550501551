import { Component, OnInit } from "@angular/core";
import { EmployeeService } from "src/app/services/employee.service";
import { IEmployee } from "../interfaces/Employee";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { DepartmentService } from "src/app/services/department.service";
import { IDepartment } from "src/app/department/interfaces/Department";
import { NotifierService } from "angular-notifier";
import { ActivatedRoute, Router } from "@angular/router";
import { LogService } from "src/app/services/log.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-edit-employee",
  templateUrl: "./edit-employee.component.html",
  styleUrls: ["./edit-employee.component.css"],
})
export class EditEmployeeComponent implements OnInit {
  employeeId: string;
  loginUser: IEmployee;
  emptyId: string = "";
  constructor(
    private route: ActivatedRoute,
    private empService: EmployeeService,
    private endPoints: ApiEndPointsService,
    private departmentService: DepartmentService,
    private notifier: NotifierService,
    private router: Router,
    private logger: LogService,
    private authService: AuthService
  ) {
    this.employeeId = this.route.snapshot.paramMap.get("id");
    this.emptyId = this.endPoints.constants.emptyId;
    this.authService.getUser().subscribe((data) => {
      this.loginUser = data;
    });
  }
  employee: IEmployee = {
    id: this.emptyId,
    createdAt: new Date(),
    updatedAt: new Date(),
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    password: "1234emp",
    identity: "",
    departmentId: this.emptyId,
    creator: this.emptyId,
    admin: 0,
    escalated: false,
  };
  departments: IDepartment[] = [];
  ngOnInit() {
    this.getDepartments();
    this.getEmployeeById();
  }

  editEmployee() {
    this.employee.id = this.employeeId;
    if (
      !this.employee.departmentId ||
      this.employee.departmentId == "" ||
      this.employee.departmentId == null
    ) {
      this.notifier.notify("error", "Select Employee Department");
      return;
    }

    this.empService.editEmployee(this.employee).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        let message =
          "Edited " +
          data.data.firstName +
          " " +
          data.data.lastName +
          " employee details.";
        this.logger.saveLog(this.loginUser.id, message, "Info");
        this.router.navigate(["employees"]);

        return;
      }

      let message = "Editing of employee details failed";
      this.logger.saveLog(this.loginUser.id, message, "Error");
      this.notifier.notify("error", data.message);
    });
  }

  getDepartments() {
    this.departmentService.getDepartments().subscribe((data) => {
      if (data.success) {
        this.departments = data.data;

        return;
      }

      this.logger.saveLog(
        this.loginUser.id,
        "Failed to fetch departments",
        "Error"
      );
    });
  }

  getEmployeeById() {
    this.empService.getEmployee(this.employeeId).subscribe((data) => {
      if (data.success) {
        this.employee = data.data;
      }
    });
  }
}
