import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiEndPointsService } from "../common/api-end-points.service";
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FaqService {
  baseUrl: string;
  constructor(
    private http: HttpClient,
    private endPoints: ApiEndPointsService,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.baseUrl = baseUrl;
  }

  getFaqs(type: string): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.faq.getfaqs+type)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  getFaq(id: string): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.faq.getfaqdetails + id)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addFaq(faq: any): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.faq.addFaq, faq)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  editFaq(faq: any): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.faq.editfaq, faq)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  deleteFaq(id : string) : Observable<any>{
    return this.http.delete(this.baseUrl+this.endPoints.faq.deleteFaq+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
