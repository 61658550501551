import { Component, OnInit } from '@angular/core';
import { ISurveyDetail } from '../survey/interfaces/SurveyDetail';
import { SurveyService } from '../services/survey.service';
import { IResponse } from '../survey/interfaces/QuestionResponse';
import { ApiEndPointsService } from '../common/api-end-points.service';
import { NotifierService } from 'angular-notifier';
import { IResponseView } from '../survey/interfaces/ResponseView';
import {IReviewOptions} from "src/app/common/interfaces/ReviewOptions";
import { OtherService } from 'src/app/services/other.service';
import { AuthService } from "src/app/services/auth.service";


@Component({
  selector: 'app-user-survey',
  templateUrl: './user-survey.component.html',
  styleUrls: ['./user-survey.component.css']
})
export class UserSurveyComponent implements OnInit {
  surveyDetail: ISurveyDetail;
  responses: IResponse[] = [];
  reviews: IReviewOptions[]=[];
  surveyId : string;
  emptyId: string;
  loginUser: any;
  hasConducted: boolean
  constructor(private surveyService: SurveyService,private authService: AuthService, private otherService: OtherService, private endPoints: ApiEndPointsService, private notifier: NotifierService) { 
    this.emptyId = endPoints.constants.emptyId;

    this.emptyId = this.endPoints.constants.emptyId;
    this.authService.getUser().subscribe((data) => {
      this.loginUser = data;
      console.log(this.loginUser);
    });
  }

  ngOnInit() {
    this.getActiveSurvey(); 
    this.getReviewOptions();
  }
  getReviewOptions(){
 
    this.otherService.getReviewOptions().subscribe(data => {
      if (data.success) {
        this.reviews = data.data;

      }else{
        this.notifier.notify('error', data.message);
      }

    });
  }

  getActiveSurvey(){
    this.surveyService.getActiveSurvey().subscribe(data=>{
      if(data.success){
        this.surveyDetail = data.data;
      }
    });
  }

  addRemoveResponseChoice(optionId : string, questionId: string, e){
    if(e.target.checked){
      let optionCheck: IResponse = {
        id: this.emptyId,
        questionId: questionId,
        optionId: optionId,
        createdAt: new Date,
        updatedAt: new Date,
        customerId: this.loginUser.type == "Customer" ? this.loginUser.id : this.emptyId,
        employeeId: this.loginUser.type == "Employee" ? this.loginUser.id : this.emptyId,
        openResponse: this.emptyId
      };

      this.responses.push(optionCheck);
    }
    if(!e.target.checked){
     this.responses = this.responses.filter(x=>x.questionId != questionId && x.optionId !=optionId);
    }

  }
  addResponse(questionId:string, openResponse:string){
    if(openResponse.length >=1){
      let openRes: IResponse = {
        id: this.emptyId,
        questionId: questionId,
        optionId: this.emptyId,
        createdAt: new Date,
        updatedAt: new Date,
        customerId: this.loginUser.type == "Customer" ? this.loginUser.id : this.emptyId,
        employeeId: this.loginUser.type == "Employee" ? this.loginUser.id : this.emptyId,
        openResponse :openResponse 
      };
     console.log(openRes);
      this.responses.push(openRes);
    }
    else if(openResponse.length <1){
      this.responses= this.responses.filter(x=>x.questionId !=questionId && x.openResponse !=openResponse)

    }

  }

  submitSurvey(){
    if(this.responses.length == 0){
      this.notifier.notify('error', 'Please fill the survey first before submit.');
      return;
    }
    let resp: IResponseView = {
      responses: this.responses
    };
    
    this.surveyService.saveResponses(resp).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
        this.surveyDetail = null;
        return;
      }
      this.notifier.notify('error', data.message);
    });
  }
  hasConductedSurvey(){
    let has = {
      "id": this.loginUser.id,
      "type": this.loginUser.type,
      "surveyId": this.surveyId
    };
    this.surveyService.conductedSurvey(has).subscribe(data=>{
      console.log(data);
      if(data.success){
        this.hasConducted = data.data;
      }
    });
  }

}
