<div class="row">
    <div class="col-md-12">
        <notifier-container></notifier-container>

        <div class="card">
            <div class="card-header border-bottom">
                <ul class="nav nav-tabs card-header-tabs" id="cardTab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="smtp-tab" href="#smtp-settings" data-toggle="tab" role="tab"
                        aria-controls="overview" aria-selected="true">Email Settings</a></li>
                    <li class="nav-item"><a class="nav-link" id="add-smtp-tab" href="#add-smtp" data-toggle="tab" role="tab"
                         aria-controls="example" aria-selected="false">Add Email Setting</a></li>

                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="cardTabContent">
                    <div class="tab-pane fade show active" id="smtp-settings" role="tabpanel" aria-labelledby="smtp-tab">
                        <div class="sb-datatable table-responsive">
                            <table class="table table-bordered table-hover display" datatable width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Server</th>
                                        <th>UserName</th>
                                        <th>Password</th>
                                        <th>Port</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let setting of smtpSettings;index as i">
                                        <td>{{i+1}}</td>
                                        <td>{{setting.server}}</td>
                                        <td>{{setting.userName}}</td>
                                        <td>
                                            <input type="password" disabled readonly  value="{{setting.password}}">
                                        </td>
                                        <td>
                                            <div class="badge badge-primary badge-pill">{{setting.port}}</div>
                                        </td>
                                        <td>
                                            <button class="btn sb-btn-datatable sb-btn-icon sb-btn-outline-danger mr-2" (click)="deleteSmtpSetting(setting.id)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="add-smtp" role="tabpanel" aria-labelledby="add-smtp-tab">
                      <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                              <div class="form-row">
                                  <div class="col-md-6">
                                      <div class="form-group"><label class="small blm mb-1" for="server">Server Name</label>
                                          <input class="form-control" id="server" name="server" [(ngModel)]="smtpSetting.server" type="text"  placeholder="Enter Server Name" />
                                      </div>
                                  </div>
                              </div>
                              <div class="form-row">
                                <div class="col-md-6">
                                  <div class="form-group"><label class="small blm mb-1" for="userName">Username</label>
                                      <input class="form-control" id="userName" name="userName" [(ngModel)]="smtpSetting.userName" type="text"  placeholder="Enter Username" />
                                  </div>
                              </div>
                              </div>
                              <div class="form-row">
                                  <div class="col-md-6">
                                      <div class="form-group"><label class="small blm mb-1" for="password">Password</label>
                                          <input class="form-control" id="password" name="password" [(ngModel)]="smtpSetting.password" type="password"  placeholder="Enter Password" />
                                      </div>
                                  </div>
                              </div>
                              <div class="form-row">
                                <div class="col-md-6">
                                  <div class="form-group"><label class="small blm mb-1" for="port">Port</label>
                                      <input class="form-control" id="port" name="port" [(ngModel)]="smtpSetting.port" type="number"  placeholder="Enter port" />
                                  </div>
                              </div>
                              </div>
                              <div class="form-row">
                                  <div class="col-md-6">
                              <div class="form-group mt-4 mb-0">
                                  <button class="btn btn-primary btn-block" (click)="saveSmtpSetting()">Create Email Setting</button>
                              </div>
                              </div>
                              </div>
                        </div>
                      </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
