import { Component } from "@angular/core";
import { ILogin } from "./login/interfaces/Login";
import { AuthService } from "./services/auth.service";
import { NotifierService } from "angular-notifier";
declare var $: any;
import "datatables.net";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "Support Management";
  showLoader: boolean;
  loggedin: boolean = false;
  user: any;
  redirectUrl = "https://registration.ngobureau.go.ke/#/index";
  login: ILogin = {
    email: "",
    password: "",
  };
  constructor(
    private notifier: NotifierService
  ) {}
  ngOnInit() {

  }




  logout() {
    //this.notifier.notify("success", "You have been looged out");
    window.location.href = this.redirectUrl;
  }
}
