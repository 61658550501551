import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { IOwnerType } from 'src/app/common/interfaces/OwnerType';
import { ExportService } from 'src/app/services/export.service';
import { OtherService } from 'src/app/services/other.service';
import { ReportService } from 'src/app/services/report.service';


@Component({
  selector: 'app-it-helpdesk-report',
  templateUrl: './it-helpdesk-report.component.html',
  styleUrls: ['./it-helpdesk-report.component.css']
})
export class ItHelpdeskReportComponent implements OnInit {

  emptyId: string;
  tickets: any[] = [];
  types : IOwnerType[] = [];
  startDate : Date;
  endDate : Date;
  type : string;
  showLoader: boolean;
  exportData: any[] =[];

  constructor(private otherService : OtherService, private endPoints : ApiEndPointsService,
     private notifier : NotifierService, private reportService: ReportService, private exportService: ExportService) {
      this.emptyId = this.endPoints.constants.emptyId;
      this.type = "";
      }
     
  ngOnInit() {
    this.getTicketTypes();
  }

  getTicketTypes(){
    this.otherService.getTypes("IT").subscribe(data=>{
      if(data.success){
        this.types = data.data;
      }
    });
  }

  getTickets(){
    this.showLoader =true;
    this.reportService.getTickets({startDate: this.startDate, endDate: this.endDate, type: this.type, origin:"IT"}).subscribe(data=>{
      if(data.success){
        this.tickets = data.data;
        var counter = 1;
        this.tickets.forEach(element=>{
          var styled = {
            "S/NO" : counter,
            "Date Received": element.ticket.createdAt,
            "Complain Channel": element.ticket.source,
            "Name of Complainant": element.employee.firstName + element.employee.lastName,
            "Complaint Issue": element.ticket.description,
            "Corrective Action": element.ticket.reason,
            "Status": element.status,
          };counter++
          this.exportData.push(styled);
        });
        this.showLoader = false;
        this.notifier.notify('success', data.message);
        return;
        }
        this.showLoader = false;
        this.notifier.notify('error', data.message);
        return;
    });
  }

  export() {
    console.log(this.tickets)
    this.exportService.exportExcel(this.exportData, 'Request Reports from_'+this.startDate+'_to_'+this.endDate);
  }



}
