import { Component, OnInit } from '@angular/core';
import { ICounty } from "src/app/common/interfaces/County"
import { ISubCounty } from "src/app//common/interfaces/SubCounty";
import { COUNTYS } from "src/app/common/interfaces/CountyData";
import { IUserPrivilege } from "src/app/common/interfaces/UserPrivilege";
import { AuthService } from "src/app/services/auth.service";
import { TicketService } from "src/app/services/ticket.service";
import { ITicket } from "src/app/ticket/interfaces/Ticket";
import { ICustomer } from "src/app/customer/interfaces/Customer";
import { CustomerService } from "src/app/services/customer.service";
import { NotifierService } from "angular-notifier";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { IOwnerType } from "src/app/common/interfaces/OwnerType";
import { OtherService } from "src/app/services/other.service";
import { Observable, of } from "rxjs";
import { IEmployee } from 'src/app/employee/interfaces/Employee';
import { EmployeeService } from 'src/app/services/employee.service';





@Component({
  selector: 'app-it-helpdesk',
  templateUrl: './it-helpdesk.component.html',
  styleUrls: ['./it-helpdesk.component.css']
})
export class ItHelpdeskComponent implements OnInit {
  canraise: boolean;
  canViewAll: boolean;
  canviewdetails: boolean;
  canedit: boolean;
  canaddcustomer: boolean;
  isBeneficiary: boolean;
  countydata: ICounty[];
  subcountySelection: ISubCounty[] = [];
  user: any;
  search_key: string;
  create_new: boolean = false;
  tickets: any[] = [];
  errors: string[] = [];
  customers: ICustomer[] = [];
  emptyId: string = null;
  showLoader: boolean;
  new_employee: IEmployee = {
    id: this.emptyId,
    createdAt: new Date(),
    updatedAt: new Date(),
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    departmentId:"",
    creator:"",
    admin:0,
    escalated:false,
    address: "",
    password: "1234cust",
    identity: "",
  };

  employeeTypes: IOwnerType[] = [];
  constructor(
    private ticketService: TicketService,
    private employeeService: EmployeeService,
    private notifier: NotifierService,
    private endPoints: ApiEndPointsService,
    private otherService: OtherService,
    private authService: AuthService
  ) {
    this.emptyId = this.endPoints.constants.emptyId;
    this.new_employee.id = this.emptyId;
  }
  ngOnInit(): void {
    this.authService.getUser().subscribe((data) => {
      this.user = data;
      console.log(this.user )

    });
    this.getTickets();
    this.getEmployeeTypes();

  }
  getTickets() {
    this.showLoader = true;
    if (this.user.isAdmin == 1 || this.canViewAll) {
      this.ticketService.getTickets('IT').subscribe(
        (data) => {
          if (data.success) {
            console.log(data)

            this.tickets = data.data;
          }
          this.showLoader = false;
        },
        (error) => console.log(<any>error)
      );
    }

    if (this.user.isAdmin == 0) {
      this.ticketService.getUserTickets(this.user.id,'IT').subscribe(
        (data) => {
          if (data.success) {
            this.tickets = data.data;
          }
          this.showLoader = false;
        },
        (error) => console.log(<any>error)
      );
    }

}

getEmployeeTypes() {
  this.otherService.getTypes("Employee").subscribe((data) => {
    if (data.success) {
      this.employeeTypes = data.data;
    }
  });
}

saveEmployee() {
  this.validator(this.new_employee).subscribe((data) => {
    if (data.length > 0) {
      return;
    } else {
      this.showLoader = true;
      this.employeeService
        .addEmployee(this.new_employee)
        .subscribe((data) => {
          if (data.success) {
            this.notifier.notify("success", data.message);
            this.customers.push(data.data);
            this.create_new = false;
            this.showLoader = false;
            return;
          }
          this.showLoader = false;
          this.notifier.notify("error", data.message);
        });
    }
  });
}
validator(employee: IEmployee): Observable<string[]> {

  if (
    !employee.firstName ||
    employee.firstName == "" ||
    employee.firstName == null
  ) {
    this.errors.push("First name of employee is required");
  }
  if (
    !employee.lastName ||
    employee.lastName == "" ||
    employee.lastName == null
  ) {
    this.errors.push("Last name of employee is required");
  }
  if (!employee.email || employee.email == "" || employee.email == null) {
    this.errors.push("Email of employee is required");
  }
  if (!employee.phone || employee.phone == "" || employee.phone == null) {
    this.errors.push("Phone number of employee is required");
  }
  return of(this.errors);
}

}

