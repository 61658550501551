import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { IOwnerType } from 'src/app/common/interfaces/OwnerType';
import { ICustomer } from 'src/app/customer/interfaces/Customer';
import { ExportService } from 'src/app/services/export.service';
import { OtherService } from 'src/app/services/other.service';
import { ReportService } from 'src/app/services/report.service';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.css']
})
export class CustomerReportComponent implements OnInit {

  emptyId: string;
  customers: ICustomer[] = [];
  types : IOwnerType[] = [];
  startDate : Date; 
  endDate : Date;
  type : string;
  showLoader: boolean;
  exportData: any[] =[];

  constructor(private otherService : OtherService, private endPoints : ApiEndPointsService,
     private notifier : NotifierService, private reportService: ReportService, private exportService: ExportService) {
      this.emptyId = this.endPoints.constants.emptyId;
      this.type = "";
      }

  ngOnInit() {
    this.getTicketTypes();
  }

  getTicketTypes(){
    this.otherService.getTypes("Customer").subscribe(data=>{
      if(data.success){
        this.types = data.data;
      }
    });
  }

  getCustomers(){
    this.showLoader =true;
    this.reportService.getCustomers({startDate: this.startDate, endDate: this.endDate, type: this.type}).subscribe(data=>{
      if(data.success){
        this.customers = data.data;
        var counter = 1;
        this.customers.forEach(element=>{
          var styled = {
            "S/NO" : counter,
            "Name": element.firstName+element.lastName,
            "Phone": element.phone,
            "Email": element.email,
            "ID/Passport": element.identity,
            "Arn NO": element.arn,
            "Address": element.address,
            "Created On": element.createdAt,
          };counter++
          this.exportData.push(styled);
        });
        this.showLoader = false;
        this.notifier.notify('success', data.message);
        return;
        }
        this.showLoader = false;
        this.notifier.notify('error', data.message);
        return;
    });
  }

  export() {
    this.exportService.exportExcel(this.exportData, 'Customers '+this.startDate+'_to_'+this.endDate);
  }

}
