import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndPointsService } from '../common/api-end-points.service';
import { Observable} from 'rxjs';
import { tap, catchError} from 'rxjs/operators';
import { ITicket } from '../ticket/interfaces/Ticket';
import { IUserAssigenee } from '../common/interfaces/ListObject';
import { IStatus } from '../common/interfaces/Status';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
baseUrl: string;
  constructor(private http: HttpClient, private endPoints: ApiEndPointsService, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
   }

  getTickets(origin: string) : Observable<any> {
    return this.http.get(this.baseUrl+this.endPoints.tickets.getalltickets+origin).pipe(tap(),
     catchError(this.endPoints.handleError));
  }
  getTicket(id : string) : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.tickets.getticketdetails+id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  addTicket(ticket : ITicket) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.tickets.addticket, ticket)
    .pipe(tap(), catchError(this.endPoints.handleError))
  }

  editTicket(ticket : ITicket) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.tickets.editticket, ticket)
    .pipe(tap(), catchError(this.endPoints.handleError))
  }

  getTicketUsers(id : string) : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.tickets.getticketusers +id)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
  getUserTickets(id : string, origin:string) : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.tickets.getusertickets+id+"/"+origin)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  changeStatus(status : IStatus) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.tickets.changestatus, status)
    .pipe(tap(), catchError(this.endPoints.handleError))
  }
  addTicketUsers(userAssignees : IUserAssigenee) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.tickets.addticketusers, userAssignees)
    .pipe(tap(), catchError(this.endPoints.handleError))
  }

  removeTicketUser(ticketId : string, employeeId : string) : Observable<any>{
    return this.http.post(this.baseUrl + this.endPoints.tickets.removeticketuser, {ticketId : ticketId, employeeId : employeeId})
    .pipe(tap(), catchError(this.endPoints.handleError))
  }

  changeState(id: string, state : boolean, reason: string, notify: boolean) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.tickets.openclose, {id: id, status: state, reason: reason, notifyCustomer: notify})
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  sendMessage(id: string, subject: string ,message: string, medium: string) : Observable<any>{
    return this.http.post(this.baseUrl+this.endPoints.tickets.sendmessage, {id: id, subject: subject, message: message, medium: medium})
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
}
