import { Component, OnInit } from "@angular/core";
import { CustomerService } from "src/app/services/customer.service";
import { ICustomer } from "../interfaces/Customer";
import { NotifierService } from "angular-notifier";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { IOwnerType } from "src/app/common/interfaces/OwnerType";
import { OtherService } from "src/app/services/other.service";
import { ICounty } from "src/app/common/interfaces/County";
import { ISubCounty } from "src/app/common/interfaces/SubCounty";
import { COUNTYS } from "src/app/common/interfaces/CountyData";
import { LogService } from "src/app/services/log.service";
import { IEmployee } from "src/app/employee/interfaces/Employee";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-edit-customer",
  templateUrl: "./edit-customer.component.html",
  styleUrls: ["./edit-customer.component.css"],
})
export class EditCustomerComponent implements OnInit {
  loginUser: IEmployee;
  customerId: string;
  showLoader: boolean;
  countydata: ICounty[];
  emptyId: string = "";
  subcountySelection: ISubCounty[] = [];
  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private notifier: NotifierService,
    private router: Router,
    private endPoints: ApiEndPointsService,
    private otherService: OtherService,
    private logger: LogService,
    private authService: AuthService
  ) {
    this.customerId = this.route.snapshot.paramMap.get("id");
    this.emptyId = this.endPoints.constants.emptyId;
    this.authService.getUser().subscribe((data) => {
      this.loginUser = data;
    });
  }
  customer: ICustomer = {
    id: this.emptyId,
    createdAt: new Date(),
    updatedAt: new Date(),
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phone2: "",
    county: "",
    address: "",
    subCounty: "",
    password: "1234cust",
    identity: "",
    typeId: this.emptyId,
    arn: "",
  };
  customerTypes: IOwnerType[] = [];
  ngOnInit() {
    this.countydata = COUNTYS;
    this.getCustomerTypes();
    this.getCustomerById();
  }

  editCustomer() {
    this.customer.id = this.customerId;
    if (
      !this.customer.typeId ||
      this.customer.typeId == "" ||
      this.customer.typeId == null
    ) {
      this.notifier.notify("error", "Select Customer Type First");
      return;
    }
    this.showLoader = true;
    this.customerService.editCustomer(this.customer).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);

        let message =
          "Edited customer " +
          data.data.firstName +
          " " +
          data.data.lastName +
          " details";
        this.logger.saveLog(this.loginUser.id, message, "Info");
        this.showLoader = false;
        this.router.navigate(["customers"]);
        return;
      }
      this.showLoader = false;
      this.logger.saveLog(
        this.loginUser.id,
        "Editing of customer details failed",
        "Error"
      );
      this.notifier.notify("error", data.message);
    });
  }

  getCustomerTypes() {
    this.otherService.getTypes("Customer").subscribe((data) => {
      if (data.success) {
        this.customerTypes = data.data;

        return;
      }

      this.logger.saveLog(
        this.loginUser.id,
        "Failed to fetch customer types",
        "Error"
      );
    });
  }

  getCustomerById() {
    this.customerService.getCustomer(this.customerId).subscribe((data) => {
      if (data.success) {
        this.customer = data.data;
      }
    });
  }

  populateSubCounties(county: string) {
    this.subcountySelection = this.countydata.find(
      (x) => x.name == county
    ).subcounties;
  }
}
