import { Component, OnInit } from "@angular/core";
import { IDepartment } from "../interfaces/Department";
import { DepartmentService } from "src/app/services/department.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { AuthService } from "src/app/services/auth.service";
import { LogService } from "src/app/services/log.service";
import { IEmployee } from "src/app/employee/interfaces/Employee";

@Component({
  selector: "app-edit-department",
  templateUrl: "./edit-department.component.html",
  styleUrls: ["./edit-department.component.css"],
})
export class EditDepartmentComponent implements OnInit {
  departmentId: string;
  loginUser: IEmployee;
  emptyId: string = "";
  showLoader: boolean;
  constructor(
    private route: ActivatedRoute,
    private departmentService: DepartmentService,
    private router: Router,
    private notifier: NotifierService,
    private endPointService: ApiEndPointsService,
    private authService: AuthService,
    private logger: LogService
  ) {
    this.departmentId = this.route.snapshot.paramMap.get("id");
    this.emptyId = this.endPointService.constants.emptyId;
  }
  department: IDepartment = {
    name: "",
    email: "",
    updatedAt: new Date(),
    createdAt: new Date(),
    id: this.emptyId,
    departmentHeadId: this.emptyId,
  };
  submitted: boolean;

  ngOnInit() {
    this.getDepartmentById();
  }

  getDepartmentById() {
    this.departmentService
      .getDepartment(this.departmentId)
      .subscribe((data) => {
        if (data.success) {
          this.department = data.data;
        }
      });
  }

  editDepartment() {
    this.showLoader = true;
    this.department.id = this.departmentId;
    this.departmentService.editDepartment(this.department).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.logger.saveLog(
          this.loginUser.id,
          "Edited " + data.data.name + " department details",
          "Info"
        );
        this.showLoader = false;
        this.router.navigate(["/departments"]);
        return;
      }
      this.showLoader = false;
      this.logger.saveLog(
        this.loginUser.id,
        "Editing department details failed",
        "Info"
      );
      this.notifier.notify("error", data.message);
    });
  }
}
