<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>Customers</span>
        </h1>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-n10">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header"> <a class="btn btn-outline-primary btn-sm" [routerLink]="['/add-customer']"
              *ngIf="canraise || user.isAdmin == 1" routerLinkActive="router-link-active">
              <i class="fa fa-plus"></i> Add Customer</a></div>
          <div class="card-body">
            <div class="sb-datatable table-responsive">
              <table class="table table-bordered table-hover" datatable width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Id/Passport No</th>
                    <th>Address</th>
                    <th>Created On</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let customer of customers;index as i">
                    <td>{{i+1}}</td>
                    <td>{{customer.firstName}} {{customer.lastName}}</td>
                    <td>{{customer.phone}}</td>
                    <td>{{customer.email}}</td>
                    <td>{{customer.identity}}</td>
                    <td>{{customer.address}}</td>
                    <td>
                      <div class="badge badge-primary badge-pill">{{customer.createdAt | date:'medium'}}</div>
                    </td>
                    <td>
                      <a class="badge badge-primary" [routerLink]="['/customer-details', customer?.id]"
                        *ngIf="canviewdetails || user.isAdmin == 1">
                        <i class="fa fa-eye"></i>
                      </a>|
                      <a class="badge badge-warning" [routerLink]="['/edit-customer', customer?.id]"
                        *ngIf="canedit || user.isAdmin == 1">
                        <i class="fa fa-edit"></i>
                      </a> |
                      <a class="badge badge-success" [routerLink]="['/add-ticket', customer?.id]"
                        *ngIf="canraiseticket || user.isAdmin == 1">
                        <i class="fa fa-ticket-alt"></i> Raise
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</div>
</div>
</div>
