import { Component, OnInit } from "@angular/core";
import { EmployeeService } from "../services/employee.service";
import { NotifierService } from "angular-notifier";
import { IEmployee } from "../employee/interfaces/Employee";
import { ActivatedRoute } from "@angular/router";
import { PrivilegeService } from "../services/privilege.service";
import { IPrivilege } from "../common/interfaces/Privilege";
import { IUserPrivilege } from "../common/interfaces/UserPrivilege";
import { ApiEndPointsService } from "../common/api-end-points.service";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  emptyId: string;
  userId: string;
  password: string;
  loginUser: any;
  confirm_password: string;
  employee: IEmployee;
  privileges: IPrivilege[];
  showLoader: boolean;

  userPrivileges: IUserPrivilege[] = [];
  constructor(
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private privilegeService: PrivilegeService,
    private endPoints: ApiEndPointsService,
    private authService: AuthService
  ) {
    this.userId = localStorage.getItem("userId");
  }

  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.loginUser = data;
      console.log(this.loginUser);
      if (this.loginUser.type == "Employee") {
        this.getEmployee();
        this.getUserPrivileges();
      }
    });
  }

  getEmployee() {
    this.showLoader = true;
    this.employeeService.getEmployee(this.userId).subscribe((data) => {
      if (data.success) {
        this.employee = data.data;
      }
      this.showLoader = false;
    });
  }

  getPrivileges() {
    this.privilegeService.getPrivileges().subscribe((data) => {
      if (data.success) {
        this.privileges = data.data;
        return;
      }
    });
  }

  getUserPrivileges() {
    this.privilegeService.getUserPrivileges(this.userId).subscribe((data) => {
      console.log(data);
      if (data.success) {
        this.userPrivileges = data.data;
        this.getPrivileges();
        return;
      }
      this.getPrivileges();
    });
  }

  checkIfAssigned(privilege: string): boolean {
    if (
      this.userPrivileges.filter((x) => x.privilege == privilege).length > 0
    ) {
      return true;
    }
    return false;
  }

  resetPassword() {
    if (!this.password || !this.confirm_password) {
      this.notifier.notify(
        "error",
        "Please type a password before you submit."
      );
      return;
    }
    if (this.password !== this.confirm_password) {
      this.notifier.notify(
        "error",
        "Passwords dont match, ensure you enter the same password."
      );
      return;
    }

    this.authService
      .resetPassword(this.password, this.userId)
      .subscribe((data) => {
        if (data.success) {
          this.notifier.notify("success", data.message);
          return;
        }
        this.notifier.notify("error", data.message);
      });
  }
}
