<div class="row">
  <div class="col-md-12">
    <div class="card mb-4">
      <div class="card-header">
        <h3>Important Topics</h3>
      </div>
      <div class="card-body">
        <div class="sb-datatable table-responsive">
          <table class="table table-borderless table-hover" datatable width="100%" cellspacing="0">
            <thead>
              <tr>
                <th>Faq</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let faq of faqs;index as i">
                <td>
                  <div class="accordion" [id]="'accordion'+faq.id">
                    <div class="card">
                      <div class="card-header">
                        <a  data-toggle="collapse" [href]="'#collapseOne'+faq.id">
                          <h6>{{faq.name}}</h6>
                        </a>
                      </div>
                      <div [id]="'collapseOne'+faq.id" class="collapse" [attr.data-parent]="'#accordion'+faq.id">
                        <div class="card-body">
                          <p>{{faq.answer}}</p>
                        </div>
                      </div>
                    </div>
                  </div> 

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
