<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main>
    <notifier-container></notifier-container>

    <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-fluid">
            <div class="sb-page-header-content py-5">
                <h1 class="sb-page-header-title">
                    <div class="sb-page-header-icon"><i data-feather="droplet"></i></div>
                    <span>Customer Information</span>
                </h1>
                <div class="sb-page-header-subtitle">
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-n10">
        <app-loading *ngIf="showLoader"></app-loading>
        <div class="row" *ngIf="!showLoader">
            <div class="col-lg-9">
                <div class="card">
                    <div class="card-header">
                        Profile Details
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item"> <i class="fa fa-user"></i> Name : {{customer?.firstName}} {{customer?.lastName}}</li>
                                    <li class="list-group-item"> <i class="fa fa-envelope"></i> Email :{{customer?.email}}</li>
                                    <li class="list-group-item"> <i class="fa fa-phone"></i> Phone : {{customer?.phone}}</li>
                                    <li class="list-group-item"> <i class="fa fa-phone"></i> Alt. Phone : {{customer?.phone2}}</li>
                                    <li class="list-group-item"> <i class="fa fa-credit-card"></i> ID/Passport No : {{customer?.identity}}</li>
                                    <li class="list-group-item"> <i class="fa fa-home"></i> Address : {{customer?.address}}</li>
                                    <li class="list-group-item"> <i class="fa fa-city"></i> County : {{customer?.county}}</li>
                                    <li class="list-group-item"> <i class="fa fa-city"></i> Address : {{customer?.subCounty}}</li>
                                    <li class="list-group-item"> <i class="fa fa-clock"></i>  Created On : {{customer?.createdAt | date:'medium'}}</li>
                                  </ul>
                            </div>

                        </div>
                    </div>
                </div><br/><br/>
            </div>
            <div class="col-lg-3">

            </div>
        </div>
    </div>
</main>

</div>
</div>
</div>
