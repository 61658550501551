import { Component, OnInit } from "@angular/core";
import { EmployeeService } from "src/app/services/employee.service";
import { IEmployee } from "../interfaces/Employee";
import { IPrivilege } from "src/app/common/interfaces/Privilege";
import { IUserPrivilege } from "src/app/common/interfaces/UserPrivilege";
import { PrivilegeService } from "src/app/services/privilege.service";
import { ActivatedRoute } from "@angular/router";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { NotifierService } from "angular-notifier";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-employee-detail",
  templateUrl: "./employee-detail.component.html",
  styleUrls: ["./employee-detail.component.css"],
})
export class EmployeeDetailComponent implements OnInit {
  emptyId: string;
  userId: string;
  employee: IEmployee;
  privileges: IPrivilege[];
  loginUser: IEmployee;
  userPrivileges: IUserPrivilege[] = [];
  password: string;
  confirm_password: string;
  constructor(
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private privilegeService: PrivilegeService,
    private endPoints: ApiEndPointsService,
    private authService: AuthService
  ) {
    this.userId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.getEmployee();
    this.getUserPrivileges();
    this.authService.getUser().subscribe((data) => {
      this.loginUser = data;
      console.log(this.loginUser)
    });
  }

  getEmployee() {
    this.employeeService.getEmployee(this.userId).subscribe((data) => {
      if (data.success) {
        this.employee = data.data;
      }
    });
  }

  getPrivileges() {
    this.privilegeService.getPrivileges().subscribe((data) => {
      if (data.success) {
        this.privileges = data.data;

        return;
      }
    });
  }

  getUserPrivileges() {
    this.privilegeService.getUserPrivileges(this.userId).subscribe((data) => {
      if (data.success) {
        this.userPrivileges = data.data;
        this.getPrivileges();

        return;
      }

      this.getPrivileges();
    });
  }

  addRemovePrivilege(privilage: string, e) {
    let privilege: IUserPrivilege = {
      id: this.emptyId,
      createdAt: new Date(),
      updatedAt: new Date(),
      privilege: privilage,
      userId: this.userId,
    };
    if (e.target.checked) {
      this.privilegeService.addPrivilege(privilege).subscribe((data) => {
        if (data.success) {
          this.notifier.notify("success", data.message);
          this.getUserPrivileges();

          return;
        }

        this.getUserPrivileges();
        this.notifier.notify("error", data.message);
      });
    }
    if (!e.target.checked) {
      this.privilegeService.removePrivilege(privilege).subscribe((data) => {
        if (data.success) {
          this.notifier.notify("success", data.message);
          this.getUserPrivileges();

          return;
        }

        this.getUserPrivileges();
        this.notifier.notify("error", data.message);
      });
    }
  }

  checkIfAssigned(privilege: string): boolean {
    if (
      this.userPrivileges.filter((x) => x.privilege == privilege).length > 0
    ) {
      return true;
    }
    return false;
  }

  resetPassword() {
    if (!this.password || !this.confirm_password) {
      this.notifier.notify(
        "error",
        "Please type a password before you submit."
      );
      return;
    }
    if (this.password !== this.confirm_password) {
      this.notifier.notify(
        "error",
        "Passwords dont match, ensure you enter the same password."
      );
      return;
    }

    this.authService
      .resetPassword(this.password, this.userId)
      .subscribe((data) => {
        if (data.success) {
          this.notifier.notify("success", data.message);

          return;
        }

        this.notifier.notify("error", data.message);
      });
  }

  passwordReset() {
    this.authService.passwordReset(this.employee.email).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        return;
      }
      this.notifier.notify("error", data.message);
    });
  }

  makeAdmin() {
    this.authService.makeAdmin(this.employee.id).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.getEmployee();
        return;
      }
      this.notifier.notify("error", data.message);
    });
  }

  removeAdmin() {
    this.authService.removeAdmin(this.employee.id).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.getEmployee();
        return;
      }
      this.notifier.notify("error", data.message);
    });
  }
}
