import { NotifierService } from 'angular-notifier';
import { IDepartmentHead } from './../interfaces/DepartmentHead';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/department.service';
import { IDepartment } from '../interfaces/Department';
import { IEmployee } from 'src/app/employee/interfaces/Employee';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { ITeamDetail } from '../interfaces/TeamDetail';
import { ITeamMember } from '../interfaces/TeamMember';
import { ITeam } from '../interfaces/Team';
import { ITeamType } from '../interfaces/TeamType';
import { IOwnerType } from 'src/app/common/interfaces/OwnerType';
import { OtherService } from 'src/app/services/other.service';


@Component({
  selector: 'app-department-detail',
  templateUrl: './department-detail.component.html',
  styleUrls: ['./department-detail.component.css']
})
export class DepartmentDetailComponent implements OnInit {
  department: IDepartment;
  departmentHead: IEmployee;
  departmentId: string;
  showLoader: boolean;
  employees: IEmployee[] = [];
  teams: ITeamDetail[] = [];
  ownerTypes: IOwnerType[] = [];
  activeEmployeeId: string = this.endPoints.constants.emptyId;
  activeTypeId: string = this.endPoints.constants.emptyId;
  newTeam: ITeam = {
    id: this.endPoints.constants.emptyId,
    createdAt: new Date,
    updatedAt: new Date,
    name: "",
    departmentId: this.endPoints.constants.emptyId,
  };

  editTeam: ITeam = {
    id: this.endPoints.constants.emptyId,
    createdAt: new Date,
    updatedAt: new Date,
    name: "",
    departmentId: this.endPoints.constants.emptyId,
  };


  constructor(private departmentService: DepartmentService, private router: Router, private otherService: OtherService,
     private endPoints: ApiEndPointsService, private route : ActivatedRoute, private notifier : NotifierService ) {
      this.departmentId = this.route.snapshot.paramMap.get('id');
     }

  ngOnInit() {
    this.getDepartment();
    this.getDepartmentEmployees();
    this.getDepartmentTeams();
    
  }

  getDepartment(){
    this.showLoader = true;
    this.departmentService.getDepartment(this.departmentId).subscribe(
      data => {
        if (data.success) {
        this.department = data.data;
        this.getTicketTypes();
        if (this.department.departmentHeadId !== this.endPoints.constants.emptyId) {
          this.getDepartmentHead();
        }
        }
        this.showLoader = false;
      }
    );
  }

  getTicketTypes(){
    var owner = "";
    if(this.department?.name == "OPERATIONS"){
      owner = "PR";
    }
    if(this.department?.name == "TECHNICAL"){
      owner = "IT";
    }
    
    this.otherService.getTypes(owner).subscribe(data=>{
      if(data.success){
        data.data.forEach(element => {
          let type: IOwnerType = {
            id: element.id,
            name: element.name,
            createdAt: element.createdAt,
            updatedAt: element.updatedAt,
            owner: element.owner,
            type: element.type
          };
          this.ownerTypes.push(type);
        });
      }
    });
  }



  getDepartmentEmployees() {
    this.departmentService.getDepartmentEmployees(this.departmentId).subscribe(data => {
      if(data.success){
        this.employees = data.data;
      }
    });
  }

  getDepartmentHead() {
    this.departmentService.getDepartmentHead(this.department.departmentHeadId).subscribe(
      data => {
        if (data.success) {
            this.departmentHead = data.data;
        }
      }
    );
  }

  assignAsHead(id: string) {

    let head : IDepartmentHead = {
      departmentId : this.departmentId,
      employeeId : id
    };
    this.departmentService.addDepartmentHead(head).subscribe(data => {
      if(data.success){
        this.notifier.notify('success', data.message);
        this.getDepartment();
        this.getDepartmentEmployees();

        return;
      }

      this.notifier.notify('error', data.message);
    });
  }

  getDepartmentTeams(){
    this.departmentService.getDepartmentTeams(this.departmentId).subscribe(data =>{
      if(data.success){
        this.teams = data.data;
      }
    });
  }

  saveTeam(){

    this.newTeam.departmentId = this.departmentId;
    this.departmentService.addTeam(this.newTeam).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
        this.getDepartmentTeams();

        return;
      }

      this.notifier.notify('error', data.message);
    });
  }

  selectOption(id: string) {
    this.activeEmployeeId = id;
  }

  selectTypeOption(id: string) {
    this.activeTypeId = id;
  }

  addMember(id: string){

    let member: ITeamMember = {
      employeeId: this.activeEmployeeId,
      teamId: id
    };

    this.departmentService.addTeamMember(member).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
        this.getDepartmentTeams();

        return;
      }

      this.notifier.notify('error', data.message);
    });
  }

  removeMember(id: string, empId: string){

    let rmember : ITeamMember = {
      employeeId: empId,
      teamId: id
    };
    this.departmentService.removeTeamMember(rmember).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
        this.getDepartmentTeams();

        return;
      }

      this.notifier.notify('error', data.message);
    });
  }

  addType(teamId: string){

    let teamType: ITeamType = {
      teamId: teamId,
      typeId: this.activeTypeId
    };
    this.departmentService.addTeamType(teamType).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
        this.getDepartmentTeams();

        return;
      }

      this.notifier.notify('error', data.message);
    });
  }

  removeType(teamId: string, typeId: string){

    let teamType: ITeamType = {
      teamId: teamId,
      typeId: typeId
    };
    this.departmentService.removeTeamType(teamType).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
        this.getDepartmentTeams();

        return;
      }

      this.notifier.notify('error', data.message);
    });
  }

  triggerEditTeam(id: string, name: string){
    this.editTeam.id = id;
    this.editTeam.name = name;
  }

  saveEditTeam(){

    this.departmentService.editTeam(this.editTeam).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
        this.getDepartmentTeams();

        return;
      }

      this.notifier.notify('error', data.message);
    });
  }

}
