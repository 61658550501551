import { Component, OnInit } from "@angular/core";
import { IUserPrivilege } from "../common/interfaces/UserPrivilege";
import { AuthService } from "../services/auth.service";
import { FaqService } from "../services/faq.service";
import { NotifierService } from 'angular-notifier';


@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"],
})
export class FaqComponent implements OnInit {
  faqs: any[] = [];
  canraise: boolean;
  canviewdetails: boolean;
  canedit: boolean;
  showLoader: boolean;
  user: any;
  privileges: IUserPrivilege[] = [];
  constructor(
    private faqService: FaqService,
    private authService: AuthService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;
    });
    this.getFaqs();
  }

  getFaqs() {
    this.showLoader = true;
    this.faqService.getFaqs("All").subscribe((data) => {
      if (data.success) {
        this.faqs = data.data;
        console.log(this.faqs)
        this.showLoader = false;
      } else {
        this.showLoader = false;
      }
    });
  }

  deleteFaq(id : string){

    this.faqService.deleteFaq(id).subscribe(data=>{
      if(data.success){
      this.notifier.notify("success", data.message);
      this.getFaqs();

      return;
      }

      this.notifier.notify('error', data.message);
    });
  }

  
}
