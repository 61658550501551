<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main>
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
      <div class="container-fluid">
          <div class="sb-page-header-content py-5">
              <h1 class="sb-page-header-title">
                  <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
                  <span>Logs</span>
              </h1>
          </div>
      </div>
  </div>
  <div class="container-fluid mt-n10">
  <div class="row">
      <div class="col-md-12">
          <div class="card mb-4">
            <div class="card-body">
                <div class="sb-datatable table-responsive">
                    <table class="table table-bordered table-hover" datatable width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Message</th>
                                <th>Type</th>
                                <th>User(if any)</th>
                                <th>Created On</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let log of logs;index as i">
                                <td>{{i+1}}</td>
                                <td>{{log.message}} </td>
                                <td>{{log.type}}</td>
                                <td>
                                  <a href="javascript:void(0);" (click)="getUser(log?.employeeId)"  data-toggle="modal" data-target="#exampleModalReason">
                                    User Details
                                  </a>
                                </td>
                                <td><div class="badge badge-primary badge-pill">{{log.createdAt | date:'medium'}}</div></td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
  </div>
  </div>
</main>
</div>
</div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModalReason" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongReason" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">User Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="alert alert-danger" *ngIf="error">{{error}}</div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"> <i class="fa fa-user"></i>   Names : {{user?.name}}</li>
            <li class="list-group-item"> <i class="fa fa-envelope"></i>   Email :{{user?.email}}</li>
            <li class="list-group-item"> <i class="fa fa-phone"></i>   Phone : {{user?.phone}}</li>
            <li class="list-group-item"> <i class="fa fa-credit-card"></i>   ID/Passport No : {{user?.identity}}</li>
            <li class="list-group-item"> <i class="fa fa-clock"></i>    Created On : {{user?.createdAt | date:'medium'}}</li>
         </ul>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-sm" data-dismiss="modal"><i class="fa fa-times"></i> Close</button>
      </div>
    </div>
  </div>
</div>
