<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
    <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-fluid">
            <div class="sb-page-header-content py-5">
                <h1 class="sb-page-header-title">
                    <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
                    <span>Complains Report</span>
                </h1>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-n10">
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-header">

                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="small blm mb-1" for="date">From Date</label>
                        <div class="input-group">
                          <input class="form-control" placeholder="dd-mm-yyyy"
                          name="endDate" id="startDate" [(ngModel)]="startDate" type="date">
                          </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="small blm mb-1" for="endDate">To Date</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="dd-mm-yyyy"
                                   name="endDate" id="endDate" [(ngModel)]="endDate" type="date">
                          </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group"><label class="small blm mb-1" for="type">Type</label>
                        <select class="form-control" id="type" [(ngModel)]="type">
                            <option value="">Select Type</option>
                            <option value="{{emptyId}}">All</option>
                            <option *ngFor="let type of types" value="{{type.id}}">{{type.name}}</option>
                        </select>
                    </div>
                    </div>
                    <div class="col-md-3">
                      <br>
                      <button class="btn btn-success" (click)="getTickets()">Generate</button>
                    </div>
                  </div><br>
                    <div class="sb-datatable table-responsive">
                      <div class="row">
                        <div class="col-md-12" style="text-align: right;">
                          <button class="btn btn-info pull-right"  (click)="export()">Export</button>
                        </div>
                      </div><br>

                        <table class="table table-bordered table-hover"  width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>S/NO</th>
                                    <th>Date Received</th>
                                    <th>Complain Channel</th>
                                    <th>Name of Complainant</th>
                                    <th>Complaint Issue</th>
                                    <th>Root Cause</th>
                                    <th>Corrective Action</th>
                                    <th>Action Taken</th>
                                    <th>STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let model of tickets;index as i">
                                    <td>MST-{{i+1}}</td>
                                    <td>{{model.ticket?.createdAt | date:'medium'}}</td>
                                    <td>{{model.ticket?.source}}</td>
                                    <td>{{model.customer?.firstName +'&nbsp;' + model.customer?.lastName}}</td>
                                    <td>{{model.ticket?.description}}</td>


                                    <td>
                                        <div *ngIf="model.ticket.reason">{{model.ticket?.reason}}</div>
                                        <div *ngIf="!model.ticket.reason">
                                          <p>N/A</p></div>

                                    </td>
                                    <td>
                                      <a class="badge badge-primary " [routerLink]="['/ticket-details',model.id]"
                                        >
                                        <i class="fa fa-eye"></i> View
                                      </a> |
                                      <a class="badge badge-warning" [routerLink]="['/edit-ticket', model.id]"
                                        >
                                        <i class="fa fa-edit"></i>
                                      </a>
                                    </td>
                                    <td></td>
                                    <td>
                                      <div *ngIf="model.status">
                                        {{model.status}}
                                      </div>
                                      <div *ngIf="!model.status">
                                        <p>N/A</p>

                                      </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</main>
</div>
</div>
</div>

