import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { OtherService } from 'src/app/services/other.service';
import { Router } from '@angular/router';
import { SettingService } from 'src/app/services/setting.service';
import { IReviewOptions } from 'src/app/common/interfaces/ReviewOptions';


@Component({
  selector: 'app-review-setting',
  templateUrl: './review-setting.component.html',
  styleUrls: ['./review-setting.component.css']
})
export class ReviewSettingComponent implements OnInit {

  newReview: IReviewOptions;
  reviews: IReviewOptions[] = [];
   constructor(private otherService: OtherService, private notifier: NotifierService,
     private endPoints: ApiEndPointsService, private router: Router, private settingService : SettingService) {
       this.newReview = {
         id: this.endPoints.constants.emptyId,
         name: "",
         createdAt: new Date,
         updatedAt: new Date
       }
     }

   ngOnInit() {
     this.getReviewOptions();
   }

   getReviewOptions(){

     this.otherService.getReviewOptions().subscribe(data => {
       if (data.success) {
         this.reviews = data.data;

       }else{
         this.notifier.notify('error', data.message);
       }

     });
   }

   saveReview(){

     this.otherService.addReview(this.newReview).subscribe(data => {
       if (data.success) {
         this.notifier.notify("success", data.message);
         this.router.navigate(['settings']);
         this.getReviewOptions();

         window.location.reload();
         return;
       }else{
         this.notifier.notify('error', data.message);
       }

     });
   }
 }
