import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError} from 'rxjs/operators';
import { HttpClient} from "@angular/common/http";
import { ApiEndPointsService } from '../common/api-end-points.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
baseUrl: string;
  constructor(private http : HttpClient, private endPoints: ApiEndPointsService, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getDashboard(id: string, origin:string) : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.dashboard.getdashstats+id+"/"+origin)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getTotalTicketClosedPerDay(id:string, origin:string) : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.dashboard.gettottalticketclosed+id+"/"+origin)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getTotalTaskClosedPerDay() : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.dashboard.gettottaltaskclosed)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getTaskComp() : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.dashboard.gettaskcomp)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getTicketComp(id:string, origin:string) : Observable<any>{
    return this.http.get(this.baseUrl + this.endPoints.dashboard.getticketcomp+id+"/"+origin)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }

}
