import { ISource } from "./../../setting/interfaces/Source";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TicketService } from "src/app/services/ticket.service";
import { OtherService } from "src/app/services/other.service";
import { IOwnerType } from "src/app/common/interfaces/OwnerType";
import { ITicket } from "../interfaces/Ticket";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { NotifierService } from "angular-notifier";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-add-ticket",
  templateUrl: "./add-ticket.component.html",
  styleUrls: ["./add-ticket.component.css"],
})
export class AddTicketComponent implements OnInit {
  customerId: string = null;
  employeeId: string = null;
  userId: string = null;
  emptyId: string = null;
  showLoader: boolean;
  user: any;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private ticketService: TicketService,
    private otherService: OtherService,
    private endPoints: ApiEndPointsService,
    private notifier: NotifierService
  ) {
    this.userId = this.userId = localStorage.getItem("userId");
    this.employeeId = this.userId;
    this.customerId = this.route.snapshot.paramMap.get("id");
    this.emptyId = this.endPoints.constants.emptyId;
  }
  types: IOwnerType[] = [];
  sources: ISource[] = [];
  ticket: ITicket = {
    id: this.emptyId,
    title: "",
    description: "",
    closed: false,
    completionPercentage: 0,
    creator: this.userId,
    customerId: this.customerId,
    employeeId: this.employeeId,
    typeId: this.emptyId,
    createdAt: new Date(),
    updatedAt: new Date(),
    status: "",
    reason: "",
    source: "",
  };
  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;
      console.log(this.user)
    });
    this.getTicketTypes();
    this.getSources();
  }

  getTicketTypes() {
    let type = ""
    if (this.customerId == this.emptyId) {
      type = "IT";
    }
    else {
      type = "PR";
    }
    this.otherService.getTypes(type).subscribe((data) => {
      if (data.success) {
        this.types = data.data;

      }
    });
  }

  getSources() {
    this.otherService.getSources().subscribe((data) => {
      if (data.success) {
        this.sources = data.data;
      }
    });
  }

  saveTicket() {
    if (!this.ticket.title) {
      this.notifier.notify("error", "Provide a title for your ticket");
      return;
    }
    if (!this.ticket.description) {
      this.notifier.notify("error", "Provide a description for your ticket");
      return;
    }
    if (!this.ticket.typeId || this.ticket.typeId == this.emptyId) {
      this.notifier.notify("error", "Select a category for your ticket");
      return;
    }
    this.showLoader = true;
    this.ticket.id = this.emptyId;
    if (this.user?.type == "Customer") {
      this.ticket.customerId = this.customerId;
      this.ticket.employeeId = this.emptyId;
      this.ticket.creator = this.userId;

      this.ticket.source = "Added By Customer";

    }
    if (this.user?.type == "Employee") {
      this.ticket.employeeId = this.employeeId;
      this.ticket.customerId = this.customerId;
      this.ticket.creator = this.userId;


      this.ticket.source = "Added By Employee";
    }

    this.ticketService.addTicket(this.ticket).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.showLoader = false;
        if (this.customerId != this.emptyId) {
          this.router.navigate(["/complains"]);
          return;
        }
        else if (this.customerId == this.emptyId) {
          this.router.navigate(["/itHelpdesk"]);
          return;


        }


      }
      this.showLoader = false;
      this.notifier.notify("error", data.message);
    });
  }
}
