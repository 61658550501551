import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { AuthService } from "src/app/services/auth.service";
import { FaqService } from "src/app/services/faq.service";

@Component({
  selector: "app-edit-faq",
  templateUrl: "./edit-faq.component.html",
  styleUrls: ["./edit-faq.component.css"],
})
export class EditFaqComponent implements OnInit {
  userId: string = null;
  emptyId: string = null;
  showLoader: boolean;
  user: any;
  faqId: string = null;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private faqService: FaqService,
    private endPoints: ApiEndPointsService,
    private notifier: NotifierService
  ) {
    this.userId = this.userId = localStorage.getItem("userId");
    this.emptyId = this.endPoints.constants.emptyId;
    this.faqId = this.route.snapshot.paramMap.get("id");
  }
  faq: any = {
    id: this.emptyId,
    name: "",
    answer: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;
    });
    this.getFaqById();
  }

  getFaqById() {
    this.faqService.getFaq(this.faqId).subscribe((data) => {
      if (data.success) {
        this.faq = data.data;
        return;
      }
    });
  }
  saveFaqEdit() {
    this.showLoader = true;
    this.faq.id = this.faqId;
    console.log(this.faq);
    this.faqService.editFaq(this.faq).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.showLoader = false;
        this.router.navigate(["/faqs"]);
        return;
      }
      this.showLoader = false;
      this.notifier.notify("error", data.message);
    });
  }
}
