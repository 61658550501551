import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiEndPointsService } from "../common/api-end-points.service";
import { Observable } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { ISurvey } from "../survey/interfaces/Survey";
import { IQuestion } from "../survey/interfaces/Question";
import { IOption } from "../survey/interfaces/Option";
import { IResponseView } from "../survey/interfaces/ResponseView";
import { INotifyStaff } from "../survey/interfaces/NotifyStaff";

@Injectable({
  providedIn: "root",
})
export class SurveyService {
  baseUrl: string;
  constructor(
    private http: HttpClient,
    private endPoints: ApiEndPointsService,
    @Inject("BASE_URL") baseUrl: string
  ) {
    this.baseUrl = baseUrl;
  }

  getSurveys(): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.survey.getsurveys)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  getSurveyDetail(id: string): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.survey.getsurveydetail + id)
      .pipe(tap(), catchError(this.endPoints.handleError));
  } 

  getActiveSurvey(): Observable<any> {
    return this.http
      .get(this.baseUrl + this.endPoints.survey.getactivesurvey)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  changeStatus(id: string, status: string): Observable<any> {
    return this.http
      .get(
        this.baseUrl + this.endPoints.survey.changestatus + id + "/" + status
      )
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  saveSurvey(survey: ISurvey): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.survey.savesurvey, survey)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  notifyStaff(notify: INotifyStaff): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.survey.notifystaff, notify)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  saveQuestion(question: IQuestion): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.survey.savequestion, question)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  saveOption(option: IOption): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.survey.saveoption, option)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }

  saveResponses(response: IResponseView): Observable<any> {
    return this.http
      .post(this.baseUrl + this.endPoints.survey.saveresponses, response)
      .pipe(tap(), catchError(this.endPoints.handleError));
  }
  conductedSurvey(surveyArgs: any):Observable<any>{
    return this.http
    .post(this.baseUrl + this.endPoints.survey.conductedSurvey, surveyArgs)
    .pipe(tap(), catchError(this.endPoints.handleError));
  }
} 
 