import { Component, OnInit } from "@angular/core";
import { SurveyService } from "src/app/services/survey.service";
import { IEmployee } from "src/app/employee/interfaces/Employee";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { AuthService } from "src/app/services/auth.service";
import { ISurveyDetail } from "../interfaces/SurveyDetail";
import { IQuestion } from "../interfaces/Question";
import { IOption } from "../interfaces/Option";
import { IQuestionView } from "../interfaces/QuestionView";
import {IReviewOptions} from "src/app/common/interfaces/ReviewOptions";
import { OtherService } from 'src/app/services/other.service';


@Component({
  selector: "app-survey-details",
  templateUrl: "./survey-details.component.html",
  styleUrls: ["./survey-details.component.css"],
})
export class SurveyDetailsComponent implements OnInit {
  userId: string;
  emptyId: string;
  showLoader: boolean;
  editable: boolean;
  surveyQuestion: IQuestion;
  option: IOption;
  questions: IQuestionView[] = [];
  reviews: IReviewOptions[]=[];

  newQuiz: string = "";
  newOption: string = "";
  activeType: string = "";
  activeQuestionId: string;

  surveyId: string;
  loginUser: any;
  surveyDetail: ISurveyDetail;
  constructor(
    private surveyService: SurveyService,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private endPoints: ApiEndPointsService,
    private otherService: OtherService,
    private authService: AuthService
  ) {
    this.surveyId = this.route.snapshot.paramMap.get("id");
    this.userId = localStorage.getItem("userId");
    this.emptyId = this.endPoints.constants.emptyId;
    this.activeQuestionId = this.emptyId;

    this.surveyDetail = {
      survey: null,
      questions: [],
    };
    this.surveyQuestion = {
      id: this.emptyId,
      surveyId: this.emptyId,
      type: "",
      question: "",
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    this.option = {
      id: this.emptyId,
      option: "",
      questionId: this.emptyId,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }

  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.loginUser = data;
    });
    this.getSurveyDetails();
    this.getReviewOptions();
  }
  getReviewOptions(){
 
    this.otherService.getReviewOptions().subscribe(data => {
      if (data.success) {
        this.reviews = data.data;

      }else{
        this.notifier.notify('error', data.message);
      }

    });
  }

  getSurveyDetails() {
    this.surveyService.getSurveyDetail(this.surveyId).subscribe((data) => {
      if (data.success) {
        this.surveyDetail = data.data;
      }
      return;
    });
  }

  saveQuestion() {
    this.showLoader = true;
    let newSQuestion = this.surveyQuestion;
    newSQuestion.surveyId = this.surveyDetail.survey.id;
    newSQuestion.question = this.newQuiz;
    newSQuestion.type = this.activeType;
    this.surveyService.saveQuestion(this.surveyQuestion).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.newQuiz = "";
        this.activeType = "";
        let quizView: IQuestionView = {
          question: data.data,
          options: [],
        };
        this.questions.push(quizView);
        this.showLoader = false;
        return;
      }
      this.showLoader = false;
    });
  }

  saveOption() {
    this.showLoader = true;
    let optionSave = this.option;
    optionSave.option = this.newOption;
    optionSave.questionId = this.activeQuestionId;
    this.surveyService.saveOption(optionSave).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.questions
          .find((x) => x.question.id == this.activeQuestionId)
          .options.push(data.data);
        this.newOption = "";
        this.showLoader = false;
      }
      this.showLoader = false;
    });
  }

  setActiveQuestion(id: string) {
    this.activeQuestionId = id;
  }
}
