<notifier-container></notifier-container>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main>
    <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
        <div class="container-fluid">
            <div class="sb-page-header-content py-5">
                <h1 class="sb-page-header-title">
                    <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
                    <span>Edit Employee</span>
                </h1>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-n10">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="card shadow-lg border-0 rounded-lg mt-5">
                        <div class="card-header justify-content-center"><h3 class="font-weight-light my-4">Edit Employee</h3></div>
                        <div class="card-body">
                            <div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="firstName">First Name</label>
                                            <input class="form-control" id="firstName" [(ngModel)]="employee.firstName" name="firstName" type="text" placeholder="Enter first name" /></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="lastName">Last Name</label>
                                            <input class="form-control" id="lastName" [(ngModel)]="employee.lastName" name="lastName" type="text" placeholder="Enter last name" /></div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="small blm mb-1" for="email">Email</label>
                                            <input class="form-control" id="email" name="email" [(ngModel)]="employee.email" type="email"  placeholder="Enter email address" />
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="small blm mb-1" for="phone">Phone/ Mobile</label>
                                            <input class="form-control" id="phone" [(ngModel)]="employee.phone" type="text"
                                             placeholder="Enter phone number" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="form-group"><label class="small blm mb-1" for="typeId">Department</label>
                                            <select class="form-control" id="typeId" [(ngModel)]="employee.departmentId">
                                                <option value="" selected="selected">Select Department</option>
                                            <option *ngFor="let department of departments" value="{{department.id}}">{{department.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mt-4 mb-0">
                                    <button class="btn btn-primary btn-block" (click)="editEmployee()">Edit Employee</button>
                                    <a class="btn btn-primary btn-block" [routerLink]="['/employees']">Cancel</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</div>
</div>
</div>
