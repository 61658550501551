import { Component, OnInit } from '@angular/core';
import { SettingService } from 'src/app/services/setting.service';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { NotifierService } from 'angular-notifier';
import { ISmtpSetting } from '../interfaces/SmtpSetting';


@Component({
  selector: 'app-smtp-setting',
  templateUrl: './smtp-setting.component.html',
  styleUrls: ['./smtp-setting.component.css']
})
export class SmtpSettingComponent implements OnInit {

  constructor(private settingService : SettingService, private endPoints : ApiEndPointsService,
     private notifier : NotifierService) { }
  initSmtpSetting : ISmtpSetting ={
    id : this.endPoints.constants.emptyId,
    server : "",
    userName : "",
    password : "",
    port : 0,
    createdAt : new Date,
    updatedAt : new Date
  };
  smtpSetting : ISmtpSetting = this.initSmtpSetting;

  smtpSettings : ISmtpSetting[] = [];
  ngOnInit() {
    this.getSmtpSettings();
  }

  getSmtpSettings(){

    this.settingService.getAllSmtpSettings().subscribe(data=>{
      if(data.success){
          this.smtpSettings = data.data
      }else{
        this.notifier.notify('error','Error Loading Email Setting Data');
      }

    });
  }

  saveSmtpSetting(){

    this.settingService.addSmtpSettings(this.smtpSetting).subscribe(data=>{
      if(data.success){
        this.notifier.notify("success", data.message);
        this.getSmtpSettings();
        this.smtpSetting = this.initSmtpSetting;

        window.location.reload();
        return;
      }else {
        this.notifier.notify("error", data.message);
      }

    });
  }

  deleteSmtpSetting(id : string){

    this.settingService.deleteSmtpSetting(id).subscribe(
      data=>{
        if(data.success){
            this.notifier.notify("success", data.message);
            this.getSmtpSettings();

            return;
        }

        this.notifier.notify("error", data.message);
      }
    );
  }
}
