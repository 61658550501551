import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CustomerService } from 'src/app/services/customer.service';
import { ICustomer } from '../interfaces/Customer';


@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css']
})
export class CustomerDetailComponent implements OnInit {
  customerId : string;
  customer : ICustomer;
  showLoader: boolean;
  constructor(private customerService : CustomerService, private route : ActivatedRoute, private notifier: NotifierService) {
    this.customerId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.getCustomer();
  }

  getCustomer(){
    this.showLoader = true;
    this.customerService.getCustomer(this.customerId).subscribe(data=>{
      if(data.success){
        this.notifier.notify('success', data.message);
          this.customer = data.data;
        this.showLoader = false;
          return;
      }
      this.showLoader = false;
      this.notifier.notify('error', data.message);
    });
  }
}
