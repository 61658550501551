import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/services/auth.service";
import { TicketService } from "src/app/services/ticket.service";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { IOwnerType } from "src/app/common/interfaces/OwnerType";
import { OtherService } from "src/app/services/other.service";


@Component({
  selector: 'app-my-it-requests',
  templateUrl: './my-it-requests.component.html',
  styleUrls: ['./my-it-requests.component.css']
})
export class MyItRequestsComponent implements OnInit {
  isBeneficiary: boolean;
  user: any;
  search_key: string;
  create_new: boolean = false;
  tickets: any[] = [];
  errors: string[] = [];
  emptyId: string = null;
  showLoader: boolean;
  employeeTypes: IOwnerType[] = [];

  constructor(
    private ticketService: TicketService,
    private endPoints: ApiEndPointsService,
    private otherService: OtherService,
    private authService: AuthService

  ) { 
    this.emptyId = this.endPoints.constants.emptyId;

  }

  ngOnInit(): void {
    this.authService.getUser().subscribe((data) => {
      this.user = data;
    });

    this.getTickets();
    this.getEmployeeTypes();

  }
  getTickets() {
    this.showLoader = true;
    if (this.user.isAdmin == 1) {
      this.ticketService.getTickets('IT').subscribe(
        (data) => {
          if (data.success) {
            this.tickets = data.data;
          }
          this.showLoader = false;
        },
        (error) => console.log(<any>error)
      );
    }
    if (this.user.isAdmin == 0) {
      this.ticketService.getUserTickets(this.user.id, 'IT').subscribe(
        (data) => {
          if (data.success) {
            this.tickets = data.data;
            console.log(data)
          }
          this.showLoader = false;
        },
        (error) => console.log(<any>error)
      );
    }
  }
  getEmployeeTypes() {
    this.otherService.getTypes("Employee").subscribe((data) => {
      if (data.success) {
        this.employeeTypes = data.data;
      }
    });
  }


}
