<notifier-container></notifier-container>
<app-loading *ngIf="showLoader"></app-loading>
<div>
  <app-header></app-header>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <app-sidenav></app-sidenav>
    </div>
    <div id="layoutSidenav_content">
<main *ngIf="!showLoader">
  <div class="sb-page-header pb-10 sb-page-header-dark bg-gradient-primary-to-secondary">
    <div class="container-fluid">
      <div class="sb-page-header-content py-5">
        <h1 class="sb-page-header-title">
          <div class="sb-page-header-icon"><i data-feather="activity"></i></div>
          <span>Add Customer</span>
        </h1>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-n10">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="card shadow-lg border-0 rounded-lg mt-5">
            <div class="card-header justify-content-center">
              <h3 class="font-weight-light my-4">Create Customer</h3>
            </div>
            <div class="card-body">

              <div>
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="form-group"><label class="small blm mb-1" for="firstName">First Name</label>
                      <input class="form-control" id="firstName" [(ngModel)]="customer.firstName" name="firstName"
                        type="text" placeholder="Enter first name" /></div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group"><label class="small blm mb-1" for="lastName">Last Name</label>
                      <input class="form-control" id="lastName" [(ngModel)]="customer.lastName" name="lastName"
                        type="text" placeholder="Enter last name" /></div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="small blm mb-1" for="email">Email</label>
                      <input class="form-control" id="email" name="email" [(ngModel)]="customer.email" type="email"
                         placeholder="Enter email address" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="small blm mb-1" for="phone">Phone/ Mobile</label>
                      <input class="form-control" id="phone" [(ngModel)]="customer.phone" type="text"
                        placeholder="Enter phone number" />
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="form-group"><label class="small blm mb-1" for="county">County</label>
                      <select class="form-control" id="county" [(ngModel)]="customer.county"
                        (change)="populateSubCounties($event.target.value)">
                        <option value="">Select County</option>
                        <option *ngFor="let county of countydata" value="{{county.name}}">{{county.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group"><label class="small blm mb-1" for="subCounty">Sub County</label>
                      <select class="form-control" id="subCounty" [(ngModel)]="customer.subCounty">
                        <option value="">Select Sub County</option>
                        <option *ngFor="let subCounty of subcountySelection" value="{{subCounty.name}}">
                          {{subCounty.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="form-group"><label class="small blm mb-1" for="address">Address</label>
                      <input class="form-control" id="address" name="address" [(ngModel)]="customer.address" type="text"
                        placeholder="Address" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group"><label class="small blm mb-1" for="typeId">Customer Type</label>
                      <select class="form-control" id="typeId" [(ngModel)]="customer.typeId">
                        <option value="">Select Customer Type</option>
                        <option *ngFor="let type of customerTypes" value="{{type.id}}">{{type.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-4 mb-0">
                  <button class="btn btn-success btn-block" (click)="saveCustomer()">Create Customer</button>
                  <a class="btn btn-primary btn-block" [routerLink]="['/customers']">Cancel</a>
                </div>
              </div>
            </div>
            <div class="card-footer text-center">
            </div>
          </div>
        </div>
        <div class="col-lg-5" *ngIf="errors.length > 0">
          <div class="card shadow-lg border-0 rounded-lg mt-5 alert-danger">
            <div class="card-header justify-content-center">
              <h3 class="font-weight-light my-4">Errors</h3>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li class="list-group-item alert-danger" *ngFor="let err of errors">
                  <i class="fa fa-exclamation"></i> {{err}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</div>
</div>
</div>
