import { Component, OnInit } from "@angular/core";
import { ISurveyPreview } from "./interfaces/SurveyPreview";
import { IEmployee } from "../employee/interfaces/Employee";
import { IUserPrivilege } from "../common/interfaces/UserPrivilege";
import { SurveyService } from "../services/survey.service";
import { AuthService } from "../services/auth.service";
import { NotifierService } from "angular-notifier";
import {IReviewOptions} from "src/app/common/interfaces/ReviewOptions";
import { OtherService } from 'src/app/services/other.service';
import { EmployeeService } from "src/app/services/employee.service";
import { INotifyStaff } from "src/app/survey/interfaces/NotifyStaff";


@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.css"],
})
export class SurveyComponent implements OnInit {
  surveys: ISurveyPreview[] = [];
  user: any;
  reviews: IReviewOptions[]=[];
  employees: IEmployee[] = [];
  notifyEmployees: string[] = [];
  showLoader: boolean;
  activeId: string;
  notifyId: string;
  status: string;
  currentRate=0;

  constructor(
    private surveyService: SurveyService,
    private authService: AuthService,
    private otherService: OtherService,
    private employeeService: EmployeeService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe((data) => {
      this.user = data;
      this.getSurveys();
      this.getEmployees();
    });
    
    this.getReviewOptions();
  }
  getReviewOptions(){
 
    this.otherService.getReviewOptions().subscribe(data => {
      if (data.success) {
        this.reviews = data.data;

      }else{
        this.notifier.notify('error', data.message);
      }

    });
  }
  getEmployees() {
    this.employeeService.getEmployees().subscribe((data) => {
      if (data.success) {
        this.employees = data.data;
      }
    });
  }
  addRemoveId(id: string, e) {
    if (e.target.checked) {
      if (!this.notifyEmployees.find((x) => x === id)) {
        this.notifyEmployees.push(id);
      }
    }
    if (!e.target.checked) {
      if (this.notifyEmployees.find((x) => x === id)) {
        const index = this.notifyEmployees.indexOf(id);
        if (index > -1) {
          this.notifyEmployees.splice(index, 1);
        }
      }
    }
  }
  notify() {
    let listNotification: INotifyStaff = {
      id: this.notifyId,
      userIds: this.notifyEmployees,
    };
    this.surveyService.notifyStaff(listNotification).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        return;
      }
      this.notifier.notify("error", data.message);
    });
  }

  getSurveys() {
    if (this.user?.isAdmin == 1) {
      this.showLoader = true;
      this.surveyService.getSurveys().subscribe(
        (data) => {
          if (data.success) {
            this.surveys = data.data;
          }
          this.showLoader = false;
        },
        (error) => {
          console.log(<any>error);
        }
      );
    }
  }

  setActiveId(id: string) {
    this.activeId = id;
  }
  setSurveyNotifyId(id: string) {
    this.notifyId = id;
  }

  changeStatus() {
    this.surveyService
      .changeStatus(this.activeId, this.status)
      .subscribe((data) => {
        if (data.success) {
          this.notifier.notify("success", data.message);
          return;
        }
        this.notifier.notify("error", data.message);
      });
  }

}
