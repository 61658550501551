import { Component, OnInit } from "@angular/core";
import { EmployeeService } from "src/app/services/employee.service";
import { IEmployee } from "../interfaces/Employee";
import { ApiEndPointsService } from "src/app/common/api-end-points.service";
import { DepartmentService } from "src/app/services/department.service";
import { IDepartment } from "src/app/department/interfaces/Department";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import { IEmployeeByNo } from "../interfaces/EmployeeByNo";

@Component({
  selector: "app-add-employee",
  templateUrl: "./add-employee.component.html",
  styleUrls: ["./add-employee.component.css"],
})
export class AddEmployeeComponent implements OnInit {
  empNo: string;
  password: string;
  confirm_password: string;
  admin: boolean;
  makeAdmin: string = "";
  constructor(
    private empService: EmployeeService,
    private endPoints: ApiEndPointsService,
    private notifier: NotifierService,
    private router: Router
  ) {}

  showLoader: boolean;
  departments: IDepartment[] = [];
  ngOnInit() {}

  saveEmployee() {
    this.showLoader = true;
    if (!this.empNo) {
      this.notifier.notify("error", "Provide a Staff No before proceeding");
      this.showLoader = false;
      return;
    }
    if (!this.password) {
      this.notifier.notify("error", "Provide a password before proceeding");
      this.showLoader = false;
      return;
    }
    if (!this.confirm_password) {
      this.notifier.notify(
        "error",
        "Provide confirm password before proceeding"
      );
      this.showLoader = false;
      return;
    }
    if (this.confirm_password != this.password) {
      this.notifier.notify(
        "error",
        "Passwords dont match, provide the same password"
      );
      this.showLoader = false;
      return;
    }
    if (this.makeAdmin == "true") {
      this.admin = true;
    }
    let emp: IEmployeeByNo = {
      empNo: this.empNo,
      admin: this.admin,
      password: this.password,
    };
    this.empService.addEmployeeByNo(emp).subscribe((data) => {
      if (data.success) {
        this.notifier.notify("success", data.message);
        this.showLoader = false;
        this.router.navigate(["employees"]);
        return;
      }
      this.showLoader = false;
      this.notifier.notify("error", data.message);
    });
  }
}
