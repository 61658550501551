import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ApiEndPointsService } from 'src/app/common/api-end-points.service';
import { IEscalationSetting } from '../interfaces/EscalationSetting';
import { EsclanationService } from 'src/app/services/escalation-service';
import { OtherService } from 'src/app/services/other.service';
import { IOwnerType } from 'src/app/common/interfaces/OwnerType';
import {ActivatedRoute, Router } from '@angular/router';
import { IEscalationView } from '../interfaces/EscalationSettingView';
import { AuthService } from "src/app/services/auth.service";




@Component({
  selector: 'app-escalation-setting',
  templateUrl: './escalation-setting.component.html',
  styleUrls: ['./escalation-setting.component.css']
})
export class EscalationSettingComponent implements OnInit {
  types: IOwnerType[] = [];
  customerId: string = null;
  emptyId: string = null;
  user: any;
  constructor(private EsclanationService: EsclanationService, private notifier: NotifierService,  private route: ActivatedRoute,private authService: AuthService,

    private endPoints: ApiEndPointsService, private router: Router,  private otherService: OtherService) {
      this.customerId = this.route.snapshot.paramMap.get("id");
      this.emptyId = this.endPoints.constants.emptyId;

    }

    escalations: IEscalationView[] = [];
    initEscalationSetting: IEscalationSetting = {
      id: this.endPoints.constants.emptyId,
      typeId: "",
      dueDuration: 0,
      dueUnit:"",
      criticalDuration: 0,
      criticalUnit: "",
      priority:"",
      createdAt: new Date,
      updatedAt: new Date
    }

    escalation: IEscalationSetting;
    ngOnInit() {
      this.getEscalationSettings();
      this.getTaskTypes();
      this.getTicketTypes();
      this.authService.getUser().subscribe((data) => {
        this.user = data;
        console.log(this.user)
      });
      this.escalation = this.initEscalationSetting;
    }
    getEscalationSettings() {

      this.EsclanationService.getAllEscalationSettings().subscribe(data => {
        if (data.success) {
          this.escalations = data.data;
        }else{
          this.notifier.notify('error', data.message);
        }

      });
    }
    getTaskTypes() {
      this.otherService.getTypes('Task').subscribe(data => {
        if (data.success) {
          data.data.forEach(element => {
            let type: IOwnerType = {
              id: element.id,
              name: element.name+"| Task",
              createdAt: element.createdAt,
              updatedAt: element.updatedAt,
              owner: element.owner,
              type: element.type
            };
            this.types.push(type);
          });
        }else{
          this.notifier.notify('error', data.message);
        }
      });
    }

    getTicketTypes() {

      this.otherService.getAllTypes().subscribe(data => {
        if (data.success) {
          data.data.forEach(element => {
            let type: IOwnerType = {
              id: element.id,
              name: element.name,
              createdAt: element.createdAt,
              updatedAt: element.updatedAt,
              owner: element.owner,
              type: element.type
            };
            this.types.push(type);
          });
        }else{
          this.notifier.notify('error', data.message);
        }
      });
    }

    saveEscalationSetting() {
      this.EsclanationService.addEscalationSettings(this.escalation).subscribe(data => {
        if (data.success) {
          this.notifier.notify("success", data.message);
          this.router.navigate(['settings']);
          this.getEscalationSettings();
          this.escalation = this.initEscalationSetting;

          window.location.reload();
          return;
        }else{

          this.notifier.notify('error', data.message);
        }
      });
    }

    deleteEscalation(id : string){

      this.EsclanationService.deleteEscalationSetting(id).subscribe(data=>{
        if(data.success){
        this.notifier.notify("success", data.message);
        this.getEscalationSettings();

        return;
        }

        this.notifier.notify('error', data.message);
      });
    }
}
